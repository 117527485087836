import { CircularProgress, TableCell, TableRow } from '@material-ui/core';
import React from 'react';

export default function LoadingCellRow(): JSX.Element {
  return (
    <TableRow>
      <TableCell>
        <CircularProgress />
      </TableCell>
    </TableRow>
  );
}
