const tableStyle = (theme) => ({
  expandedTableRow: {
    borderLeftWidth: '5px',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.primary.main,
  },
  [theme.breakpoints.down('md')]: {
    dateCaption: {
      display: 'none',
    },
  },
});

export default tableStyle;
