import { Button } from '@material-ui/core';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import { AdvertisementFragment } from './__generated__/AdvertisementFragment';
import { CampaignFragment } from './__generated__/CampaignFragment';
import { WorkingAdvertisementPreview } from './AdvertisementPreview';

type Props = {
  advertisement: AdvertisementFragment;
  campaign: CampaignFragment;
};

export default function AdvertisementPreviewStep(props: Props): JSX.Element {
  const { advertisement } = props;
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex' }}>
      <div>
        <WorkingAdvertisementPreview {...props} />
      </div>
      <div style={{ flex: 1, paddingTop: 100, paddingLeft: 20 }}>
        <Button
          rel="noopener noreferrer"
          href={advertisement.previewUrl}
          target="_blank"
          variant="contained"
        >
          {t('Preview in Browser')}
        </Button>
        <br />
        <br />
        <CopyToClipboard text={advertisement.previewUrl}>
          <Button variant="contained" color="secondary">
            {t('Copy Preview Link to Clipboard')}
          </Button>
        </CopyToClipboard>
      </div>
    </div>
  );
}
