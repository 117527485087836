import { Paper } from '@material-ui/core';
import { Field, FieldProps } from 'formik';
import React from 'react';

import { FormSelectOption, IntegrationMapping } from '@/utils/types';

import FormCheckbox from '../forms/FormCheckbox';
import FormInput from '../forms/FormInput';
import FormSelect from '../forms/FormSelect';

type Props = FieldProps<IntegrationMapping> & {
  originExpressionOptions: FormSelectOption[];
};

export default function IntegrationMappingEditorField({
  field,
  form,
  originExpressionOptions,
}: Props): React.ReactNode {
  const { value } = field;

  // yup validation schema doesn't seem to work (TODO: find why)
  // so use field level validation instead
  function validateTarget(target: string) {
    if (!target) {
      return 'Target is required';
    }

    const integrationMappings = form.values.integration_mapping;
    if (!integrationMappings) {
      return undefined;
    }

    let wasTargetAlreadyUsed = false;
    for (const mapping of integrationMappings) {
      if (mapping.target === target) {
        if (wasTargetAlreadyUsed) {
          return 'Target is not unique';
        }

        wasTargetAlreadyUsed = true;
      }
    }

    return undefined;
  }

  return (
    <Paper style={{ margin: 16, padding: 16 }}>
      <div>
        <Field
          component={FormInput}
          id={`${field.name}.target`}
          label="Target"
          name={`${field.name}.target`}
          validate={validateTarget}
        />
      </div>
      {!value.is_advanced && (
        <div>
          <Field
            component={FormSelect}
            id={`${field.name}.expression`}
            label="Expression"
            name={`${field.name}.expression`}
            options={originExpressionOptions}
          />
        </div>
      )}
      {value.is_advanced && (
        <div>
          <Field
            component={FormInput}
            id={`${field.name}.expression`}
            label="Expression"
            name={`${field.name}.expression`}
            validate={validateExpression}
          />
        </div>
      )}
      <div>
        <Field
          component={FormCheckbox}
          id={`${field.name}.is_advanced`}
          label="Advanced mode"
          name={`${field.name}.is_advanced`}
        />
      </div>
    </Paper>
  );
}

// yup validation schema doesn't seem to work (TODO: find why)
// so use field level validation instead
function validateExpression(expression: string) {
  if (!expression) {
    return 'Expression is required';
  }

  return undefined;
}
