import { useSnackbar } from 'notistack';

import { FetchLeadReportInput } from '../__generated__/graphql-types';
import {
  FetchLeadReportMutationMutationResult,
  useFetchLeadReportMutation,
} from './__generated__/FetchLeadReportMutation';
import useRunWithErrorNotifications from './useRunWithErrorNotifications';

export default function useFetchLeadReport(): (
  input: FetchLeadReportInput
) => Promise<unknown> {
  const { enqueueSnackbar } = useSnackbar();
  const [invokeFetchLeadReportUrlMutation] = useFetchLeadReportMutation();
  const runWithErrorNotifications = useRunWithErrorNotifications();

  return async function fetchLeadReport(input: FetchLeadReportInput) {
    enqueueSnackbar('Fetching started', { variant: 'info' });

    const response = (await runWithErrorNotifications(() =>
      invokeFetchLeadReportUrlMutation({ variables: { input } })
    )) as FetchLeadReportMutationMutationResult;

    const url = response.data?.fetchLeadReport.url;

    if (!url) {
      enqueueSnackbar('Cannot fetch leads', {
        variant: 'error',
      });
      return;
    }

    window.open(url);
  };
}
