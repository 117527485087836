import { useHistory, useLocation } from 'react-router-dom';

// Inspired by https://reactrouter.com/web/example/modal-gallery
export default function useHistoryPushInModal(): (to: string) => void {
  const history = useHistory();
  const location = useLocation();

  return function historyPushInModal(to: string) {
    history.push(to, {
      background: location,
    });
  };
}
