import {
  Checkbox,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  Add,
  CloudDownload,
  Delete,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from '@material-ui/icons';
import { format as dateFormat, parseISO as parseISODate } from 'date-fns';
import { flowRight as compose } from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';

import useFetchLeadReport from '@/utils/useFetchLeadReport';
import useHistoryPushInModal from '@/utils/useHistoryPushInModal';

import stopEventPropagation from '../../utils/stopEventPropagation';
import campaignValidationSchemaExtended from '../../validation_schemas/campaignValidationSchemaExtended';
import { withConfirmableAction } from '../ConfirmDialog';
import { useDisableCampaignMutation } from './__generated__/DisableCampaignMutation';
import { useEnableCampaignMutation } from './__generated__/EnableCampaignMutation';
import AnalyticCells from './AnalyticCells';
import RowMenu from './RowMenu';
import StatusBadge from './StatusBadge';
import SwitchWithValidationWarning from './SwitchWithValidationWarning';
import tableStyle from './tableStyle';

/**
 * Component to display a campaign role
 */
function CampaignRow(props) {
  const {
    campaign,
    expanded,
    classes,
    onExecuteAction,
    selected,
    onExpand,
    onSelect,
    t,
  } = props;
  const {
    active,
    analytics,
    campaignTypeId,
    creationDate,
    id,
    leadCount,
    name,
    valid,
  } = campaign;

  const [disableCampaign] = useDisableCampaignMutation();
  const [enableCampaign] = useEnableCampaignMutation();
  const historyPushInModal = useHistoryPushInModal();
  const fetchLeadReport = useFetchLeadReport();

  return (
    <TableRow
      hover
      selected={selected}
      className={expanded ? classes.expandedTableRow : ''}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={selected}
          onClick={stopEventPropagation}
          onChange={(_, checked) => onSelect('campaign', id, checked)}
        />
      </TableCell>
      <TableCell>
        <SwitchWithValidationWarning
          disableMutation={disableCampaign}
          enableMutation={enableCampaign}
          object={campaign}
          validationSchema={campaignValidationSchemaExtended(campaignTypeId)}
          checked={active && valid}
          disabled={!valid}
        />
      </TableCell>
      <TableCell>
        <IconButton onClick={() => onExpand(campaign)}>
          {expanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
        </IconButton>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{name}</Typography>
        <br />
        <Typography
          variant="caption"
          color="textSecondary"
          className={classes.dateCaption}
        >
          {dateFormat(parseISODate(creationDate), 'Pp')}
        </Typography>
      </TableCell>
      <TableCell>
        <StatusBadge active={active} valid={valid} />
      </TableCell>
      <TableCell align="right">{leadCount}</TableCell>
      <TableCell>
        <Tooltip title="Send leads report">
          <IconButton onClick={() => fetchLeadReport({ campaignIds: [id] })}>
            <CloudDownload />
          </IconButton>
        </Tooltip>
      </TableCell>
      <AnalyticCells analyticsObject={analytics} />
      <TableCell style={{ textAlign: 'right' }}>
        <RowMenu
          menuItems={[
            {
              label: t('New Advertisement Group'),
              icon: <Add />,
              onClick: () =>
                historyPushInModal(
                  `/dashboard/campaigns/${id}/new-advertisement-group`
                ),
            },
            {
              label: t('Edit Campaign'),
              icon: <Edit />,
              onClick: () => historyPushInModal(`/dashboard/campaigns/${id}/edit`),
            },
            {
              label: t('Send leads report'),
              icon: <CloudDownload />,
              onClick: () => fetchLeadReport({ campaignIds: [id] }),
            },
            {
              label: t('Delete Campaign'),
              icon: <Delete />,
              onClick: () => onExecuteAction(),
            },
          ]}
        />
      </TableCell>
    </TableRow>
  );
}

export default compose(
  withTranslation(),
  withStyles(tableStyle),
  withConfirmableAction
)(CampaignRow);
