import { Icon, Tooltip } from '@material-ui/core';
import React from 'react';

class WithLock extends React.Component {
  state = { isLocked: true };

  onToggleLock = () => {
    this.setState((oldState) => ({ isLocked: !oldState.isLocked }));
  };

  render() {
    const { isLocked } = this.state;
    const { children } = this.props;
    const child = React.Children.only(children);
    return (
      <span style={{ width: '100%' }}>
        <Tooltip title="This is currently locked, because editing will change this in the whole campaign.">
          <Icon onClick={this.onToggleLock}>{isLocked ? 'lock' : 'lock_open'}</Icon>
        </Tooltip>
        <br />
        {React.cloneElement(child, { disabled: isLocked })}
      </span>
    );
  }
}

export default WithLock;
