import { DateTimePicker } from '@material-ui/pickers';
import { noop } from 'lodash';
import React from 'react';

function FormDateTime({ field, form, ...rest }) {
  const showError = form.errors[field.name] && form.touched[field.name];
  return (
    <DateTimePicker
      {...field}
      {...rest}
      ampm={false}
      disabled={form.isSubmitting}
      error={showError}
      helperText={showError && form.errors[field.name]}
      onBlur={
        // Don't make the field "touched" until the dialog is closed
        noop
      }
      onChange={(value) => form.setFieldValue(field.name, value)}
      onClose={() => form.setFieldTouched(field.name)}
    />
  );
}

export default FormDateTime;
