import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import HelpButton from './HelpButton';

const styles = {
  parentFormContainer: { display: 'flex', margin: '1em 0' },
};

const InputContainer = withStyles(styles)(
  ({ classes, children, helpTopic, onShowHelp, show }) => {
    if (show == null || show === true) {
      return (
        <Grid item className={classes.parentFormContainer}>
          {children}
          <HelpButton onClick={() => onShowHelp(helpTopic)} />
        </Grid>
      );
    } else {
      return <></>;
    }
  }
);

export default InputContainer;
