import numeral from 'numeral';

import { AnalyticsObject } from './types';

type AnalyticsObjectFormatter = (data: AnalyticsObject) => string | number;

type AnalyticsColumn = {
  formatter: AnalyticsObjectFormatter;
};

function formattedDivision(
  dividend: number,
  divisior: number,
  { isPercentage }: { isPercentage?: boolean } = {}
) {
  if (divisior === 0) {
    return '-';
  }
  return numeral(dividend / divisior).format(isPercentage ? '0.00%' : '0.00');
}

function simpleFormatter(columnName: keyof AnalyticsObject) {
  return function formatter(data: AnalyticsObject) {
    return data[columnName];
  };
}

const AnalyticsColumns: Record<string, AnalyticsColumn> = {
  CTR: {
    formatter: (data) =>
      formattedDivision(data.clicks, data.impressions, { isPercentage: true }),
  },
  Impressions: {
    formatter: simpleFormatter('impressions'),
  },
  'Conversion Rate (Impressions)': {
    formatter: (data) =>
      formattedDivision(data.submissions, data.impressions, { isPercentage: true }),
  },
  'Conversion Rate (Clicks)': {
    formatter: (data) =>
      formattedDivision(data.submissions, data.clicks, { isPercentage: true }),
  },
  Clicks: {
    formatter: (data) => data.clicks,
  },
  'Form Shows': {
    formatter: (data) => data.formShows,
  },
  'Form Changes': {
    formatter: (data) => data.formChanges,
  },
  Submissions: {
    formatter: (data) => data.submissions,
  },
};

export const AnalyticsColumnsNames: readonly string[] = Object.keys(AnalyticsColumns);

export default AnalyticsColumns;
