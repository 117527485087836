// copied from https://github.com/jurassix/react-display-name/blob/d474eb4ea177e0aa9e832850be7f0382efae1ac0/src/getDisplayName.js

import React from 'react';

export default function getComponentDisplayName(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: React.ComponentType<any> | string
): string {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const TheComponent: any = Component;
  return (
    TheComponent.displayName ||
    TheComponent.name ||
    (typeof TheComponent === 'string' && TheComponent.length > 0
      ? TheComponent
      : 'Unknown')
  );
}
