// import locale for numeral
import 'numeral/locales/de';

// import locales for date-fns
import deLocale from 'date-fns/locale/de';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import numeral from 'numeral';

import deTranslations from './i18n-data/de/translations.json';

window.__datefns_locale_map__ = { de: deLocale, 'de-AT': deLocale };

i18next.on('languageChanged', (languageTag) => {
  const [language] = languageTag.split('-');

  if (language !== 'de') {
    // default to english, or german
    return;
  }
  numeral.locale(language);
});

const I18NEXT_CONFIG = {
  detection: { excludeCacheFor: ['cimode'] },

  // we init with resources
  resources: {
    de: {
      // WARNING: remember to add a `<Suspense />` element after changing it to using
      // dynamic loading
      translations: deTranslations,
    },
  },
  fallbackLng: 'en',
  debug: false,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },

  react: {
    wait: true,
  },
};

i18next.use(LanguageDetector).init(I18NEXT_CONFIG);

export default i18next;
