const login = (token) => {
  localStorage.setItem('auth', JSON.stringify(token));
};

const logout = () => {
  localStorage.removeItem('auth');
};

const isAuthenticated = () => {
  // validates if the token is present and working
  return localStorage.getItem('auth') != null;
};

const token = () => {
  return JSON.parse(localStorage.getItem('auth'));
};

export default { login, logout, isAuthenticated, token };
