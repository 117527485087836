import { IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  CloudDownload,
  Delete,
  Edit,
  FileCopy,
  RemoveRedEye,
} from '@material-ui/icons';
import { flowRight as compose } from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import useFetchLeadReport from '@/utils/useFetchLeadReport';
import useHistoryPushInModal from '@/utils/useHistoryPushInModal';

import {
  advertisementValidationSchema,
  submitTextValidationSchema,
} from '../AdvertisementForm';
import { withConfirmableAction } from '../ConfirmDialog';
import { useDisableAdvertisementMutation } from './__generated__/DisableAdvertisementMutation';
import { useEnableAdvertisementMutation } from './__generated__/EnableAdvertisementMutation';
import AnalyticCells from './AnalyticCells';
import RowMenu from './RowMenu';
import StatusBadge from './StatusBadge';
import SwitchWithValidationWarning from './SwitchWithValidationWarning';
import tableStyle from './tableStyle';

const validationSchema = advertisementValidationSchema.concat(
  submitTextValidationSchema
);

function AdvertisementRow({
  onExecuteAction,
  campaignId,
  advertisementGroupId,
  classes,
  advertisement,
  t,
  cloneAdvertisement,
}) {
  const historyPushInModal = useHistoryPushInModal();
  const [disableAdvertisement] = useDisableAdvertisementMutation();
  const [enableAdvertisement] = useEnableAdvertisementMutation();
  const fetchLeadReport = useFetchLeadReport();

  const { name, id, active, valid, analytics, previewUrl, leadCount } = advertisement;

  return (
    <TableRow hover className={classes.expandedTableRow}>
      <TableCell />
      <TableCell>
        <SwitchWithValidationWarning
          checked={active && valid}
          disabled={!valid}
          object={advertisement}
          disableMutation={disableAdvertisement}
          enableMutation={enableAdvertisement}
          validationSchema={validationSchema}
          fixUrl={`/dashboard/campaigns/${campaignId}/${advertisementGroupId}/${id}/edit`}
        />
      </TableCell>
      <TableCell />
      <TableCell style={{ paddingLeft: '6em' }}> {name} </TableCell>
      <TableCell>
        <StatusBadge active={active} valid={valid} />
      </TableCell>
      <TableCell align="right">{leadCount}</TableCell>
      <TableCell>
        <Tooltip title="Send leads report">
          <IconButton onClick={() => fetchLeadReport({ advertisementIds: [id] })}>
            <CloudDownload />
          </IconButton>
        </Tooltip>
      </TableCell>
      <AnalyticCells analyticsObject={analytics} />
      <TableCell style={{ textAlign: 'right' }}>
        <RowMenu
          menuItems={[
            {
              label: t('Clone Advertisement'),
              icon: <FileCopy />,
              onClick: () => cloneAdvertisement(id),
            },
            {
              label: t('Preview'),
              icon: <RemoveRedEye />,
              onClick: () => window.open(previewUrl, '_blank'),
            },
            {
              label: t('Edit Advertisement'),
              icon: <Edit />,
              onClick: () =>
                historyPushInModal(
                  `/dashboard/campaigns/${campaignId}/${advertisementGroupId}/${id}/edit`
                ),
            },
            {
              label: t('Send Report'),
              icon: <CloudDownload />,
              onClick: () => fetchLeadReport({ advertisementIds: [id] }),
            },
            {
              label: t('Delete Advertisement'),
              icon: <Delete />,
              onClick: () => onExecuteAction(),
            },
          ]}
        />
      </TableCell>
    </TableRow>
  );
}

export default compose(
  withTranslation(),
  withRouter,
  withStyles(tableStyle),
  withConfirmableAction
)(AdvertisementRow);
