import { IconButton } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

const HelpButton = ({ onClick, style }) => (
  <IconButton
    style={{ ...style, width: 'auto', height: '5%', marginLeft: '0' }}
    onClick={onClick}
  >
    <Info />
  </IconButton>
);

HelpButton.propTypes = { onClick: PropTypes.func.isRequired };

export default HelpButton;
