import throttle from 'raf-throttle';
import React, { useEffect, useRef } from 'react';

// An empty SVG image without size defined, to ensure that the whole available size
// is used - otherwise available size calculations result could be incorrect.
const EMPTY_SVG =
  'data: image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciLz4=';

export default function ResponsiveImage({ src, alt, className }) {
  const hasResponsiveSrc = src && !(typeof src === 'string');

  const imgRef = useRef();
  // Don't try to use `useLayoutEffect` here, because the calculation could be incorrect
  // for responsive styles.
  useEffect(() => {
    if (!hasResponsiveSrc) {
      return;
    }

    const sourcesWithWidth = [src.original].concat(src.scaled);
    sourcesWithWidth.sort((first, second) => first.width - second.width);
    if (!sourcesWithWidth.length) {
      return;
    }

    const devicePixelRatio = window.devicePixelRatio || 1;

    function updateImageSource() {
      if (!imgRef.current) {
        return;
      }

      const availableWidth = imgRef.current.offsetWidth * devicePixelRatio;
      let newSource;

      for (const sourceData of sourcesWithWidth) {
        if (sourceData.width >= availableWidth) {
          newSource = sourceData.url;
          break;
        }
      }

      if (!newSource) {
        newSource = sourcesWithWidth[sourcesWithWidth.length - 1].url;
      }

      imgRef.current.src = newSource;
    }

    const updateImageSourceThrottled = throttle(updateImageSource);

    // The initial invocation of `updateImageSource` should happen inside of `setTimeout`,
    // otherwise the calculation is incorrect for responsive styles - the value of
    // `imgRef.current.offsetWidth` is lower and an incorrect image variant is chosen.
    const updateImageSourceTimeout = setTimeout(() => {
      updateImageSourceThrottled();
    });

    window.addEventListener('resize', updateImageSourceThrottled, false);

    return () => {
      clearTimeout(updateImageSourceTimeout);
      updateImageSourceThrottled.cancel();
      window.removeEventListener('resize', updateImageSourceThrottled, false);
    };
  }, [hasResponsiveSrc, src]);

  return (
    <img
      alt={alt}
      className={className}
      ref={imgRef}
      style={{ maxWidth: '100%' }}
      src={hasResponsiveSrc ? EMPTY_SVG : src}
    />
  );
}
