import React, { useContext } from 'react';

import { BaseDataQuery } from '../components/__generated__/BaseDataQuery';

export type FormFillers = Pick<
  BaseDataQuery,
  'companies' | 'countries' | 'keywords' | 'regions' | 'websites'
>;

export const FormFillersContext = React.createContext<FormFillers | null>(null);

export function useFormFillers(): FormFillers {
  const formFillers = useContext(FormFillersContext);
  if (!formFillers) {
    throw new Error('Form fillers not loaded');
  }
  return formFillers;
}

export function useFormFillersNullable(): FormFillers | null {
  return useContext(FormFillersContext);
}
