import classnames from 'classnames';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ResponsiveImage from './ResponsiveImage';

const ArticleState = Object.freeze({
  BANNER: 1,
  FORM: 2,
  SUBMIT_SCREEN: 3,
});

const MARKDOWN_OPTIONS = {
  overrides: {
    a: {
      props: {
        target: '_blank',
        rel: 'noopener',
      },
    },
  },
};

function MarkdownRender({ children }) {
  return <Markdown options={MARKDOWN_OPTIONS}>{children}</Markdown>;
}

const CampaignType = Object.freeze({
  QUIZ: 'QUIZ',
  WHITEPAPER_DOWNLOAD: 'WHITEPAPER_DOWNLOAD',
});

function template(str, obj) {
  return str.replace(/\${(.*?)}/g, (x, g) => obj[g]);
}

const articleDataShape = PropTypes.shape({
  teaser: PropTypes.string.isRequired,
  buttontext: PropTypes.string.isRequired,
});

function LeadPublisher({ articleData, onClose, showPublisher, style }) {
  const adMarker = !showPublisher;

  const handleClose = (event) => {
    event.stopPropagation();
    onClose?.();
  };

  return (
    <div className={style.leadPublisher}>
      {adMarker && (
        <>
          <div className={style.markPayed}></div>
        </>
      )}
      {showPublisher && (
        <>
          <ResponsiveImage
            className={style.pubLogo}
            src={articleData.publisher_logo}
            alt={articleData.publisher}
          />
          <button className={style.closeBruce} onClick={handleClose}></button>
        </>
      )}
    </div>
  );
}

function AnimatedArrow({ style }) {
  return (
    <svg className={style.svgArrows}>
      <path
        className={classnames(style.arrowPath, style.arrow1)}
        d="M0 0 L5 10 L10 0"
      ></path>
      <path
        className={classnames(style.arrowPath, style.arrow2)}
        d="M0 10 L5 20 L10 10"
      ></path>
    </svg>
  );
}

function QuizBanner({ articleData, onClose, onShowForm, style }) {
  const [showQuiz, setShowQuiz] = useState(false);

  const handleClose = () => {
    onClose?.();
    setShowQuiz(false);
  };

  return (
    <>
      <LeadPublisher
        articleData={articleData}
        onClose={handleClose}
        showPublisher={showQuiz}
        style={style}
      />
      <div
        className={classnames(style.leadBoxContent, style.leadBoxQuiz)}
        onClick={() => setShowQuiz(true)}
      >
        <ResponsiveImage
          className={classnames(style.leadArticlePi, style.leadQuizPic)}
          src={articleData.articlepicture}
          alt="article picture"
        />
        <h2 className={style.leadHeadLine}>{articleData.title}</h2>
        {showQuiz ? (
          <>
            <div className={classnames(style.leadTeaserBanner)}>
              <p className={classnames(style.leadQuizQuestion)}>
                <MarkdownRender>{articleData.teaser}</MarkdownRender>
              </p>
            </div>
            <div className={style.quizAnswersBlock}>
              {articleData.quiz_answers.map((answer) => (
                <button
                  key={answer}
                  type="submit"
                  onClick={() => onShowForm(answer)}
                  className={style.buttonQuizAnswer}
                >
                  {answer}
                </button>
              ))}
            </div>
          </>
        ) : (
          <div className={style.btnArrowsDown}>
            <>
              <button
                type="submit"
                className={classnames(style.leadSubmit, style.submitButtonDefault)}
              >
                {articleData.buttontext}
              </button>
              <AnimatedArrow style={style} />
            </>
          </div>
        )}
      </div>
    </>
  );
}

QuizBanner.propTypes = {
  articleData: articleDataShape.isRequired,
  onShowForm: PropTypes.func.isRequired,
};

function BannerContainer({ articleData, children, onShowForm, style }) {
  return (
    <div
      onClick={onShowForm}
      className={classnames(
        style.leadBoxContent,
        style.bannerContainer,
        style.firstBannerComponent
      )}
    >
      <ResponsiveImage src={articleData.articlepicture} alt="article" />
      {children}
    </div>
  );
}

function Banner({ articleData, onShowForm, style }) {
  return (
    <BannerContainer articleData={articleData} onShowForm={onShowForm} style={style}>
      <h2 className={classnames(style.headlineBanner, style.leadHeadLine)}>
        {articleData.title}
      </h2>
      <div className={style.btnArrowsDown}>
        <button
          type="button"
          className={classnames(style.leadSubmit, style.submitButtonDefault)}
        >
          {articleData.buttontext}
        </button>
        <AnimatedArrow style={style} />
      </div>
    </BannerContainer>
  );
}

Banner.propTypes = {
  articleData: articleDataShape.isRequired,
  onShowForm: PropTypes.func.isRequired,
};

function ImageBanner({ articleData, onShowForm, style }) {
  return (
    <BannerContainer articleData={articleData} onShowForm={onShowForm} style={style} />
  );
}

function FormFieldAddons({ required, style }) {
  if (!required) {
    return null;
  }

  return <span className={style.requiredMark}>*</span>;
}

FormFieldAddons.propTypes = {
  required: PropTypes.bool,
  style: PropTypes.object.isRequired,
};

function FormField(props) {
  const {
    title,
    type,
    name,
    format,
    description,
    required,
    displayAs,
    descriptions,
    titles,
    style,
    templateObject,
    onFocus,
  } = props;
  const enum_ = props['enum'];
  const [elementId] = useState(
    () => `bl-${name}-${Math.random().toString(36).slice(2)}`
  );
  let fieldType = 'text';
  let accept;

  switch (type) {
    case 'string':
      switch (format) {
        case 'date':
          fieldType = 'date';
          break;
        case 'telephone-number':
          fieldType = 'tel';
          break;
        case 'email':
          fieldType = 'email';
          break;
        case 'url':
          fieldType = 'url';
          break;
        case 'binary':
          fieldType = 'file';
          if (displayAs === 'imageUpload') {
            accept = 'image/png, image/jpeg';
          }
          break;
        default:
          break;
      }
      if (enum_) {
        if (displayAs === 'radio') {
          return (
            <React.Fragment>
              <div className={style.radioButtonContainer}>
                {enum_.map((value, index) => (
                  <React.Fragment key={value}>
                    <div className={style.radioButtonInputText}>
                      <input
                        type="radio"
                        id={value}
                        name={name}
                        value={value}
                        required={required}
                        onFocus={onFocus}
                      />
                      <label htmlFor={value}>
                        <MarkdownRender>
                          {template(descriptions[index], templateObject)}
                        </MarkdownRender>
                      </label>
                      <FormFieldAddons required={required} style={style} />
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </React.Fragment>
          );
        }
        return (
          <React.Fragment>
            <div className={style.selectContainer} onFocus={onFocus}>
              <label htmlFor={elementId}>{title}</label>
              <select name={name} id={elementId} required={required}>
                {enum_.map((value, index) => (
                  <option key={value} value={value}>
                    {titles[index]}
                  </option>
                ))}
              </select>
              <FormFieldAddons required={required} style={style} />
            </div>
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          <div className={style.inputContainer}>
            <label htmlFor={elementId}>{title}</label>
            <input
              type={fieldType}
              name={name}
              id={elementId}
              required={required}
              accept={accept}
              onFocus={onFocus}
            />
            <FormFieldAddons required={required} style={style} />
          </div>
        </React.Fragment>
      );
    case 'number':
      return (
        <React.Fragment>
          <div className={style.inputContainer}>
            <label htmlFor={elementId}>{title}</label>
            <input
              type="number"
              name={name}
              id={elementId}
              required={required}
              onFocus={onFocus}
            />
            <FormFieldAddons required={required} style={style} />
          </div>
        </React.Fragment>
      );

    case 'boolean':
      return (
        <React.Fragment>
          <div id="checkBoxContainer" className={style.TOSTag}>
            <input type="checkbox" required={required} name={name} onFocus={onFocus} />
            <MarkdownRender>{template(description, templateObject)}</MarkdownRender>
            <FormFieldAddons required={required} style={style} />
          </div>
        </React.Fragment>
      );

    case undefined:
      if (displayAs === 'description') {
        return (
          <div className={style.TOSTag}>
            <MarkdownRender>{template(description, templateObject)}</MarkdownRender>
          </div>
        );
      }
      break;

    default:
      // TODO: should it stay? If not remove also the `eslint-disable`
      // declaration on the top of this file
      // eslint-disable-next-line no-console
      console.warn(`not supported formfield "${type}"`);
      return null;
  }
}

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['string', 'number', 'boolean', 'binary']).isRequired,
  displayAs: PropTypes.oneOf(['dropdown', 'radio', 'description', 'imageUpload'])
    .isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  descriptions: PropTypes.arrayOf(PropTypes.string),
  titles: PropTypes.arrayOf(PropTypes.string),
};

FormField.defaultProps = {
  title: null,
  description: null,
  required: false,
  descriptions: null,
  titles: null,
};

function FormFields({ formSchema, style, templateObject, onInputFocus }) {
  return (formSchema.displayOrder || []).map((fieldName) => (
    <FormField
      key={fieldName}
      name={fieldName}
      style={style}
      required={formSchema.required && formSchema.required.indexOf(fieldName) !== -1}
      templateObject={templateObject}
      {...formSchema.properties[fieldName]}
      onFocus={onInputFocus}
    />
  ));
}

FormField.defaultProps = {
  onInputFocus: () => null,
};

/**
 * This renders a JSON schema into a form.
 * 
 * It takes exactly one json schema object, extended with a "displayOrder" property:
 * 
 * ```
 * {
 *  "type": "object",
 *  "required": ["name"],
 *  "displayOrder": ["name"],
 *  "properties": { … }
}

 * ```
 */
function Form({ formSchema, onSubmitClick, style, templateObject }) {
  return (
    <React.Fragment>
      <div
        id="bannerTra"
        className={classnames(style.leadBoxContent, style.formSubmitBannerContainer)}
      >
        <div className={style.formWrapper}>
          <FormFields
            formSchema={formSchema}
            style={style}
            templateObject={templateObject}
          />
        </div>
        <input
          className={classnames(style.leadSubmit, style.submitButtonDefault)}
          type="submit"
          onClick={onSubmitClick}
          value={templateObject.submit_buttontext}
        />
      </div>
    </React.Fragment>
  );
}

Form.propTypes = {
  formSchema: PropTypes.shape({
    displayOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  onSubmitClick: PropTypes.func.isRequired,
};

const SubmitScreen = ({ articleData, style }) => {
  let finalText = articleData.final_text;
  let finalTitle = articleData.final_title;
  let final;
  if (articleData.type === CampaignType.QUIZ) {
    const rightQuizAnswer = articleData.quiz_answers[articleData.correct_quiz_answer];
    if (finalText) {
      finalText = finalText.replace('{answer}', rightQuizAnswer);
    }
    if (finalTitle) {
      finalTitle = finalTitle.replace('{answer}', rightQuizAnswer);
    }
  }

  // This is not an ideal solution for the Whitepaper download
  if (articleData.type === CampaignType.WHITEPAPER_DOWNLOAD) {
    final = (
      <div className={style.whitePaperDownload}>
        <a
          className={classnames(style.leadSubmit, style.submitButtonDefault)}
          href={articleData.attachment_url}
        >
          <span>Download</span>
        </a>
      </div>
    );
  } else {
    final = (
      <div className={style.leadTeaserBanner}>
        <MarkdownRender>{finalText}</MarkdownRender>
      </div>
    );
  }
  return (
    <div className={classnames(style.leadBoxContent, style.thankYou)}>
      <ResponsiveImage
        className={classnames(style.leadArticlePi, style.thankYouPic)}
        src={articleData.final_picture}
        alt="articlePicture"
      />
      <h2 className={style.leadHeadLine}>{finalTitle}</h2>
      {final}
    </div>
  );
};

SubmitScreen.propTypes = {
  articleData: articleDataShape.isRequired,
};

function BoxShadow(props) {
  const { articleState, articleData, onClose, style } = props;

  switch (articleState) {
    case ArticleState.BANNER:
      // This is the beginning state

      if (articleData.type === CampaignType.QUIZ) {
        return <QuizBanner {...props} onClose={onClose} />;
      }

      if (articleData.teaser_display_mode === 'IMAGE_ONLY') {
        return <ImageBanner {...props} />;
      }

      return <Banner {...props} />;
    case ArticleState.FORM:
      return (
        articleData.form_schema && (
          <Form
            formSchema={articleData.form_schema}
            style={style}
            templateObject={articleData}
          />
        )
      );
    case ArticleState.SUBMIT_SCREEN:
      return <SubmitScreen {...props} />;
    default:
      return null;
  }
}

BoxShadow.propTypes = {
  articleData: articleDataShape.isRequired,
  articleState: PropTypes.number.isRequired,
};

class LeadItNews extends React.Component {
  outerRef = React.createRef();

  constructor() {
    super();
    this.state = { style: {} };
  }

  componentDidMount() {
    const { styleName } = this.props;

    const importPromise = import(`./native-websites/${styleName}.scss`);

    importPromise
      .then((styleModule) => {
        // Prevent warnings about changing state of unmounted component
        if (this.outerRef.current) {
          this.setState({ style: styleModule.default });
        }
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.warn('Error loading style', error));
  }

  render() {
    const { articleState, articleData, onClose } = this.props;
    const { style } = this.state;
    const shouldShowPublisher = articleState !== ArticleState.BANNER;
    // QuizBanner manages lead publisher rendering in banner by itself
    const renderLeadPublisher =
      shouldShowPublisher || articleData.type !== CampaignType.QUIZ;

    return (
      <div className={style.leadItNewsOuter} ref={this.outerRef}>
        <div className={style.leadContainer}>
          <div id="Shadowbox" className={classnames(style.leadWrap)}>
            {renderLeadPublisher && (
              <LeadPublisher
                articleData={articleData}
                onClose={onClose}
                showPublisher={shouldShowPublisher}
                style={style}
              />
            )}
            <BoxShadow {...this.props} onClose={onClose} style={style} />
          </div>
        </div>
      </div>
    );
  }
}

LeadItNews.propTypes = { styleName: PropTypes.string };

LeadItNews.defaultProps = { styleName: 'base' };

export { ArticleState, LeadItNews };
