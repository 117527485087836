import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import { keyBy } from 'lodash';
import React from 'react';

import { useFormFillers } from '@/utils/FormFillersContext';

import { CampaignFragment } from '../__generated__/CampaignFragment';
import FormSelect from '../forms/FormSelect';
import InputContainer from '../InputContainer';
import Newsletter2GoConfigurator from './Newsletter2GoConfigurator';
import TestButton from './TestButton';
import WebhookConfigurator from './WebhookConfigurator';

type Props = {
  campaign: CampaignFragment;
  onShowHelp: (topic: string) => void;
  values: {
    integrationId?: number;
    integrationMapping?: Record<string, unknown>;
    integrationMeta?: Record<string, unknown>;
  };
};

type IntegrationName = 'newsletter2go' | 'webhook';
type IntegrationProps = {
  campaign: CampaignFragment;
  onShowHelp: (topic: string) => void;
};
type IntegrationComponent = React.FunctionComponent<IntegrationProps>;

const INTEGRATION_COMPONENT_MAPPING: Record<IntegrationName, IntegrationComponent> = {
  newsletter2go: Newsletter2GoConfigurator,
  webhook: WebhookConfigurator,
};

/**
 * This is a wizard page to select and configure a integration.
 */
function IntegrationForm({ campaign, onShowHelp, values }: Props): React.ReactNode {
  const formFillers = useFormFillers();
  const currentCompany = formFillers.companies.find(
    (company) => company.id === campaign.companyId
  );
  const integrationsById = currentCompany
    ? keyBy(currentCompany?.integrations, 'id')
    : {};

  const integrations = currentCompany
    ? currentCompany.integrations.map((integration) => ({
        label: integration.label,
        value: integration.id,
      }))
    : [];

  integrations.unshift({ label: 'No Integration', value: '' });
  const selectedIntegration =
    values.integrationId && integrationsById[values.integrationId];

  const ConfiguratorComponent: IntegrationComponent =
    selectedIntegration && INTEGRATION_COMPONENT_MAPPING[selectedIntegration.name];
  return (
    <Grid container direction="column" spacing={8} alignContent="stretch">
      <React.Fragment>
        <InputContainer helpTopic="integrations.integration" onShowHelp={onShowHelp}>
          {
            <Field
              component={FormSelect}
              controlProps={{ style: { width: '92%' } }}
              id="integrationId"
              label="Integration"
              name="integrationId"
              options={integrations}
            />
          }
        </InputContainer>
        {ConfiguratorComponent && (
          <ConfiguratorComponent campaign={campaign} onShowHelp={onShowHelp} />
        )}
        <br />
        {selectedIntegration && (
          <TestButton
            campaignId={campaign.id}
            integrationMeta={values.integrationMeta}
            selectedIntegration={selectedIntegration}
          />
        )}
      </React.Fragment>
    </Grid>
  );
}

export default IntegrationForm;
