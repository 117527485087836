import { Field } from 'formik';
import React from 'react';

import useDataFetch from '@/utils/useDataFetch';

import { CampaignFragment } from '../__generated__/CampaignFragment';
import FormSelect from '../forms/FormSelect';
import InputContainer from '../InputContainer';
import IntegrationMappingEditor from './IntegrationMappingEditor';

type Props = {
  campaign: CampaignFragment;
  onShowHelp: (topic: string) => void;
};

type Newsletter2goAddressBook = {
  id: string;
  name: string;
};

const Newsletter2GoConfigurator: React.FunctionComponent<Props> = ({
  campaign,
  onShowHelp,
}: Props) => {
  const lists = useDataFetch<Newsletter2goAddressBook[]>(
    `/integrations/newsletter2go/${campaign.integrationId}/lists`
  );

  if (campaign.integrationId) {
    return (
      <>
        <InputContainer helpTopic="integrations.list_id" onShowHelp={onShowHelp}>
          {lists ? (
            <Field
              component={FormSelect}
              controlProps={{ style: { width: '92%' } }}
              id="newsletter2go_list"
              label="NewsLetter2GoList"
              name="integrationMeta.list_id"
              options={lists.map((list) => ({
                value: list.id,
                label: list.name,
              }))}
            />
          ) : (
            'loading lists'
          )}
        </InputContainer>
        <Field
          campaign={campaign}
          component={IntegrationMappingEditor}
          name="integrationMapping"
        />
      </>
    );
  }
  return null;
};

export default Newsletter2GoConfigurator;
