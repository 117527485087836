import axios from 'axios';
import { useEffect, useRef, useState } from 'react';

export default function useDataFetch<T>(url: string): T | null {
  const [data, setData] = useState<T | null>(null);
  const requestId = useRef(0);

  useEffect(() => {
    async function fetchData() {
      const currentRequestId = requestId.current;
      const response = await axios.get(url);
      // The initialized request might be not the current one. Don't try
      // to set data in that case.
      if (currentRequestId === requestId.current) {
        setData(response.data);
      }
    }

    requestId.current += 1;
    fetchData();
  }, [url]);

  return data;
}
