import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  TextField,
} from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, FormikHelpers } from 'formik';
import { flowRight as compose } from 'lodash';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import React from 'react';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import * as yup from 'yup';

// @ts-expect-error Webpack import
import logoSvg from '../assets/logo.svg';
import Auth from '../utils/Auth';

type LoginFormValues = {
  email: string;
  password: string;
  secondFactor: string;
};

type LoginFromProps = {
  handleSubmitForm: (
    arg1: LoginFormValues,
    arg2: FormikHelpers<LoginFormValues>
  ) => Promise<void>;
  isFirstPhase: boolean;
};

const useStyles = makeStyles({
  card: {
    marginTop: '50%',
    padding: '2em',
  },

  cardActions: {
    justifyContent: 'center',
  },
});

function LoginForm({ handleSubmitForm, isFirstPhase }: LoginFromProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Formik
      validationSchema={yup.object({
        email: yup.string().email(t('Input has to be a E-Mail address')),
        password: yup.string(),
      })}
      initialValues={{
        email: '',
        password: '',
        secondFactor: '',
      }}
      onSubmit={handleSubmitForm}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        isSubmitting,
        isValid,
        touched,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Card className={classes.card}>
            <CardMedia
              component="img"
              alt="Bruce Leads"
              image={logoSvg}
              title="Wuuuu-haaa Kiiiiick"
            />
            <CardContent>
              <TextField
                error={touched.email && errors.email !== undefined}
                helperText={touched.email && errors.email}
                disabled={isSubmitting || !isFirstPhase}
                autoFocus
                onBlur={handleBlur}
                name="email"
                label="E-Mail"
                value={values.email}
                onChange={handleChange}
                fullWidth
              />
              <p />
              <TextField
                error={touched.password && errors.password !== undefined}
                helperText={errors.password}
                disabled={isSubmitting || !isFirstPhase}
                name="password"
                label={t('Password')}
                type="password"
                onBlur={handleBlur}
                value={values.password}
                onChange={handleChange}
                fullWidth
              />
              <p />
              <TextField
                error={touched.secondFactor && errors.secondFactor !== undefined}
                helperText={errors.secondFactor}
                disabled={isSubmitting || isFirstPhase}
                name="secondFactor"
                label={t('Second Factor')}
                onBlur={handleBlur}
                value={values.secondFactor}
                onChange={handleChange}
                fullWidth
              />
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button
                color="secondary"
                disabled={isSubmitting || !isValid}
                type="submit"
              >
                {isFirstPhase
                  ? t('Send Verification Code')
                  : t('Verify and Kickass Login')}
              </Button>
              <CircularProgress style={isSubmitting ? {} : { display: 'none' }} />
            </CardActions>
          </Card>
        </form>
      )}
    </Formik>
  );
}

type LoginPageProps = WithSnackbarProps & WithTranslation & RouteComponentProps;

type TwoFactorState = {
  firstPhaseToken: string | null;
};

interface FormDataObject {
  [index: string]: string;
}

interface TokenResponse {
  access_token?: string;
  token_type?: string;
  detail?: string;
}

async function sendAuthentication(
  url: string,
  formData: FormDataObject,
  headers?: HeadersInit
): Promise<string> {
  const bodyFormData = new FormData();

  for (const [key, value] of Object.entries(formData)) {
    bodyFormData.append(key, value);
  }

  const response = await fetch(url, {
    method: 'post',
    body: bodyFormData,
    headers,
  });

  const responseJson: TokenResponse = await response.json();

  if (!response.ok) {
    if (responseJson) {
      if (responseJson.detail) {
        throw new Error(responseJson.detail);
      }
    }
    throw new Error(response.statusText);
  }
  if (!responseJson.access_token) {
    throw new Error('No access token returned.');
  }

  return responseJson.access_token;
}

class LoginPage extends React.Component<LoginPageProps, TwoFactorState> {
  state: Readonly<TwoFactorState> = {
    firstPhaseToken: null,
  };

  handleLoginSubmit = async (
    { email, password, secondFactor }: LoginFormValues,
    { setSubmitting }: FormikHelpers<LoginFormValues>
  ) => {
    const { t, enqueueSnackbar } = this.props;

    try {
      if (this.state.firstPhaseToken === null) {
        // first phase of 2 factor authentication

        const token = await sendAuthentication('/auth/token-first-phase', {
          username: email,
          password,
        });
        this.setState({ firstPhaseToken: token });
      } else {
        // second phase of 2 factor authentication

        const token = await sendAuthentication(
          '/auth/token-second-phase',
          { factor: secondFactor },
          {
            Authorization: `Bearer ${this.state.firstPhaseToken}`,
          }
        );

        Auth.login(token);
        this.props.history.push('/');
      }
    } catch (e) {
      enqueueSnackbar(String(t(e)), {
        variant: 'error',
      });
    }

    setSubmitting(false);
  };

  render() {
    return (
      <Grid container justify="center" alignItems="center">
        <Grid item>
          <LoginForm
            handleSubmitForm={this.handleLoginSubmit}
            isFirstPhase={this.state.firstPhaseToken === null}
          />
        </Grid>
      </Grid>
    );
  }
}

const LoginPageComposed = compose(withSnackbar, withTranslation())(LoginPage);

export default LoginPageComposed as LoginPage;
