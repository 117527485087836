import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field, FormikHelpers, useFormikContext } from 'formik';
import i18next from 'i18next';
import { pick } from 'lodash';
import React from 'react';
import * as yup from 'yup';

import { EditAdvertisementGroupInput, Scalars } from '../__generated__/graphql-types';
import { useCurrentUser } from '../utils/CurrentUserContext';
import { useFormFillers, useFormFillersNullable } from '../utils/FormFillersContext';
import { AdvertisementGroupFragment } from './__generated__/AdvertisementGroupFragment';
import { useCreateAdvertisementGroupMutation } from './__generated__/CreateAdvertisementGroupMutation';
import { useUpdateAdvertisementGroupMutation } from './__generated__/UpdateAdvertisementGroupMutation';
import FormDateTime from './forms/FormDateTime';
import FormInput from './forms/FormInput';
import FormMultiSelect from './forms/FormMultiSelect';
import InputContainer from './InputContainer';

type Props = {
  onShowHelp: (topic: string) => void;
};

const useStyles = makeStyles({ formWidth: { width: '90%' } });

function AdvertisementGroupForm({ onShowHelp }: Props): JSX.Element {
  const classes = useStyles();
  const formikContext = useFormikContext();
  const formFillers = useFormFillers();
  const currentUser = useCurrentUser();
  const canEditCountries = currentUser?.canEditCountries;
  const canEditRegions = currentUser?.canEditRegions;

  function selectEuropeanCountries() {
    const europeanCountryIds = formFillers.countries
      .filter((country) => country.continent === 'Europe')
      .map((country) => country.id);
    formikContext.setFieldValue('countryIds', europeanCountryIds);
  }

  return (
    <Grid container direction="column" spacing={1}>
      <InputContainer helpTopic="advertisement_group.name" onShowHelp={onShowHelp}>
        <Field
          className={classes.formWidth}
          component={FormInput}
          name="name"
          label="Advertisement Group Name"
        />
      </InputContainer>
      <InputContainer
        helpTopic="advertisement_group.start_timestamp"
        onShowHelp={onShowHelp}
      >
        <Field
          className={classes.formWidth}
          component={FormDateTime}
          label="Start Time"
          name="startTimestamp"
        />
      </InputContainer>
      <InputContainer
        helpTopic="advertisement_group.end_timestamp"
        onShowHelp={onShowHelp}
      >
        <Field
          className={classes.formWidth}
          component={FormDateTime}
          label="End Time"
          name="endTimestamp"
        />
      </InputContainer>
      <InputContainer helpTopic="advertisement_group.country" onShowHelp={onShowHelp}>
        <Field
          component={FormMultiSelect}
          controlProps={{ className: classes.formWidth }}
          disabled={!canEditCountries}
          id="countryIds"
          label="Countries"
          name="countryIds"
          options={formFillers.countries}
        />
      </InputContainer>
      {canEditCountries && (
        <div>
          <Button onClick={selectEuropeanCountries}>
            Select all european countries
          </Button>
        </div>
      )}
      {canEditRegions && (
        <InputContainer helpTopic="advertisement_group.regions" onShowHelp={onShowHelp}>
          <Field
            className={classes.formWidth}
            component={FormMultiSelect}
            controlProps={{ className: classes.formWidth }}
            label="Regions"
            name="regionIds"
            options={formFillers.regions}
          />
        </InputContainer>
      )}
      <InputContainer helpTopic="advertisement_group.keywords" onShowHelp={onShowHelp}>
        <Field
          className={classes.formWidth}
          component={FormMultiSelect}
          controlProps={{ className: classes.formWidth }}
          label="Keywords"
          name="keywordIds"
          options={formFillers.keywords}
        />
      </InputContainer>
      {/* <Grid>
            <Field
              controlProps={{ className: classes.formWidth }}
              component={FormSelect}
              label="Gender"
              name="gender"
              options={[
                { value: 'UNKNOWN', label: 'Any' },
                { value: 'MALE', label: 'Male' },
                { vale: 'FEMALE', label: 'Female' },
              ]}
            />
            <HelpButton onClick={() => onShowHelp('advertisement_group.gender')} />
          </Grid>
          <Grid>
            <Field
              controlProps={{ className: classes.formWidth }}
              component={FormSelect}
              label="Age-Group"
              name="age_group"
              options={[
                { value: 13, label: '13-17' },
                { value: 18, label: '18-24' },
                { value: 25, label: '25-34' },
                { value: 35, label: '35-44' },
                { value: 45, label: '45-54' },
                { value: 55, label: '55-64' },
                { value: 65, label: '65+' },
              ]}
            />
            <HelpButton onClick={() => onShowHelp('advertisement_group.age_group')} />
          </Grid>  */}
    </Grid>
  );
}

function useAdvertisementGroupTemplate(): () => EditAdvertisementGroupInput | null {
  const currentUser = useCurrentUser();
  const formFillers = useFormFillersNullable();

  function advertisementGroupTemplate(): EditAdvertisementGroupInput | null {
    let countryIds: number[] = [];

    if (currentUser && !currentUser.canEditCountries) {
      if (!formFillers) {
        return null;
      }
      if (formFillers?.countries.length === 1) {
        countryIds = [formFillers.countries[0].id];
      }
    } else if (formFillers) {
      // Make Austria the country selected by default
      countryIds = formFillers.countries
        .filter((country) => country.label === 'Austria')
        .map((country) => country.id);
    }

    return {
      countryIds,
      startTimestamp: new Date(),
      endTimestamp: new Date(),
      name: 'Default Advertisement-Group',
      // ageFrom: 0,
      // ageTo: 100,
      // gender: 'UNKNOWN',
      keywordIds: [],
      regionIds: [],
      // ageGroup: 13,
    };
  }
  return advertisementGroupTemplate;
}

const advertisementGroupValidationSchema = yup.object({
  startTimestamp: yup.date().required(),
  endTimestamp: yup
    .date()
    .required()
    .min(yup.ref('startTimestamp'), () =>
      i18next.t('The end time needs to be less than the start time')
    ),
});

export function useHandleAdvertisementGroupSubmit(
  campaignId: Scalars['UUID'] | null,
  advertisementGroupId?: Scalars['UUID'] | null,
  onSuccess?: (result: AdvertisementGroupFragment) => void
): (
  data: EditAdvertisementGroupInput,
  actions: FormikHelpers<EditAdvertisementGroupInput>
) => Promise<void> {
  const [createAdvertisementGroup] = useCreateAdvertisementGroupMutation();
  const [updateAdvertisementGroup] = useUpdateAdvertisementGroupMutation();
  const getAdvertisementGroupFields = useGetAdvertisementGroupFields();

  const handleAdvertisementGroupSubmit = async (data, actions) => {
    if (advertisementGroupId) {
      const result = await updateAdvertisementGroup({
        variables: {
          id: advertisementGroupId,
          input: data,
        },
      });
      // TODO: validate result
      const updatedAdvertisementGroup =
        result.data?.updateAdvertisementGroup.advertisementGroup;
      if (updatedAdvertisementGroup) {
        onSuccess?.(updatedAdvertisementGroup);
        actions.setValues(
          getAdvertisementGroupFields(updatedAdvertisementGroup, campaignId)
        );
      }
    } else {
      const result = await createAdvertisementGroup({
        variables: {
          campaignId,
          input: data,
        },
        update(cache, { data }) {
          if (!data?.createAdvertisementGroup?.advertisementGroup) {
            return;
          }

          cache.modify({
            id: `Campaign:${campaignId}`,
            fields: {
              advertisementGroups(existingAdGroupRefs = []) {
                const newAdGroupRef = cache.writeFragment({
                  fragment: AdvertisementGroupFragment,
                  fragmentName: 'AdvertisementGroupFragment',
                  data: data.createAdvertisementGroup.advertisementGroup,
                });
                return [...existingAdGroupRefs, newAdGroupRef];
              },
            },
          });
        },
      });
      // TODO: validate result
      const createdAdvertisementGroup =
        result.data?.createAdvertisementGroup.advertisementGroup;
      if (createdAdvertisementGroup) {
        onSuccess?.(createdAdvertisementGroup);
        actions.setValues(
          getAdvertisementGroupFields(createdAdvertisementGroup, campaignId)
        );
      }
    }
  };

  return handleAdvertisementGroupSubmit;
}

export function useGetAdvertisementGroupFields(): (
  advertisementGroup: AdvertisementGroupFragment | null | undefined,
  campaignId: string | null
) => EditAdvertisementGroupInput | null {
  const advertisementGroupTemplate = useAdvertisementGroupTemplate();

  function getAdvertisementGroupFields(
    advertisementGroup: AdvertisementGroupFragment | null | undefined,
    campaignId: string | null
  ): EditAdvertisementGroupInput | null {
    if (!advertisementGroup) {
      if (campaignId) {
        return advertisementGroupTemplate();
      }
      return null;
    }
    return pick(advertisementGroup, [
      'countryIds',
      'endTimestamp',
      'keywordIds',
      'name',
      'regionIds',
      'startTimestamp',
    ]);
  }
  return getAdvertisementGroupFields;
}

export {
  AdvertisementGroupForm,
  advertisementGroupValidationSchema,
  useAdvertisementGroupTemplate,
};
