import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Frame from 'react-frame-component';

import smartphonePng from '../assets/smartphone.png';

const styles = {
  iframeContent: {
    position: 'relative',
    transformOrigin: '0 0',
    border: 'none',
  },

  mainDiv: {
    height: '100%',
  },
  resizable: {
    width: '100%',
    float: 'left',
    overflow: 'hidden',
    backgroundImage: `url('${smartphonePng}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '150% 100%',
    backgroundPosition: 'top',
    position: 'relative',
    height: '38rem',
  },
  marginTopPersent: {
    height: '14.4%',
  },
  inner: {
    height: '72.6%',
    marginLeft: '6.5%',
    marginRight: '6%',
    backgroundColor: 'white',
  },
};

/**
 * Component to render its children within a virtual smartphone.
 *
 */
class SmartphonePreview extends React.Component {
  static propTypes = { width: PropTypes.number };

  static defaultProps = {
    width: 320, // 320 is a iphone 4
  };

  constructor() {
    super();
    this.observer = new MutationObserver(this.handleStyleChanges);
    this.state = { style: null };
  }

  componentDidMount() {
    this.observer.observe(document.head, { childList: true, subtree: true });
    this.handleStyleChanges();
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  handleStyleChanges = () => {
    // TODO: should it stay here?
    // eslint-disable-next-line no-console
    console.log('style changes', document.querySelectorAll('style#preview-css').length);
    let style = '';
    for (const styleElement of document.querySelectorAll('style#preview-css')) {
      style += `<style>${styleElement.innerHTML}</style>`;
    }
    this.setState({ style });
  };

  refCallback = (element) => {
    if (element) {
      const scale = element.clientWidth / this.props.width;
      this.setState({
        scale,
        height: element.clientHeight / scale,
      });
    }
  };

  render() {
    const { children, classes, width } = this.props;
    const { style, height, scale } = this.state;

    return (
      <div className={classes.resizable}>
        <div className={classes.marginTopPersent} />
        <div className={classes.inner} ref={this.refCallback}>
          <div className={classes.mainDiv}>
            <Frame
              className={classes.iframeContent}
              initialContent={`<!DOCTYPE html><html><head>${style}<meta name="viewport" content="width=device-width, initial-scale=1"></head><body><div id="mountHere"></div></body></html>`}
              mountTarget="#mountHere"
              key={style}
              style={{
                transform: `scale(${scale})`,
                width: `${width}px`,
                height: `${height}px`,
              }}
            >
              {children}
            </Frame>
          </div>
        </div>
      </div>
    );
    // return (
    //   <div className={classes.parentContainer}>
    //     <img className={classes.imagePreview} src="/static/smartphone.svg" alt="" />
    //     <div title="smartphone preview" className={classes.previewContent}>
    //       <div title="smartphonePreviewInner" className={classes.previewContentInner}>
    //         {children}
    //       </div>
    //     </div>
    //   </div>
    // );
  }
}

export default withStyles(styles)(SmartphonePreview);
