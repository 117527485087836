import { Divider, IconButton, Menu, MenuItem } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Auth from '../utils/Auth';
import { useCurrentUser } from '../utils/CurrentUserContext';

type Props = RouteComponentProps;

function UserMenu(props: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const currentUser = useCurrentUser();

  const userEmail = currentUser && currentUser.email;
  const userRoles = currentUser?.roles;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    Auth.logout();
    props.history.push('/login');
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  // assemble menu here, since Menu does not support Fragments
  const menuItems: React.ReactElement[] = [
    <MenuItem disabled key="__user_email">
      {userEmail}
    </MenuItem>,
  ];

  if (userRoles?.length) {
    menuItems.push(<Divider key="__roles_divider" />);
    menuItems.push(
      <MenuItem disabled key="__roles">
        Roles:
      </MenuItem>
    );
    for (const role of userRoles) {
      menuItems.push(
        <MenuItem disabled key={role}>
          {role}
        </MenuItem>
      );
    }
  }

  menuItems.push(<Divider key="__logout_divider" />);
  menuItems.push(
    <MenuItem key="__logout" onClick={handleLogout}>
      Logout
    </MenuItem>
  );

  return (
    <div style={{ marginLeft: 'auto', marginRight: 0 }}>
      <IconButton
        aria-owns={open ? 'menu-appbar' : undefined}
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {menuItems}
      </Menu>
    </div>
  );
}

export default withRouter(UserMenu);
