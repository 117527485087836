import { TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import AnalyticsColumns from '@/utils/AnalyticsColumns';
import { AnalyticsObject } from '@/utils/types';
import useVisibleDashboardColumns from '@/utils/useVisibleDashboardColumns';

import analyticsStyles from './analyticsStyles';

type Props = {
  analyticsObject: AnalyticsObject;
  classes: Record<string, string>;
};

function AnalyticCells({ analyticsObject, classes }: Props) {
  const columnsManager = useVisibleDashboardColumns();
  return (
    <>
      {Object.keys(AnalyticsColumns).map((columnName) => {
        if (!columnsManager.isVisible(columnName)) {
          return null;
        }

        let formattedData: string | number;

        // data is not fetched yet (or not available)
        if (analyticsObject == null) {
          formattedData = '-';
        } else {
          const columnOptions = AnalyticsColumns[columnName];
          formattedData = columnOptions.formatter(analyticsObject);
        }
        return (
          <TableCell align="right" className={classes.analyticsCell} key={columnName}>
            {formattedData}
          </TableCell>
        );
      })}
    </>
  );
}

export default withStyles(analyticsStyles)(AnalyticCells);
