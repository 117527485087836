import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';

class MyDateFnsUtils extends DateFnsUtils {
  getDatePickerHeaderText(date: Date): string {
    return format(date, 'PP', { locale: this.locale });
  }

  dateFormat = 'PP';

  dateTime24hFormat = 'PPpp';
}

export default MyDateFnsUtils;
