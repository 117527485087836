import { useApolloClient } from '@apollo/client';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import { AdvertisementFragment } from './__generated__/AdvertisementFragment';
import { AdvertisementGroupFragment } from './__generated__/AdvertisementGroupFragment';
import { useCampaignFullWithRelatedQuery } from './__generated__/CampaignFullWithRelatedQuery';
import {
  AdvertisementForm,
  advertisementValidationSchemaWithCampaignFields,
  getAdvertisementFields,
  getAdvertisementFinalDataFields,
  getFormEditorFields,
  submitTextValidationSchema,
  useAdvertisementHandlers,
} from './AdvertisementForm';
import AdvertisementPreviewStep from './AdvertisementPreviewStep';
import { FormEditorForm } from './FormEditorForm';
import SubmitTextForm from './SubmitTextForm';
import { Wizard, WizardPage } from './Wizard';

export function NewAdvertisementDialog(): JSX.Element {
  const apolloClient = useApolloClient();
  const { t } = useTranslation();
  const {
    advertisementGroupId,
    advertisementId: advertisementIdFromParams,
    campaignId,
  } = useParams<{
    campaignId: string;
    advertisementGroupId: string;
    advertisementId?: string;
  }>();
  const [advertisementId, setAdvertisementId] = useState(advertisementIdFromParams);
  const {
    handleAdvertisementFinalDataSubmit,
    handleAdvertisementSubmit,
    handleFormSchemaSubmit,
  } = useAdvertisementHandlers({
    advertisementGroupId,
    advertisementId,
    campaignId,
    onAdvertisementCreated: (newAdvertisement) => {
      setAdvertisementId(newAdvertisement.id);
    },
  });

  const { data, error } = useCampaignFullWithRelatedQuery({
    variables: { id: campaignId },
  });
  if (error) {
    throw error;
  }

  const campaign = data?.campaign;
  let advertisementGroup: AdvertisementGroupFragment | null = null;
  let advertisement: AdvertisementFragment | null = null;

  if (campaign) {
    advertisementGroup = apolloClient.readFragment<AdvertisementGroupFragment>({
      id: `AdvertisementGroup:${advertisementGroupId}`,
      fragment: AdvertisementGroupFragment,
    });
  }

  if (campaign && advertisementId) {
    advertisement = apolloClient.readFragment<AdvertisementFragment>({
      id: `Advertisement:${advertisementId}`,
      fragment: AdvertisementFragment,
    });
  }

  let title: string | null = null;
  if (advertisement) {
    title = `Edit Advertisement "${advertisement.name}"`;
  } else if (advertisementGroup) {
    title = `New Advertisement in Group "${advertisementGroup.name}"`;
  }

  const dataLoaded = advertisementId ? !!advertisement : !!campaign;

  return (
    // TODO move each page to a separate component/function. Right now the code has to
    // be synchronized with `NewCampaignDialog`.
    <Wizard title={title}>
      <WizardPage
        title={t('Advertisement')}
        component={AdvertisementForm}
        initialValues={
          dataLoaded
            ? getAdvertisementFields(advertisement, advertisementGroupId, campaign)
            : null
        }
        onSubmit={handleAdvertisementSubmit}
        validationSchema={advertisementValidationSchemaWithCampaignFields(
          campaign?.campaignTypeId
        )}
        primaryHelpTopic="advertisement"
        campaign={campaign}
        campaignType={campaign?.campaignTypeId}
      />
      <WizardPage
        title={t('Form')}
        component={FormEditorForm}
        initialValues={dataLoaded ? getFormEditorFields(campaign, advertisement) : null}
        onSubmit={handleFormSchemaSubmit}
        validationSchema={yup.object()}
        primaryHelpTopic="form"
        campaign={campaign}
        advertisement={advertisement}
      />
      <WizardPage
        title={t('Thank You Text')}
        component={SubmitTextForm}
        initialValues={
          dataLoaded ? getAdvertisementFinalDataFields(advertisement) : null
        }
        validationSchema={submitTextValidationSchema}
        primaryHelpTopic="submit_text"
        onSubmit={handleAdvertisementFinalDataSubmit}
        campaignType={campaign?.campaignTypeId}
        campaign={campaign}
        advertisement={advertisement}
      />
      <WizardPage
        title={t('Preview')}
        initialValues={{}}
        validationSchema={yup.object()}
        component={AdvertisementPreviewStep}
        campaign={campaign}
        advertisement={advertisement}
        primaryHelpTopic="advertisement_preview"
        onSubmit={() => null}
      />
    </Wizard>
  );
}
