import { useApolloClient } from '@apollo/client';
import { omit, pick } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import { Scalars } from '../__generated__/graphql-types';
import {
  CAMPAIGN_CREATED_EVENT,
  globalEventEmitter,
} from '../utils/globalEventEmitter';
import { AdvertisementFragment } from './__generated__/AdvertisementFragment';
import { AdvertisementGroupFragment } from './__generated__/AdvertisementGroupFragment';
import {
  CampaignDialogQuery,
  useCampaignDialogQuery,
} from './__generated__/CampaignDialogQuery';
import { CampaignFragment } from './__generated__/CampaignFragment';
import { useCreateCampaignMutation } from './__generated__/CreateCampaignMutation';
import { useUpdateCampaignIntegrationMutation } from './__generated__/UpdateCampaignIntegrationMutation';
import { useUpdateCampaignMutation } from './__generated__/UpdateCampaignMutation';
import {
  AdvertisementForm,
  advertisementValidationSchemaWithCampaignFields,
  getAdvertisementFields,
  getAdvertisementFinalDataFields,
  getFormEditorFields,
  submitTextValidationSchema,
  useAdvertisementHandlers,
} from './AdvertisementForm';
import {
  AdvertisementGroupForm,
  advertisementGroupValidationSchema,
  useGetAdvertisementGroupFields,
  useHandleAdvertisementGroupSubmit,
} from './AdvertisementGroupForm';
import AdvertisementPreviewStep from './AdvertisementPreviewStep';
import { CampaignTypeForm } from './CampaignTypeForm';
import { FormEditorForm } from './FormEditorForm';
import IntegrationForm from './IntegrationForm';
import SubmitTextForm from './SubmitTextForm';
import { Wizard, WizardPage } from './Wizard';

const CAMPAIGN_VALIDATION_SCHEMA = yup.object({
  name: yup.string(),
  campaignTypeId: yup.string().required(),
  companyId: yup.number().required(),
  sendAdopsEmail: yup.bool().required(),
});

const campaignDefaultValues = {
  name: 'Campaign',
  campaignTypeId: '',
  companyId: '',
  websiteIds: [],
  sendAdopsEmail: false,
};

function EditCampaignDialog(): JSX.Element {
  const params = useParams<{ id: string }>();

  const { data, error } = useCampaignDialogQuery({ variables: { id: params.id } });
  if (error) {
    throw error;
  }

  const [updateCampaign] = useUpdateCampaignMutation();
  const handleIntegrationSubmit = useHandleIntegrationSubmit(params.id);

  const campaign = graphqlCampaignToForm(data?.campaign);
  return (
    <Wizard title={`Edit Campaign "${campaign && campaign.name}"`}>
      <WizardPage
        title="Campaign"
        component={CampaignTypeForm}
        initialValues={campaign}
        validationSchema={CAMPAIGN_VALIDATION_SCHEMA}
        onSubmit={(data) =>
          // TODO: refactor
          updateCampaign({
            variables: {
              id: params.id,
              input: {
                name: data.name,
                websiteIds: data.websiteIds,
                companyId: data.companyId,
                sendAdopsEmail: data.sendAdopsEmail,
              },
            },
          })
        }
        primaryHelpTopic="campaign"
        edit
      />
      <WizardPage
        title="Integrations"
        component={IntegrationForm}
        initialValues={getIntegrationFields(campaign)}
        validationSchema={yup.object()}
        onSubmit={handleIntegrationSubmit}
        primaryHelpTopic="integrations"
        edit
        campaign={campaign}
      />
    </Wizard>
  );
}

function NewCampaignDialog(): JSX.Element {
  const [campaignId, setCampaignId] = useState<string | null>(null);
  const [advertisementGroupId, setAdvertisementGroupId] = useState<string | null>(null);
  const [advertisementId, setAdvertisementId] = useState<string | null>(null);
  const apolloClient = useApolloClient();
  const { t } = useTranslation();
  const [createCampaign] = useCreateCampaignMutation();
  const [updateCampaign] = useUpdateCampaignMutation();

  const getAdvertisementGroupFields = useGetAdvertisementGroupFields();

  const handleIntegrationSubmit = useHandleIntegrationSubmit(campaignId);
  const handleAdvertisementGroupSubmit = useHandleAdvertisementGroupSubmit(
    campaignId,
    advertisementGroupId,
    (result) => {
      setAdvertisementGroupId(result.id);
    }
  );
  const {
    handleAdvertisementFinalDataSubmit,
    handleAdvertisementSubmit,
    handleFormSchemaSubmit,
  } = useAdvertisementHandlers({
    advertisementGroupId,
    advertisementId,
    campaignId,
    onAdvertisementCreated: (newAdvertisement) => {
      setAdvertisementId(newAdvertisement.id);
    },
  });

  let campaign: CampaignFragment | null = null;
  let advertisementGroup: AdvertisementGroupFragment | null = null;
  let advertisement: AdvertisementFragment | null = null;

  const handleCampaignTypeSubmit = async (data, actions) => {
    if (campaignId) {
      // we already have a campaign, just update the existing one
      const result = await updateCampaign({
        variables: {
          id: campaignId,
          input: {
            name: data.name,
            websiteIds: data.websiteIds,
            companyId: data.companyId,
            sendAdopsEmail: data.sendAdopsEmail,
          },
        },
      });
      // TODO: validate result
      const updatedCampaign = result.data?.updateCampaign.campaign;
      actions.setValues(getCampaignTypeFields(updatedCampaign));
    } else {
      // lets create a new campaign
      const result = await createCampaign({
        variables: { input: data },
      });
      // TODO: validate result
      const createdCampaign = result.data?.createCampaign.campaign;
      if (createdCampaign) {
        setCampaignId(createdCampaign?.id);
        // Warning: `actions.setValues` has problems here, causing validation errors
        // for "Campaign Type" and "Company" if the "Save" icon was clicked.
        actions.resetForm({ values: getCampaignTypeFields(createdCampaign) });
        globalEventEmitter.emit(CAMPAIGN_CREATED_EVENT, createdCampaign);
      }
    }
  };

  if (campaignId) {
    campaign = apolloClient.readFragment<CampaignFragment>({
      id: `Campaign:${campaignId}`,
      fragment: CampaignFragment,
    });
  }

  if (campaign && advertisementGroupId) {
    advertisementGroup = apolloClient.readFragment<AdvertisementGroupFragment>({
      id: `AdvertisementGroup:${advertisementGroupId}`,
      fragment: AdvertisementGroupFragment,
    });
  }

  if (advertisementGroup && advertisementId) {
    advertisement = apolloClient.readFragment<AdvertisementFragment>({
      id: `Advertisement:${advertisementId}`,
      fragment: AdvertisementFragment,
    });
  }

  return (
    // TODO move each page to a separate component/function. Right not the code has to
    // be synchronized with other dialogs.
    <Wizard title={t('Create new Campaign')}>
      <WizardPage
        title={t('Campaign')}
        component={CampaignTypeForm}
        initialValues={getCampaignTypeFields(campaign)}
        validationSchema={CAMPAIGN_VALIDATION_SCHEMA}
        onSubmit={handleCampaignTypeSubmit}
        edit={campaignId !== null}
        needValidPage
        primaryHelpTopic="campaign"
      />
      <WizardPage
        title={t('Targeting')}
        component={AdvertisementGroupForm}
        initialValues={getAdvertisementGroupFields(advertisementGroup, campaignId)}
        validationSchema={advertisementGroupValidationSchema}
        onSubmit={handleAdvertisementGroupSubmit}
        primaryHelpTopic="advertisement_group"
      />
      <WizardPage
        title={t('Advertisement')}
        component={AdvertisementForm}
        initialValues={getAdvertisementFields(
          advertisement,
          advertisementGroupId,
          campaign
        )}
        campaign={campaign}
        validationSchema={advertisementValidationSchemaWithCampaignFields(
          campaign?.campaignTypeId
        )}
        onSubmit={handleAdvertisementSubmit}
        primaryHelpTopic="advertisement"
        campaignType={campaign?.campaignTypeId}
      />
      <WizardPage
        title={t('Form')}
        component={FormEditorForm}
        initialValues={getFormEditorFields(campaign, advertisement)}
        onSubmit={handleFormSchemaSubmit}
        validationSchema={yup.object()}
        primaryHelpTopic="form"
        campaign={campaign}
        advertisement={advertisement}
      />
      <WizardPage
        title={t('Thank You Text')}
        component={SubmitTextForm}
        initialValues={getAdvertisementFinalDataFields(advertisement)}
        validationSchema={submitTextValidationSchema}
        primaryHelpTopic="submit_text"
        onSubmit={handleAdvertisementFinalDataSubmit}
        campaignType={campaign?.campaignTypeId}
        campaign={campaign}
        advertisement={advertisement}
      />
      <WizardPage
        title={t('Preview')}
        initialValues={{}}
        validationSchema={yup.object()}
        component={AdvertisementPreviewStep}
        primaryHelpTopic="advertisement_preview"
        onSubmit={() => null}
        campaign={campaign}
        advertisement={advertisement}
      />
      <WizardPage
        title={t('Integrations')}
        component={IntegrationForm}
        initialValues={getIntegrationFields(campaign)}
        campaign={campaign}
        validationSchema={yup.object()}
        onSubmit={handleIntegrationSubmit}
        primaryHelpTopic="integrations"
      />
    </Wizard>
  );
}

function getCampaignTypeFields(campaign: CampaignFragment | null | undefined) {
  if (!campaign) {
    return campaignDefaultValues;
  }

  return pick(campaign, [
    'campaignTypeId',
    'companyId',
    'name',
    'websiteIds',
    'sendAdopsEmail',
  ]);
}

function getIntegrationFields(campaign: CampaignFragment | null) {
  if (!campaign) {
    return null;
  }

  return pick(campaign, ['integrationId', 'integrationMapping', 'integrationMeta']);
}

function graphqlCampaignToForm(campaign?: CampaignDialogQuery['campaign']) {
  if (!campaign) {
    return null;
  }
  return omit(campaign, ['__typename']);
}

function useHandleIntegrationSubmit(campaignId: Scalars['UUID']) {
  const [updateCampaignIntegration] = useUpdateCampaignIntegrationMutation();

  const handleIntegrationSubmit = async (data, actions) => {
    const result = await updateCampaignIntegration({
      variables: {
        id: campaignId,
        input: {
          ...data,
          // convert empty string to null
          integrationId: data.integrationId || null,
        },
      },
    });
    // TODO: validate result
    const updatedCampaign = result.data?.updateCampaignIntegration.campaign;
    if (updatedCampaign) {
      actions.setValues(getIntegrationFields(updatedCampaign));
    }
  };

  return handleIntegrationSubmit;
}
export { EditCampaignDialog, NewCampaignDialog };
