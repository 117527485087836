import React, { useMemo } from 'react';

import { CurrentUserContext } from '../utils/CurrentUserContext';
import { FormFillersContext } from '../utils/FormFillersContext';
import isGraphqlAuthenticationError from '../utils/isGraphqlAuthenticationError';
import { useBaseDataQuery } from './__generated__/BaseDataQuery';

type Props = {
  children: React.ReactNode;
};

export default function BaseDataLoader({ children }: Props): JSX.Element {
  const { data, error } = useBaseDataQuery();
  if (error && !isGraphqlAuthenticationError(error.networkError)) {
    throw error;
  }
  const user = (data && data.me) || null;
  // TODO: should we support loading/error states here?

  const formFillers = useMemo(() => {
    if (!data) {
      return null;
    }
    return {
      companies: data.companies,
      countries: data.countries,
      keywords: data.keywords,
      regions: data.regions,
      websites: data.websites,
    };
  }, [data]);

  return (
    <CurrentUserContext.Provider value={user}>
      <FormFillersContext.Provider value={formFillers}>
        {children}
      </FormFillersContext.Provider>
    </CurrentUserContext.Provider>
  );
}
