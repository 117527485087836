import { ApolloClient, useApolloClient } from '@apollo/client';
import { Paper, Table, TableBody, TablePagination } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';
import {
  addDays,
  formatISO as formatISODate,
  isAfter,
  parseISO as parseISODate,
} from 'date-fns';
import update from 'immutability-helper';
import { flowRight as compose } from 'lodash';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import React, { useEffect } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom';

import { CampaignOrder, FetchLeadReportInput } from '@/__generated__/graphql-types';
import { CAMPAIGN_CREATED_EVENT, globalEventEmitter } from '@/utils/globalEventEmitter';
import { Campaign } from '@/utils/types';
import useFetchLeadReport from '@/utils/useFetchLeadReport';
import useRunWithErrorNotifications from '@/utils/useRunWithErrorNotifications';

import { CampaignsQuery, useCampaignsQuery } from './__generated__/CampaignsQuery';
import { CampaignWithAdGroupsFragment } from './__generated__/CampaignWithAdGroupsFragment';
import { useDeleteAdvertisementGroupMutation } from './campaigns_table/__generated__/DeleteAdvertisementGroupMutation';
import { useDeleteCampaignMutation } from './campaigns_table/__generated__/DeleteCampaignMutation';
import { useDisableAdvertisementGroupMutation } from './campaigns_table/__generated__/DisableAdvertisementGroupMutation';
import { useDisableCampaignMutation } from './campaigns_table/__generated__/DisableCampaignMutation';
import { useEnableAdvertisementGroupMutation } from './campaigns_table/__generated__/EnableAdvertisementGroupMutation';
import { useEnableCampaignMutation } from './campaigns_table/__generated__/EnableCampaignMutation';
import AdvertisementGroupCollection from './campaigns_table/AdvertisementGroupCollection';
import CampaignRow from './campaigns_table/CampaignRow';
import EnhancedTableHead, {
  ORDERABLE_ANALYTICS_COLUMNS,
} from './campaigns_table/EnhancedTableHead';
import EnhancedTableToolbar from './campaigns_table/EnhancedTableToolbar';
import LoadingIndicator from './LoadingIndicator';

const ALLOWED_ORDER_BY_COLUMNS = new Set([
  'active',
  'name',
  ...ORDERABLE_ANALYTICS_COLUMNS,
]);

const START_DATE_FIELD = 'start-date';
const END_DATE_FIELD = 'end-date';
const EXPANDED_FIELD = 'expanded';
const FILTER_STRING_FIELD = 'filter-string';
const ORDER_BY_FIELD = 'order-by';

const styles = (theme: Theme) =>
  ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    expandedTableRow: {
      borderLeftWidth: '5px',
      borderLeftStyle: 'solid',
      borderLeftColor: theme.palette.primary.main,
    },
  } as const);

type WithIdVariables = {
  variables: {
    id: string;
  };
};

type Props = {
  analysisEndDate: Date;
  analysisStartDate: Date;
  apolloClient: ApolloClient<unknown>;
  campaigns: CampaignsQuery['campaigns'];
  deleteAdvertisementGroup: (campaignId: string, advertisementGroupId) => void;
  deleteCampaign: (campaignId: string) => void;
  disableAdvertisementGroup: (props: WithIdVariables) => Promise<void>;
  disableCampaign: (props: WithIdVariables) => Promise<void>;
  enableAdvertisementGroup: (props: WithIdVariables) => Promise<void>;
  enableCampaign: (props: WithIdVariables) => Promise<void>;
  expanded: string | null;
  orderBy: CampaignOrder;
  page: number;
  perPage: number;
  filterString: string;
  fetchLeadReport: (input: FetchLeadReportInput) => Promise<unknown>;
} & WithSnackbarProps &
  WithTranslation &
  RouteComponentProps<{
    campaignId?: string;
  }>;

class CampaignsTable extends React.Component<Props> {
  state = {
    selectedCampaigns: new Set<string>(),
    selectedAdvertisementGroups: new Set<string>(),
  };

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.orderBy.direction !== this.props.orderBy.direction ||
      prevProps.orderBy.field !== this.props.orderBy.field ||
      prevProps.page !== this.props.page ||
      prevProps.perPage !== this.props.perPage
    ) {
      this.setState({
        selectedAdvertisementGroups: new Set(),
        selectedCampaigns: new Set(),
      });
    } else if (prevProps.expanded !== this.props.expanded) {
      this.setState({ selectedAdvertisementGroups: new Set() });
    }
  }

  getNumSelected = () => {
    return (
      this.state.selectedCampaigns.size + this.state.selectedAdvertisementGroups.size
    );
  };

  getPossibleSelectionNum = () => {
    let count = this.props.campaigns?.nodes?.length || 0;

    if (this.props.expanded) {
      count += this.getAdGroupsOfExpanded().length;
    }
    return count;
  };

  getExpandedCampaignId = () => {
    return this.props.expanded;
  };

  getAdGroupsOfExpanded = () => {
    const { apolloClient, expanded } = this.props;

    if (!expanded) {
      return [];
    }

    const campaignWithAdGroups =
      apolloClient.readFragment<CampaignWithAdGroupsFragment>({
        id: `Campaign:${expanded}`,
        fragment: CampaignWithAdGroupsFragment,
      });
    return campaignWithAdGroups?.advertisementGroups || [];
  };

  clearSelection = () => {
    this.setState({
      selectedAdvertisementGroups: new Set<string>(),
      selectedCampaigns: new Set<string>(),
    });
  };

  handleSelectStartDate = async (value: Date) => {
    const { t, enqueueSnackbar } = this.props;

    if (isAfter(value, this.props.analysisEndDate)) {
      enqueueSnackbar(t('Start date cannot be after end date.'), {
        variant: 'warning',
      });
      return;
    }

    replaceDateQueryParam(this.props.history, START_DATE_FIELD, value);
  };

  handleSelectEndDate = async (value: Date) => {
    const { t, enqueueSnackbar } = this.props;

    if (!isAfter(value, this.props.analysisStartDate)) {
      enqueueSnackbar(t('End date cannot be before start date.'), {
        variant: 'warning',
      });
      return;
    }

    replaceDateQueryParam(this.props.history, END_DATE_FIELD, value);
  };

  handleChangeFilterString = async (filterString: string) => {
    replaceQueryParam(this.props.history, FILTER_STRING_FIELD, filterString);
  };

  handleSelect = (
    type: 'advertisementGroup' | 'campaign',
    id: string,
    active: boolean
  ) => {
    if (type === 'campaign') {
      this.setState((prevState) =>
        update(prevState, {
          selectedCampaigns: active ? { $add: [id] } : { $remove: [id] },
        })
      );
    } else if (type === 'advertisementGroup') {
      this.setState((prevState) =>
        update(prevState, {
          selectedAdvertisementGroups: active ? { $add: [id] } : { $remove: [id] },
        })
      );
    }
  };

  handleDeactivateSelection = () => {
    this.bulkSetActive(false);
  };

  handleActivateSelection = () => {
    this.bulkSetActive(true);
  };

  handleExpandCampaign = (campaign: Campaign) => {
    if (this.props.expanded === campaign.id) {
      deleteQueryParam(this.props.history, EXPANDED_FIELD);
    } else {
      replaceQueryParam(this.props.history, EXPANDED_FIELD, campaign.id);
    }
  };

  handleExportSelection = () => {
    this.props.fetchLeadReport({
      campaignIds: Array.from(this.state.selectedCampaigns.values()),
      advertisementGroupIds: Array.from(
        this.state.selectedAdvertisementGroups.values()
      ),
    });
  };

  handleSelectAllClick = () => {
    if (this.getNumSelected() === 0) {
      const { campaigns } = this.props;
      const adGroupsOfExpanded = this.getAdGroupsOfExpanded();

      const selectedCampaigns = new Set(
        campaigns?.nodes.map((campaign) => campaign.id)
      );
      const selectedAdvertisementGroups = new Set(
        adGroupsOfExpanded.map((adGroup) => adGroup.id)
      );

      this.setState({
        selectedCampaigns,
        selectedAdvertisementGroups,
      });
    } else {
      // either all or a part is selected, lets clear
      this.clearSelection();
    }
  };

  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    replaceQueryParam(this.props.history, 'per-page', event.target.value);
  };

  handleSort = (_event: unknown, column: string) => {
    const { orderBy } = this.props;

    replaceQueryParam(
      this.props.history,
      ORDER_BY_FIELD,
      orderByToQueryParam({
        direction: orderBy.direction === 'ASC' ? 'DESC' : 'ASC',
        field: column,
      } as CampaignOrder)
    );
  };

  handleChangePage = (_: unknown, page: number) => {
    deleteQueryParam(this.props.history, EXPANDED_FIELD);
    replaceQueryParam(this.props.history, 'page', page + 1);
  };

  createAdvertisementGroupDeleteHandler = (
    campaignId: string,
    advertisementGroupId: string
  ) => {
    return () => {
      this.props.deleteAdvertisementGroup(campaignId, advertisementGroupId);

      // remove advertisement group from selection if there is one
      this.setState((prevState) =>
        update(prevState, {
          selectedAdvertisementGroups: { $remove: [advertisementGroupId] },
        })
      );
    };
  };

  createCampaignDeleteHandler(campaignId: string) {
    return () => {
      this.props.deleteCampaign(campaignId);

      this.setState((prevState) =>
        update(prevState, { selectedCampaigns: { $remove: [campaignId] } })
      );

      if (this.props.expanded === campaignId) {
        deleteQueryParam(this.props.history, EXPANDED_FIELD);
      }
    };
  }

  async bulkSetActive(active: boolean) {
    const {
      disableAdvertisementGroup,
      disableCampaign,
      enableAdvertisementGroup,
      enableCampaign,
      enqueueSnackbar,
      t,
    } = this.props;
    const promises: Promise<void>[] = [];

    for (const selectedAdvertisementGroup of this.state.selectedAdvertisementGroups) {
      const params = { variables: { id: selectedAdvertisementGroup } };
      const proc = active ? enableAdvertisementGroup : disableAdvertisementGroup;
      promises.push(proc(params));
    }

    for (const selectedCampaign of this.state.selectedCampaigns) {
      const params = { variables: { id: selectedCampaign } };
      const proc = active ? enableCampaign : disableCampaign;
      promises.push(proc(params));
    }

    try {
      await Promise.all(promises);
    } catch (error) {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
      });
      throw error;
    }

    active &&
      enqueueSnackbar(t('Successfully enabled the selected items'), {
        variant: 'success',
      });

    !active &&
      enqueueSnackbar(t('Successfully disabled the selected items'), {
        variant: 'success',
      });
  }

  render = () => {
    const { analysisEndDate, analysisStartDate, t, campaigns, orderBy, filterString } =
      this.props;
    const { selectedCampaigns, selectedAdvertisementGroups } = this.state;
    const numSelected = this.getNumSelected();
    const expandedCampaignId = this.props.expanded;

    // the table is seperate so the search field in the header does not rerender with
    // the query results
    let table = <LoadingIndicator />;

    if (campaigns) {
      table = (
        <>
          <Table>
            <EnhancedTableHead
              numSelected={numSelected}
              rowCount={this.getPossibleSelectionNum()}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleSort}
              order={
                // TODO: replace
                orderBy.direction.toLowerCase()
              }
              orderBy={
                // TODO: replace
                orderBy.field.toLowerCase()
              }
            />

            <TableBody>
              {campaigns.nodes.map((campaign) => {
                // if there is a match then id will be in the url
                const expanded = expandedCampaignId === campaign.id;
                return (
                  <React.Fragment key={`campaign${campaign.id}`}>
                    <CampaignRow
                      onSelect={this.handleSelect}
                      selected={selectedCampaigns.has(campaign.id)}
                      expanded={expanded}
                      campaign={campaign}
                      confirmOnHandleAction={this.createCampaignDeleteHandler(
                        campaign.id
                      )}
                      confirmTitle={`${t('Delete Campaign')} "${campaign.name}"`}
                      confirmContent={`${t('Are you sure you want to delete')} "${
                        campaign.name
                      }" . ${t('All lead data will be purged as well.')}`}
                      onExpand={this.handleExpandCampaign}
                    />
                    {expanded && (
                      <AdvertisementGroupCollection
                        analysisEndDate={analysisEndDate}
                        analysisStartDate={analysisStartDate}
                        selectedAdvertisementGroups={selectedAdvertisementGroups}
                        campaignId={campaign.id}
                        createDeleteAdvertisementGroupHandler={
                          this.createAdvertisementGroupDeleteHandler
                        }
                        onSelect={this.handleSelect}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={campaigns.total}
            rowsPerPage={campaigns.perPage}
            page={this.props.page - 1}
            backIconButtonProps={{
              'aria-label': t('Previous Page'),
            }}
            nextIconButtonProps={{
              'aria-label': t('Next Page'),
            }}
            onChangePage={this.handleChangePage}
            rowsPerPageOptions={[10, 20, 40]}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </>
      );
    }

    return (
      <Paper>
        <EnhancedTableToolbar
          numSelected={numSelected}
          onActiveSelection={this.handleActivateSelection}
          onDeactivateSelection={this.handleDeactivateSelection}
          onExportSelection={this.handleExportSelection}
          onSelectStartDate={this.handleSelectStartDate}
          onSelectEndDate={this.handleSelectEndDate}
          onChangeFilterString={this.handleChangeFilterString}
          startDate={analysisStartDate}
          endDate={analysisEndDate}
          filterString={filterString}
        />
        {table}
      </Paper>
    );
  };
}

const CampaignsTableComposed = compose(
  withSnackbar,
  withTranslation(),
  withRouter,
  withStyles(styles)
)(CampaignsTable);

export default function CampaignsTableWithSubscriptions(): JSX.Element {
  const apolloClient = useApolloClient();
  const location = useLocation();
  const deleteAdvertisementGroup = useDeleteAdvertisementGroupMutationWithCache();
  const deleteCampaign = useDeleteCampaignMutationWithCache();
  const [disableCampaign] = useDisableCampaignMutation();
  const [enableCampaign] = useEnableCampaignMutation();
  const [disableAdvertisementGroup] = useDisableAdvertisementGroupMutation();
  const [enableAdvertisementGroup] = useEnableAdvertisementGroupMutation();
  const fetchLeadReport = useFetchLeadReport();

  const queryParams = new URLSearchParams(location.search);
  const page = intFromQueryParams(queryParams, 'page', 1);
  const perPage = intFromQueryParams(queryParams, 'per-page', 20);
  const orderBy = getOrderBy(queryParams);
  const analysisStartDate = dateFromQueryParams(queryParams, START_DATE_FIELD, () =>
    addDays(new Date(), -30)
  );
  const analysisEndDate = dateFromQueryParams(
    queryParams,
    END_DATE_FIELD,
    () => new Date()
  );
  const filterString = queryParams.get(FILTER_STRING_FIELD);
  const expanded = queryParams.get(EXPANDED_FIELD);

  const analysisStartDateStr = formatDateOnly(analysisStartDate);
  const analysisEndDateStr = formatDateOnly(analysisEndDate);

  const { data, error, refetch } = useCampaignsQuery({
    variables: {
      orderBy,
      page,
      perPage,
      filters: {
        analysisStartDate: analysisStartDateStr,
        analysisEndDate: analysisEndDateStr,
        filterString: filterString,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const unbind = globalEventEmitter.on(CAMPAIGN_CREATED_EVENT, () => {
      refetch();
    });

    return () => {
      unbind();
    };
  }, [refetch]);

  if (error) {
    throw error;
  }

  return (
    <CampaignsTableComposed
      analysisEndDate={analysisEndDate}
      analysisStartDate={analysisStartDate}
      apolloClient={apolloClient}
      campaigns={data?.campaigns}
      deleteAdvertisementGroup={deleteAdvertisementGroup}
      deleteCampaign={deleteCampaign}
      disableAdvertisementGroup={disableAdvertisementGroup}
      disableCampaign={disableCampaign}
      enableAdvertisementGroup={enableAdvertisementGroup}
      enableCampaign={enableCampaign}
      expanded={expanded}
      orderBy={orderBy}
      page={page}
      perPage={perPage}
      fetchLeadReport={fetchLeadReport}
      filterString={filterString}
    />
  );
}

function getOrderBy(params: URLSearchParams): CampaignOrder {
  let orderStr = params.get(ORDER_BY_FIELD);
  if (!orderStr) {
    return {
      direction: 'DESC',
      field: 'NAME',
    } as CampaignOrder;
  }

  let direction = 'ASC';
  if (orderStr[0] === '-') {
    direction = 'DESC';
    orderStr = orderStr.substr(1);
  }

  if (!ALLOWED_ORDER_BY_COLUMNS.has(orderStr)) {
    orderStr = 'name';
  }

  return { direction, field: orderStr.toUpperCase() } as CampaignOrder;
}

function orderByToQueryParam(order: CampaignOrder) {
  return `${order.direction === 'DESC' ? '-' : ''}${order.field.toLowerCase()}`;
}

function dateFromQueryParams(
  params: URLSearchParams,
  key: string,
  alternative: () => Date
): Date {
  const dateStr = params.get(key);
  if (!dateStr) {
    return alternative();
  }

  try {
    return parseISODate(dateStr);
  } catch (error) {
    return alternative();
  }
}

function intFromQueryParams(
  params: URLSearchParams,
  key: string,
  alternative: number
): number {
  return parseInt(params.get(key) || '', 10) || alternative;
}

function deleteQueryParam(history: RouteComponentProps['history'], param: string) {
  const queryParams = new URLSearchParams(history.location.search);
  queryParams.delete(param);
  history.replace({ search: queryParams.toString() });
}

function replaceQueryParam(
  history: RouteComponentProps['history'],
  param: string,
  value: number | string | null | undefined
) {
  const queryParams = new URLSearchParams(history.location.search);
  if (value) {
    queryParams.set(param, value.toString());
  } else {
    queryParams.delete(param);
  }
  history.replace({ search: queryParams.toString() });
}

function replaceDateQueryParam(
  history: RouteComponentProps['history'],
  param: string,
  value: Date
) {
  return replaceQueryParam(history, param, formatDateOnly(value));
}

function formatDateOnly(date: Date): string {
  return formatISODate(date, { representation: 'date' });
}

function useDeleteAdvertisementGroupMutationWithCache() {
  const [deleteAdvertisementGroup] = useDeleteAdvertisementGroupMutation();
  const runWithErrorNotifications = useRunWithErrorNotifications();

  return (campaignId: string, advertisementGroupId: string) => {
    return runWithErrorNotifications(() =>
      deleteAdvertisementGroup({
        variables: { id: advertisementGroupId },
        update(cache, { data }) {
          if (!data?.deleteAdvertisementGroup?.deletedId) {
            return;
          }

          cache.modify({
            id: `Campaign:${campaignId}`,
            fields: {
              advertisementGroups(existingAdGroupsRefs, { readField }) {
                return existingAdGroupsRefs.filter(
                  (adGroupRef) =>
                    data.deleteAdvertisementGroup.deletedId !==
                    readField('id', adGroupRef)
                );
              },
            },
          });

          cache.evict({
            id: `AdvertisementGroup:${data.deleteAdvertisementGroup.deletedId}`,
          });
          cache.gc();
        },
      })
    );
  };
}

function useDeleteCampaignMutationWithCache() {
  const [deleteCampaign] = useDeleteCampaignMutation();
  const runWithErrorNotifications = useRunWithErrorNotifications();

  return (campaignId: string) => {
    return runWithErrorNotifications(() =>
      deleteCampaign({
        variables: { id: campaignId },
        update(cache, { data }) {
          if (!data?.deleteCampaign?.deletedId) {
            return;
          }

          cache.evict({
            id: `Campaign:${campaignId}`,
          });
          cache.gc();
        },
      })
    );
  };
}
