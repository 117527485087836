// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@5.2.6_webpack@5.41.1/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@5.2.6_webpack@5.41.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3swuRFtZm6O5TXlFCku0V9 div div{display:flex}._3swuRFtZm6O5TXlFCku0V9 div div:nth-child(2n){padding:0.5em}\n", "",{"version":3,"sources":["webpack://./../ad-widget/src/backend-campaign.scss"],"names":[],"mappings":"AAAA,iCACE,YAAa,CADf,+CAGI,aAAc","sourcesContent":[".chooseCompany div div {\n  display: flex;\n  &:nth-child(2n) {\n    padding: 0.5em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chooseCompany": "_3swuRFtZm6O5TXlFCku0V9"
};
export default ___CSS_LOADER_EXPORT___;
