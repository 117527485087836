import React from 'react';
import { useTranslation } from 'react-i18next';

import useRunWithErrorNotifications from '@/utils/useRunWithErrorNotifications';

import { AdvertisementGroupRowFragment } from './__generated__/AdvertisementGroupRowFragment';
import { AdvertisementRowFragment } from './__generated__/AdvertisementRowFragment';
import { useCloneAdvertisementMutation } from './__generated__/CloneAdvertisementMutation';
import { useDeleteAdvertisementMutation } from './__generated__/DeleteAdvertisementMutation';
import AdvertisementRow from './AdvertisementRow';

type Props = {
  advertisementGroup: AdvertisementGroupRowFragment;
  campaignId: string;
  canBeActive: boolean;
};

export default function AdvertisementCollection(props: Props): JSX.Element {
  const { advertisementGroup, campaignId, canBeActive } = props;

  const { t } = useTranslation();
  const cloneAdvertisement = useCloneAdvertisementWithCache(advertisementGroup.id);
  const deleteAdvertisement = useDeleteAdvertisementWithCache(advertisementGroup.id);

  return (
    <>
      {advertisementGroup.advertisements.map((advertisement) => {
        const adCanBeActive = canBeActive && advertisement.valid;
        return (
          <AdvertisementRow
            key={advertisement.id}
            confirmOnHandleAction={() => deleteAdvertisement(advertisement.id)}
            confirmTitle={`${t('Delete Advertisement')} ${advertisement.name}"`}
            confirmContent={`${t('Are you sure you want to delete')} ${
              advertisement.name
            }" ${t('. All lead data will be purged as well.')}`}
            campaignId={campaignId}
            advertisementGroupId={advertisementGroup.id}
            canBeActive={adCanBeActive}
            advertisement={advertisement}
            cloneAdvertisement={cloneAdvertisement}
          />
        );
      })}
    </>
  );
}

function useCloneAdvertisementWithCache(advertisementGroupId: string) {
  const [cloneAdvertisement] = useCloneAdvertisementMutation();
  const runWithErrorNotifications = useRunWithErrorNotifications();

  return (advertisementId: string) => {
    return runWithErrorNotifications(() =>
      cloneAdvertisement({
        variables: {
          id: advertisementId,
        },
        update(cache, { data }) {
          if (!data?.cloneAdvertisement?.advertisement) {
            return;
          }

          cache.modify({
            id: `AdvertisementGroup:${advertisementGroupId}`,
            fields: {
              advertisements(existingAdRefs = []) {
                const newAdRef = cache.writeFragment({
                  fragment: AdvertisementRowFragment,
                  fragmentName: 'AdvertisementRowFragment',
                  data: data.cloneAdvertisement.advertisement,
                });
                return [...existingAdRefs, newAdRef];
              },
            },
          });
        },
      })
    );
  };
}

function useDeleteAdvertisementWithCache(advertisementGroupId: string) {
  const [deleteAdvertisement] = useDeleteAdvertisementMutation();
  const runWithErrorNotifications = useRunWithErrorNotifications();

  return (advertisementId: string) => {
    return runWithErrorNotifications(() =>
      deleteAdvertisement({
        variables: {
          id: advertisementId,
        },
        update(cache, { data }) {
          if (!data?.deleteAdvertisement?.deletedId) {
            return;
          }

          cache.modify({
            id: `AdvertisementGroup:${advertisementGroupId}`,
            fields: {
              advertisements(existingAdRefs, { readField }) {
                return existingAdRefs.filter(
                  (adRef) =>
                    data.deleteAdvertisement.deletedId !== readField('id', adRef)
                );
              },
            },
          });

          cache.evict({
            id: `Advertisement:${data.deleteAdvertisement.deletedId}`,
          });
          cache.gc();
        },
      })
    );
  };
}
