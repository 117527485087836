import { AppBar, Paper, Snackbar, Toolbar } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';
import { Route, useLocation } from 'react-router-dom';

// @ts-expect-error Webpack import
import logoSvg from '../assets/logo.svg';
import { NewAdvertisementDialog } from '../components/AdvertisementDialog';
import {
  EditAdvertisementGroupDialog,
  NewAdvertisementGroupDialog,
} from '../components/AdvertisementGroupDialog';
import { EditCampaignDialog, NewCampaignDialog } from '../components/CampaignDialog';
import CampaignsTable from '../components/CampaignsTable';
import UserMenu from '../components/UserMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  logo: {
    height: '32px',
  },
  leadsTable: {
    margin: theme.spacing(3),
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

export default function Dashboard(): JSX.Element {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();

  return (
    <>
      <AppBar
        position="static"
        style={{ backgroundColor: theme.palette.background.paper }}
      >
        <Toolbar>
          <img alt="Logo" className={classes.logo} src={logoSvg} />
          <UserMenu />
        </Toolbar>
      </AppBar>
      <Paper className={classes.leadsTable}>
        <Route
          path="/dashboard/campaigns"
          children={() => <CampaignsTable />}
          location={
            // Inspired by https://reactrouter.com/web/example/modal-gallery
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (location.state as any)?.background || location
          }
        />
      </Paper>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        autoHideDuration={6000}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">Campaign Created</span>}
      />
      <Route path="/dashboard/campaigns/:id/edit" component={EditCampaignDialog} />
      <Route path="/dashboard/campaigns/new-campaign" component={NewCampaignDialog} />

      <Route
        path="/dashboard/campaigns/:campaignId/:id/edit"
        component={EditAdvertisementGroupDialog}
      />
      <Route
        path="/dashboard/campaigns/:campaignId/new-advertisement-group"
        component={NewAdvertisementGroupDialog}
      />
      <Route
        path="/dashboard/campaigns/:campaignId/:advertisementGroupId/new-advertisement"
        component={NewAdvertisementDialog}
      />
      <Route
        path="/dashboard/campaigns/:campaignId/:advertisementGroupId/:advertisementId/edit"
        component={NewAdvertisementDialog}
      />
    </>
  );
}
