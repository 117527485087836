import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { Edit, Warning } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

/**
 * Displays validation errors
 * @param {object} props
 */
function ValidationErrorsDialog({
  open,
  handleAccept,
  handleReject,
  validationErrors,
  dialogText,
  t,
}) {
  let validationErrorsArray;

  // convert a possibly given object to an array
  if (!Array.isArray(validationErrors)) {
    validationErrorsArray = Object.entries(validationErrors).map(
      ([field, message]) => ({
        field,
        message,
      })
    );
  } else {
    validationErrorsArray = validationErrors;
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {validationErrors.length === 0
          ? t('You are all set')
          : t('You have validation errors')}
      </DialogTitle>
      <DialogContent>
        {validationErrors.length === 0 ? (
          <DialogContentText>There are no validation problems left.</DialogContentText>
        ) : (
          <React.Fragment>
            <DialogContentText id="alert-dialog-description">
              {dialogText}
            </DialogContentText>
            <List>
              {validationErrorsArray.map(({ field, message, fixFunction }) => (
                <ListItem key={field} dense button>
                  <ListItemIcon>
                    <Warning />
                  </ListItemIcon>
                  <ListItemText>{message}</ListItemText>
                  {fixFunction && (
                    <ListItemSecondaryAction>
                      <IconButton onClick={fixFunction}>
                        <Edit />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
            </List>
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions>
        {handleReject && (
          <Button onClick={handleReject} color="primary">
            Let me fix this
          </Button>
        )}
        <Button onClick={handleAccept} color="primary" autoFocus>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ValidationErrorsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleAccept: PropTypes.func.isRequired,
  handleReject: PropTypes.func,
  validationErrors: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string.isRequired,
        field: PropTypes.string.isRequired,
        fixFunction: PropTypes.func,
      })
    ),
    PropTypes.object,
  ]).isRequired,
  dialogText: PropTypes.string,
};

ValidationErrorsDialog.defaultProps = {
  dialogText:
    'The following fields are not correctly filled, this means that the object you are editing will be saved as DRAFT. DRAFTS will not be published.',
  handleReject: null,
};

export default withTranslation()(ValidationErrorsDialog);
