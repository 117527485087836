import { Field } from 'formik';
import React from 'react';

import FormInput from '../forms/FormInput';
import InputContainer from '../InputContainer';

type Props = {
  onShowHelp: (topic: string) => void;
};

const WebhookConfigurator: React.FunctionComponent<Props> = ({ onShowHelp }: Props) => {
  return (
    <InputContainer helpTopic="integrations.webhook_url" onShowHelp={onShowHelp}>
      <Field
        component={FormInput}
        label="Webhook URL"
        name="integrationMeta.url"
        style={{ width: '92%' }}
      />
    </InputContainer>
  );
};

export default WebhookConfigurator;
