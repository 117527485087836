import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { PropTypes } from 'prop-types';
import React from 'react';

class RowMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  static propTypes = {
    menuItems: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        icon: PropTypes.element.isRequired,
      })
    ).isRequired,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
    event.stopPropagation();
  };

  handleClose = (event) => {
    this.setState({ anchorEl: null });
    event.stopPropagation();
  };

  handleMenuClick = (handler) => (event) => {
    this.handleClose(event);
    handler();
  };

  render = () => {
    const { anchorEl } = this.state;
    const { menuItems } = this.props;
    const open = Boolean(anchorEl);
    return (
      <React.Fragment>
        <IconButton
          aria-label="More"
          aria-owns={open ? 'the-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <MoreVert />
        </IconButton>
        <Menu id="the-menu" anchorEl={anchorEl} open={open} onClose={this.handleClose}>
          {menuItems.map((menuItem) => (
            <MenuItem
              key={menuItem.label}
              onClick={this.handleMenuClick(menuItem.onClick)}
            >
              <ListItemIcon>{menuItem.icon}</ListItemIcon>
              <ListItemText primary={menuItem.label} />
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    );
  };
}

export default RowMenu;
