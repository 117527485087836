import {
  Chip,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import hasParentWithClass from '../../utils/hasParentWithClass';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const SELECT_ALL_ID = '__SELECT_ALL_ID';
const DESELECT_ALL_ID = '__DESELECT_ALL_ID';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.25),
  },
});

class FormMultiSelect extends React.Component {
  handleDelete = (id) => {
    const values = this.props.field.value.slice();
    const indexToRemove = values.findIndex((item) => item === id);
    values.splice(indexToRemove, 1);
    this.props.form.setFieldValue(this.props.field.name, values);
  };

  handleChange = (event) => {
    const values = event.target.value;
    if (values.indexOf(SELECT_ALL_ID) !== -1) {
      // remove the select all value
      values.splice(values.indexOf(SELECT_ALL_ID), 1);
      this.props.form.setFieldValue(
        this.props.field.name,
        this.getOptions().map((obj) => obj.id)
      );
    } else if (values.indexOf(DESELECT_ALL_ID) !== -1) {
      // remove the select all value
      values.splice(values.indexOf(DESELECT_ALL_ID), 1);
      this.props.form.setFieldValue(this.props.field.name, []);
    } else {
      this.props.form.setFieldValue(this.props.field.name, values);
    }
  };

  getOptions = () => {
    let mappedOptions = this.props.options;

    if (this.props.optionsFilter != null) {
      mappedOptions = mappedOptions.filter((element) =>
        this.props.optionsFilter(this.props.form, element)
      );
    }

    return mappedOptions;
  };

  render = () => {
    const { classes, controlProps, disabled, field, form, label, options } = this.props;
    const showError = form.errors[field.name] && form.touched[field.name];
    const inputId = `${field.name}_input_id`;
    const mappedOptions = this.getOptions();

    return (
      <FormControl error={showError} {...controlProps}>
        <InputLabel htmlFor={inputId}>{label}</InputLabel>
        <Select
          {...field}
          disabled={disabled}
          onChange={this.handleChange}
          multiple
          input={<Input id={inputId} />}
          renderValue={(selected) => {
            const selectedSet = new Set(selected);

            return (
              <div className={classes.chips}>
                {options.map((option) => {
                  if (!selectedSet.has(option.id)) {
                    return null;
                  }
                  return (
                    <Chip
                      key={option.id}
                      label={option.label}
                      onDelete={
                        disabled
                          ? null
                          : () => {
                              this.handleDelete(option.id);
                            }
                      }
                      onMouseDown={(event) => {
                        // A hack to make the delete button working - stop propagation
                        // of the `mouseDown` event if clicked on that button, otherwise
                        // it would be prevented by the `Select` component.
                        if (
                          hasParentWithClass(
                            event.target,
                            'MuiChip-deleteIcon',
                            'MuiChip-root'
                          )
                        ) {
                          event.stopPropagation();
                        }
                      }}
                      className={classes.chip}
                    />
                  );
                })}
              </div>
            );
          }}
          MenuProps={MenuProps}
        >
          {field.value.length === mappedOptions.length ? (
            <MenuItem value={DESELECT_ALL_ID}>Deselect All</MenuItem>
          ) : (
            <MenuItem value={SELECT_ALL_ID}>Select All</MenuItem>
          )}
          {mappedOptions.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
}

FormMultiSelect.propTypes = {
  options: PropTypes.instanceOf(Map).isRequired,
};

export default withStyles(styles, { withTheme: true })(FormMultiSelect);
