import { formatISO as formatISODate, isAfter, parseISO } from 'date-fns';
import React from 'react';

import { useAdvertisementGroupCollectionQuery } from './__generated__/AdvertisementGroupCollectionQuery';
import AdvertisementCollection from './AdvertisementCollection';
import AdvertisementGroupRow from './AdvertisementGroupRow';
import LoadingCellRow from './LoadingCellRow';

type OnSelectCallback = (
  kind: 'advertisementGroup' | 'campaign',
  id: string,
  checked: boolean
) => void;

type Props = {
  analysisEndDate: Date;
  analysisStartDate: Date;
  campaignId: string;
  createDeleteAdvertisementGroupHandler: (
    campaignId: string,
    advertisementGroupId: string
  ) => void;
  onSelect: OnSelectCallback;
  selectedAdvertisementGroups: Set<string>;
};

export default function AdvertisementGroupCollection(props: Props): JSX.Element {
  const {
    analysisEndDate,
    analysisStartDate,
    campaignId,
    createDeleteAdvertisementGroupHandler,
    onSelect,
    selectedAdvertisementGroups,
  } = props;

  const { data, error, loading } = useAdvertisementGroupCollectionQuery({
    variables: {
      id: campaignId,
      analysisEndDate:
        analysisEndDate && formatISODate(analysisEndDate, { representation: 'date' }),
      analysisStartDate:
        analysisStartDate &&
        formatISODate(analysisStartDate, { representation: 'date' }),
    },
    fetchPolicy: 'cache-and-network',
  });

  if (error) {
    throw error;
  }

  if (loading && !data?.campaign) {
    return <LoadingCellRow />;
  }

  if (!data?.campaign) {
    throw new Error('Cannot load data');
  }

  const { campaign } = data;
  const campaignActive = campaign.active && campaign.valid;

  return (
    <>
      {campaign.advertisementGroups.map((advertisementGroup) => {
        const canBeActive =
          campaign.active &&
          campaign.valid &&
          advertisementGroup.valid &&
          advertisementGroup.endTimestamp &&
          !isAfter(new Date(), parseISO(advertisementGroup.endTimestamp));

        return (
          <React.Fragment key={`ad_group${advertisementGroup.id}`}>
            <AdvertisementGroupRow
              advertisementGroup={advertisementGroup}
              selected={selectedAdvertisementGroups.has(advertisementGroup.id)}
              onSelect={onSelect}
              campaignId={campaign.id}
              campaignActive={campaignActive}
              canBeActive={canBeActive}
              confirmOnHandleAction={createDeleteAdvertisementGroupHandler(
                campaign.id,
                advertisementGroup.id
              )}
              confirmTitle={`Delete Advertisement Group "${advertisementGroup.name}"`}
              confirmContent={`Are you sure you want to delete "${advertisementGroup.name}" . All lead data will be purged as well.`}
            />
            <AdvertisementCollection
              campaignId={campaign.id}
              advertisementGroup={advertisementGroup}
              canBeActive={canBeActive}
            />
          </React.Fragment>
        );
      })}
    </>
  );
}
