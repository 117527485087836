import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import React from 'react';
import Lorem from 'react-lorem-component';

import { ArticleState } from '@bruceleads/ad-renderer/src/LeadItNews';

import { AdvertisementPreview } from './AdvertisementPreview';
import FormInput from './forms/FormInput';
import FromFileDropZone from './forms/FromFileDropZone';
import InputContainer from './InputContainer';
import SmartphonePreview from './SmartphonePreview';

const styles = { formWidth: { flex: 1 } };

const SubmitTextForm = ({ onShowHelp, classes, campaign, advertisement }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={8}>
        <Grid container direction="column" spacing={1}>
          <InputContainer helpTopic="submit_text.final_title" onShowHelp={onShowHelp}>
            <Field
              component={FormInput}
              label="Title"
              name="finalTitle"
              className={classes.formWidth}
            />
          </InputContainer>
          <InputContainer helpTopic="submit_text.image" onShowHelp={onShowHelp}>
            <Field
              component={FromFileDropZone}
              className={classes.formWidth}
              name="finalImage"
              label="Final Image"
              caption={'Final Image'}
            />
          </InputContainer>
          <InputContainer helpTopic="submit_text.final_text" onShowHelp={onShowHelp}>
            <Field
              component={FormInput}
              label="Final Text"
              name="finalText"
              className={classes.formWidth}
              multiline
              rows="4"
            />
          </InputContainer>
        </Grid>
      </Grid>
      <Grid
        item
        xs={4}
        style={{
          position: 'relative',
        }}
      >
        <Field>
          {({ form }) => {
            return (
              <SmartphonePreview>
                <Lorem count="1" />
                {/* todo: use enum here */}
                <AdvertisementPreview
                  advertisementObject={{
                    ...campaign,
                    ...advertisement,
                    ...form.values,
                  }}
                  articleState={ArticleState.SUBMIT_SCREEN}
                />
                <Lorem />
              </SmartphonePreview>
            );
          }}
        </Field>
      </Grid>
    </Grid>
  );
};

// SubmitTextForm.propTypes = {
//   data: PropTypes.shape({
//     title: PropTypes.string,
//     teaser: PropTypes.string,
//     final_image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
//   }).isRequired,
//   onValidationChange: PropTypes.func,
//   onChange: PropTypes.func,
//   onValidationChange: PropTypes.func,
//   onHelp: PropTypes.func,
//   showValidation: PropTypes.bool.isRequired,
// };

export default withStyles(styles)(SubmitTextForm);
