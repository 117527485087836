import {
  Avatar,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { getIn } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

class FormSelect extends React.Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.node.isRequired,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
  };

  handleChange = (event) => {
    const { value } = event.target;
    this.props.onChange && this.props.onChange(value, this.props.form);
    this.props.form.setFieldValue(this.props.field.name, value);
  };

  renderOption = (option, index) => {
    return (
      <MenuItem key={index} value={option.value}>
        {option.icon ? (
          <React.Fragment>
            <ListItemIcon>
              <Avatar alt={option.label} src={option.icon} />
            </ListItemIcon>
            <ListItemText inset primary={option.label} />
          </React.Fragment>
        ) : (
          option.label
        )}
      </MenuItem>
    );
  };

  renderValueWithIcon = (value) => {
    const valueObj = this.props.options.find((opt) => opt.value === value);
    if (valueObj.icon) {
      return (
        <React.Fragment>
          <Avatar alt={value.label} src={valueObj.icon} />
          <ListItemText inset primary={valueObj.label} />
        </React.Fragment>
      );
    }
    return value.label;
  };

  render() {
    const {
      controlProps,
      field,
      form,
      id,
      label,
      options,
      className,
      // Make value uncontrolled to get rid of React warnings. If this value is always
      // controlled it doesn't render properly.
      // TODO: check why
      value: _value,
      ...rest
    } = this.props;
    const error = getIn(form.errors, field.name);
    const showError = error && getIn(form.touched, field.name);
    let renderValue;

    if (options.length > 0 && options[0].icon) {
      renderValue = this.renderValueWithIcon;
    }

    return (
      <FormControl className={className} {...controlProps} error={showError}>
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <Select
          {...field}
          {...rest}
          inputProps={{
            id,
            name: field.name,
          }}
          disabled={form.isSubmitting || rest.disabled}
          onChange={this.handleChange}
          renderValue={renderValue}
        >
          {options.map(this.renderOption)}
        </Select>
        <FormHelperText>{showError && error}</FormHelperText>
      </FormControl>
    );
  }
}

export default FormSelect;
