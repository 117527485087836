import { Button, CircularProgress } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import React from 'react';

import { ClassesFromStyleFunction } from '@/utils/ClassesFromStyleFunction';

import { IntegrationFragment } from './__generated__/IntegrationFragment';

const progressButtonStyle = (theme) =>
  ({
    container: {
      margin: '1em 0',
      padding: theme.spacing(4),
    },
    wrapper: {
      position: 'relative',
    },
    button: {
      width: '100%',
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  } as const);

type Props = {
  campaignId: string;
  classes: ClassesFromStyleFunction<typeof progressButtonStyle>;
  integrationMeta: Record<string, unknown> | null | undefined;
  selectedIntegration: IntegrationFragment;
};

type State = Readonly<{
  loading: boolean;
  testResult: string | null;
}>;

/**
 * The TestButton calls the /test url of the integration and displays the result to help the user to
 * check if the integration works.
 */
class TestButton extends React.Component<Props, State> {
  readonly state: State = {
    loading: false,
    testResult: null,
  };

  handleTestIt = async () => {
    const { selectedIntegration, integrationMeta, campaignId } = this.props;

    this.setState({ loading: true });

    try {
      const response = await axios.post(
        `/integrations/${selectedIntegration.name}/${selectedIntegration.id}/test`,
        {
          integration_meta: integrationMeta,
          campaign_id: campaignId,
        }
      );
      if (response.data.result) {
        this.setState({
          testResult: response.data.result,
          loading: false,
        });
      } else {
        this.setState({ testResult: 'The test worked fine', loading: false });
      }
    } catch (error) {
      this.setState({ testResult: `${error}`, loading: false });
    }
  };

  render() {
    const { testResult, loading } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <Button
            className={classes.button}
            variant="contained"
            disabled={loading}
            onClick={this.handleTestIt}
          >
            Test It!
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
        <br />
        <div>{testResult}</div>
      </div>
    );
  }
}

export default withStyles(progressButtonStyle)(TestButton);
