import { Switch } from '@material-ui/core';
import { isEqual } from 'lodash';
import { flowRight as compose, noop } from 'lodash';
import { PropTypes } from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import * as yup from 'yup';

import useRunWithErrorNotifications from '../../utils/useRunWithErrorNotifications';
import ValidationErrorsDialog from '../ValidationErrorsDialog';

const SERVER_ERRORS_TO_MESSAGES = {
  HAS_NO_ADVERTISEMENT_GROUPS:
    'The campaign does not contain any active advertisement groups',
  HAS_NO_ADVERTISEMENTS: 'The campaign does not contain any active advertisements',
  CAMPAIGN_INVALID: 'The campaign is invalid',
  ADVERTISEMENT_INVALID: 'The advertisement is invalid',
  ADVERTISEMENT_GROUP_INVALID: 'The advertisement group is invalid',
};

function SwitchWithValidationWarningInner({
  dialogOpen,
  validationErrors,
  handleAccept,
  checked,
  onChange,
  disabled,
  handleSwitchClick,
}) {
  return (
    <React.Fragment>
      <ValidationErrorsDialog
        open={dialogOpen}
        validationErrors={validationErrors}
        handleAccept={handleAccept}
      />
      <span onClick={handleSwitchClick}>
        <Switch
          onClick={noop}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
        />
      </span>
    </React.Fragment>
  );
}

SwitchWithValidationWarningInner.propTypes = {
  disabled: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  handleSwitchClick: PropTypes.func.isRequired,
  validationErrors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      fixFunction: PropTypes.func,
    })
  ).isRequired,
};

class SwitchWithValidationWarning extends React.Component {
  state = { validationErrors: [], dialogOpen: false };

  static propTypes = {
    object: PropTypes.shape({
      id: PropTypes.string.isRequired,
      valid: PropTypes.bool,
      active: PropTypes.bool,
    }).isRequired,
    validationSchema: PropTypes.oneOfType([
      PropTypes.objectOf(yup.object),
      PropTypes.func,
    ]).isRequired,
    fixUrl: PropTypes.string,
    enableMutation: PropTypes.func.isRequired,
    disableMutation: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
  };

  static defaultProps = { fixUrl: null };

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (!isEqual(this.props.object, prevProps.object) && this.state.dialogOpen) {
      this.validate();
    }
  }

  /**
   * Event handler to catch clicks on disabled switches
   */
  handleSwitchClick = async (event) => {
    event.stopPropagation();

    const nextChecked = !this.props.checked;
    const { disableMutation, enableMutation, object, runWithErrorNotifications } =
      this.props;

    if (nextChecked) {
      const response = await runWithErrorNotifications(() =>
        enableMutation({ variables: { id: object.id } })
      );
      if (response?.data?.result?.errors?.length) {
        const validationErrors = response.data.result.errors.map((errorCode) => {
          return {
            field: errorCode,
            message: SERVER_ERRORS_TO_MESSAGES[errorCode] || errorCode,
          };
        });
        this.setState({ validationErrors, dialogOpen: true });
      } else {
        this.setState({ validationErrors: [] });
      }
    } else {
      await runWithErrorNotifications(() =>
        disableMutation({ variables: { id: object.id } })
      );
      this.setState({ validationErrors: [] });
    }
  };

  handleAccept = () => {
    this.setState({ dialogOpen: false });
  };

  fixFunction = (evt) => {
    const { history, fixUrl } = this.props;
    evt.stopPropagation();
    history.push(fixUrl);
  };

  render() {
    const { checked, disabled } = this.props;
    const { validationErrors, dialogOpen } = this.state;
    return (
      <SwitchWithValidationWarningInner
        dialogOpen={dialogOpen}
        validationErrors={validationErrors}
        handleAccept={this.handleAccept}
        checked={checked}
        disabled={disabled}
        onChange={this.handleChange}
        handleSwitchClick={this.handleSwitchClick}
      />
    );
  }
}

function SwitchWithValidationWarningWithHooks(props) {
  const runWithErrorNotifications = useRunWithErrorNotifications();
  return (
    <SwitchWithValidationWarning
      {...props}
      runWithErrorNotifications={runWithErrorNotifications}
    />
  );
}

const SwitchWithValidationWarningComposed = compose(
  withTranslation(),
  withRouter
)(SwitchWithValidationWarningWithHooks);

export default SwitchWithValidationWarningComposed;
