import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

// @ts-expect-error no TS for scss files
import campaignPreview from '@bruceleads/ad-renderer/src/backend-campaign.scss';

import { useFormFillers } from '../utils/FormFillersContext';
import FormCheckbox from './forms/FormCheckbox';
import FormInput from './forms/FormInput';
import FormMultiSelect from './forms/FormMultiSelect';
import FormSelect from './forms/FormSelect';
import InputContainer from './InputContainer';

type Props = {
  edit: boolean;
  onShowHelp: (topic: string) => void;
};

export function CampaignTypeForm({ edit, onShowHelp }: Props): React.ReactNode {
  const { t } = useTranslation();
  const formFillers = useFormFillers();
  return (
    <Grid container direction="column" spacing={1} alignContent="stretch">
      <InputContainer helpTopic="campaign.name" onShowHelp={onShowHelp}>
        <Field
          autoFocus
          component={FormInput}
          label={t('Campaign Name')}
          name="name"
          style={{ width: '92%' }}
        />
      </InputContainer>
      <InputContainer helpTopic="campaign.campaign_type" onShowHelp={onShowHelp}>
        <Field
          component={FormSelect}
          controlProps={{ style: { width: '92%' } }}
          disabled={edit}
          id="campaignTypeId"
          label={t('Campaign Type')}
          name="campaignTypeId"
          options={[
            { label: t('Newsletter'), value: 'NEWSLETTER' },

            {
              value: 'TRIAL_SUBSCRIPTION',
              label: t('Trial Subscription'),
            },
            {
              value: 'QUIZ',
              label: t('Quiz'),
            },
            {
              value: 'WHITEPAPER_DOWNLOAD',
              label: t('Whitepaper Download'),
            },
            {
              value: 'CUSTOM_LEAD_AD',
              label: t('Custom Lead Ad'),
            },
          ]}
        />
      </InputContainer>
      <InputContainer helpTopic="campaign.company" onShowHelp={onShowHelp}>
        <Field
          className={campaignPreview.chooseCompany}
          component={FormSelect}
          controlProps={{ style: { width: '92%' } }}
          id="companyId"
          label={t('Company')}
          name="companyId"
          options={formFillers.companies.map((company) => ({
            value: company.id,
            label: company.label,
            // @ts-expect-error add logo to Company schema
            icon: company.logo ? company.logo.url : null,
          }))}
        />
      </InputContainer>
      <InputContainer helpTopic="campaign.websites" onShowHelp={onShowHelp}>
        <Field
          component={FormMultiSelect}
          controlProps={{ style: { width: '92%' } }}
          label="Websites"
          name="websiteIds"
          options={formFillers.websites}
        />
      </InputContainer>
      <InputContainer helpTopic="advertisement.sendAdopsEmail" onShowHelp={onShowHelp}>
        <Field
          autoFocus
          component={FormCheckbox}
          label={t('Send adops email')}
          name="sendAdopsEmail"
        />
      </InputContainer>
    </Grid>
  );
}
