import { Button, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  buttonWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

const SpinningButton = withStyles(styles)(
  ({ classes, spinning, onClick, text, disabled }) => (
    <span className={classes.buttonWrapper}>
      <Button
        disabled={spinning || disabled}
        variant="contained"
        color="primary"
        onClick={onClick}
      >
        {text}
      </Button>
      {spinning && <CircularProgress size={24} className={classes.buttonProgress} />}
    </span>
  )
);

SpinningButton.propTypes = {
  spinning: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

SpinningButton.defaultProps = {
  disabled: false,
};

export default SpinningButton;
