import i18next from 'i18next';
import { memoize } from 'lodash';
import * as yup from 'yup';

const campaignValidationSchemaExtended = memoize(
  function campaignValidationSchemaExtended_(campaignTypeId) {
    const schema = yup.object({
      tosUrl: yup
        .string()
        .url(() => i18next.t('The terms of services must be a valid URL.'))
        .required(() => i18next.t('A terms of service URL needs to be provided.')),
      ppUrl: yup
        .string()
        .url(() => i18next.t('The privacy policy must be a valid URL.'))
        .required(),
    });

    if (campaignTypeId === 'QUIZ') {
      return schema.concat(
        yup.object({
          quizAnswers: yup
            .array()
            .of(yup.string())
            .test(
              'len',
              () => i18next.t('For a quiz at least 2 answers need to be provided.'),
              (val) => val.length >= 2
            )
            .required(() =>
              i18next.t('For a quiz at least 2 answers need to be provided.')
            ),
        })
      );
    }

    if (campaignTypeId === 'WHITEPAPER_DOWNLOAD') {
      return schema.concat(
        yup.object({
          attachment: yup
            .object({ file: yup.mixed(), url: yup.string() })
            .required(() =>
              i18next.t('A whitepaper needs to be provided for a whitepaper campaign.')
            )
            .test(
              'attachment',
              'An attachment is required',
              (value) => (value.url || value.file) != null
            ),
        })
      );
    }

    return schema;
  }
);

export default campaignValidationSchemaExtended;
