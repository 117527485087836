import { useUpdateVisibleDashboardColumnsMutation } from './__generated__/UpdateVisibleDashboardColumnsMutation';
import { AnalyticsColumnsNames } from './AnalyticsColumns';
import { useCurrentUser } from './CurrentUserContext';

type UseVisibleDashboardColumnsResult = {
  isVisible(column: string): boolean;
  setVisibleList(columns: string[]): Promise<void>;
  visibleList: readonly string[];
};

export default function useVisibleDashboardColumns(): UseVisibleDashboardColumnsResult {
  const currentUser = useCurrentUser();
  const [updateVisibleColumns] = useUpdateVisibleDashboardColumnsMutation();

  const visibleDashboardColumns = currentUser
    ? // If the user has defined visible list - use it, otherwise fallback to showing
      // all columns.
      currentUser.visibleDashboardColumns || AnalyticsColumnsNames
    : // If the user data is not loaded - show no optional columns.
      [];

  const visibleSet = new Set(visibleDashboardColumns);

  return {
    isVisible(column: string) {
      return visibleSet.has(column);
    },
    async setVisibleList(columns: string[]) {
      await updateVisibleColumns({
        variables: { columns },
        optimisticResponse: currentUser
          ? () => {
              return {
                updateVisibleDashboardColumns: {
                  __typename: 'UpdateVisibleDashboardColumnsPayload',
                  user: {
                    __typename: 'User',
                    id: currentUser.id,
                    visibleDashboardColumns: columns,
                  },
                },
              };
            }
          : undefined,
      });
    },
    visibleList: visibleDashboardColumns,
  };
}
