export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date (isoformat) */
  Date: any;
  /** Date with time (isoformat) */
  DateTime: any;
  JSON: any;
  UUID: any;
  Upload: any;
};

export enum AdServerContinent {
  Africa = 'Africa',
  Antarctica = 'Antarctica',
  Asia = 'Asia',
  Europe = 'Europe',
  NorthAmerica = 'North_America',
  Oceania = 'Oceania',
  SouthAmerica = 'South_America'
}

export type Advertisement = {
  __typename?: 'Advertisement';
  id: Scalars['UUID'];
  name: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
  valid?: Maybe<Scalars['Boolean']>;
  teaserDisplayMode: AdvertisementTeaserDisplayMode;
  title?: Maybe<Scalars['String']>;
  teaser?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  submitButtonText?: Maybe<Scalars['String']>;
  finalTitle?: Maybe<Scalars['String']>;
  finalText?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['UUID']>;
  finalImageId?: Maybe<Scalars['UUID']>;
  image?: Maybe<Image>;
  finalImage?: Maybe<Image>;
  leadCount: Scalars['Int'];
  analytics?: Maybe<Analytics>;
  previewUrl: Scalars['String'];
};


export type AdvertisementAnalyticsArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type AdvertisementGroup = {
  __typename?: 'AdvertisementGroup';
  id: Scalars['UUID'];
  name: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
  valid?: Maybe<Scalars['Boolean']>;
  startTimestamp?: Maybe<Scalars['DateTime']>;
  endTimestamp?: Maybe<Scalars['DateTime']>;
  regionIds?: Maybe<Array<Scalars['Int']>>;
  advertisements: Array<Advertisement>;
  countryIds: Array<Scalars['Int']>;
  keywordIds: Array<Scalars['Int']>;
};

export enum AdvertisementTeaserDisplayMode {
  ImageWithText = 'IMAGE_WITH_TEXT',
  None = 'NONE',
  ImageOnly = 'IMAGE_ONLY'
}

export type Analytics = {
  __typename?: 'Analytics';
  clicks?: Maybe<Scalars['Float']>;
  impressions?: Maybe<Scalars['Float']>;
  submissions?: Maybe<Scalars['Float']>;
  formShows?: Maybe<Scalars['Float']>;
  formChanges?: Maybe<Scalars['Float']>;
};

export type Attachment = {
  __typename?: 'Attachment';
  url: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type Campaign = {
  __typename?: 'Campaign';
  id: Scalars['UUID'];
  userId: Scalars['UUID'];
  name: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
  valid?: Maybe<Scalars['Boolean']>;
  creationDate: Scalars['DateTime'];
  campaignTypeId: Scalars['String'];
  companyId: Scalars['ID'];
  formSchema: Scalars['JSON'];
  quizAnswers?: Maybe<Array<Scalars['String']>>;
  correctQuizAnswer?: Maybe<Scalars['Int']>;
  integrationId?: Maybe<Scalars['ID']>;
  integrationMeta?: Maybe<Scalars['JSON']>;
  integrationMapping?: Maybe<Scalars['JSON']>;
  tosUrl?: Maybe<Scalars['String']>;
  ppUrl?: Maybe<Scalars['String']>;
  sendAdopsEmail: Scalars['Boolean'];
  attachmentId?: Maybe<Scalars['UUID']>;
  analytics?: Maybe<Analytics>;
  leadCount: Scalars['Int'];
  advertisementGroups: Array<AdvertisementGroup>;
  company: Company;
  attachment?: Maybe<Attachment>;
  websiteIds: Array<Scalars['Int']>;
};

export type CampaignFilters = {
  analysisStartDate?: Maybe<Scalars['Date']>;
  analysisEndDate?: Maybe<Scalars['Date']>;
  filterString?: Maybe<Scalars['String']>;
};

export type CampaignOrder = {
  direction: OrderDirection;
  field: CampaignOrderField;
};

export enum CampaignOrderField {
  Active = 'ACTIVE',
  Clicks = 'CLICKS',
  ConversionRateClicks = 'CONVERSION_RATE_CLICKS',
  ConversionRateImpressions = 'CONVERSION_RATE_IMPRESSIONS',
  Ctr = 'CTR',
  Impressions = 'IMPRESSIONS',
  FormChanges = 'FORM_CHANGES',
  FormShows = 'FORM_SHOWS',
  Name = 'NAME',
  Submissions = 'SUBMISSIONS'
}

export type CampaignsWithInfo = {
  __typename?: 'CampaignsWithInfo';
  nodes: Array<Campaign>;
  pages: Scalars['Int'];
  perPage: Scalars['Int'];
  total: Scalars['Int'];
};

export type CloneAdvertisementPayload = {
  __typename?: 'CloneAdvertisementPayload';
  advertisement?: Maybe<Advertisement>;
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['ID'];
  label: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  integrations: Array<Integration>;
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['Int'];
  name: Scalars['String'];
  continent: AdServerContinent;
  label: Scalars['String'];
};

export type CreateAdvertisementGroupPayload = {
  __typename?: 'CreateAdvertisementGroupPayload';
  advertisementGroup?: Maybe<AdvertisementGroup>;
};

export type CreateAdvertisementPayload = {
  __typename?: 'CreateAdvertisementPayload';
  advertisement?: Maybe<Advertisement>;
  campaign?: Maybe<Campaign>;
};

export type CreateCampaignInput = {
  name: Scalars['String'];
  campaignTypeId: Scalars['String'];
  companyId: Scalars['String'];
  websiteIds: Array<Scalars['Int']>;
  sendAdopsEmail: Scalars['Boolean'];
};

export type CreateCampaignPayload = {
  __typename?: 'CreateCampaignPayload';
  campaign?: Maybe<Campaign>;
};



export type DeleteAdvertisementGroupPayload = {
  __typename?: 'DeleteAdvertisementGroupPayload';
  deletedId?: Maybe<Scalars['UUID']>;
};

export type DeleteAdvertisementPayload = {
  __typename?: 'DeleteAdvertisementPayload';
  deletedId?: Maybe<Scalars['UUID']>;
};

export type DeleteCampaignPayload = {
  __typename?: 'DeleteCampaignPayload';
  deletedId?: Maybe<Scalars['UUID']>;
};

export type DisableAdvertisementGroupPayload = {
  __typename?: 'DisableAdvertisementGroupPayload';
  advertisementGroup: AdvertisementGroup;
};

export type DisableAdvertisementPayload = {
  __typename?: 'DisableAdvertisementPayload';
  advertisement: Advertisement;
};

export type DisableCampaignPayload = {
  __typename?: 'DisableCampaignPayload';
  campaign: Campaign;
};

export type EditAdvertisementGroupInput = {
  name: Scalars['String'];
  startTimestamp?: Maybe<Scalars['DateTime']>;
  endTimestamp?: Maybe<Scalars['DateTime']>;
  countryIds?: Maybe<Array<Scalars['Int']>>;
  regionIds?: Maybe<Array<Scalars['Int']>>;
  keywordIds?: Maybe<Array<Scalars['Int']>>;
};

export type EditAdvertisementInput = {
  name: Scalars['String'];
  teaserDisplayMode?: AdvertisementTeaserDisplayMode;
  title?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  teaser?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  tosUrl?: Maybe<Scalars['String']>;
  ppUrl?: Maybe<Scalars['String']>;
  quizAnswers?: Maybe<Array<Scalars['String']>>;
  correctQuizAnswer?: Maybe<Scalars['Int']>;
  attachment?: Maybe<Scalars['Upload']>;
};

export type EnableAdvertisementGroupPayload = {
  __typename?: 'EnableAdvertisementGroupPayload';
  errors?: Maybe<Array<EnableErrors>>;
  advertisementGroup?: Maybe<AdvertisementGroup>;
};

export type EnableAdvertisementPayload = {
  __typename?: 'EnableAdvertisementPayload';
  errors?: Maybe<Array<EnableErrors>>;
  advertisement?: Maybe<Advertisement>;
};

export type EnableCampaignPayload = {
  __typename?: 'EnableCampaignPayload';
  errors?: Maybe<Array<EnableErrors>>;
  campaign?: Maybe<Campaign>;
};

export enum EnableErrors {
  HasNoAdvertisementGroups = 'HAS_NO_ADVERTISEMENT_GROUPS',
  HasNoAdvertisements = 'HAS_NO_ADVERTISEMENTS',
  CampaignInvalid = 'CAMPAIGN_INVALID',
  AdvertisementInvalid = 'ADVERTISEMENT_INVALID',
  AdvertisementGroupInvalid = 'ADVERTISEMENT_GROUP_INVALID'
}

export type FetchLeadReportInput = {
  campaignIds?: Maybe<Array<Scalars['UUID']>>;
  advertisementGroupIds?: Maybe<Array<Scalars['UUID']>>;
  advertisementIds?: Maybe<Array<Scalars['UUID']>>;
};

export type FetchLeadReportPayload = {
  __typename?: 'FetchLeadReportPayload';
  url: Scalars['String'];
};

export type Image = {
  __typename?: 'Image';
  url: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  originalFilename: Scalars['String'];
  type: ImageType;
  mimeType: Scalars['String'];
};

export enum ImageType {
  Jpeg = 'JPEG',
  Png = 'PNG'
}

export type Integration = {
  __typename?: 'Integration';
  id: Scalars['ID'];
  label: Scalars['String'];
  name: Scalars['String'];
};


export type Keyword = {
  __typename?: 'Keyword';
  id: Scalars['Int'];
  name: Scalars['String'];
  label: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  updateVisibleDashboardColumns: UpdateVisibleDashboardColumnsPayload;
  createCampaign: CreateCampaignPayload;
  updateCampaign: UpdateCampaignPayload;
  updateCampaignIntegration: UpdateCampaignIntegrationPayload;
  enableCampaign: EnableCampaignPayload;
  disableCampaign: DisableCampaignPayload;
  deleteCampaign: DeleteCampaignPayload;
  createAdvertisementGroup: CreateAdvertisementGroupPayload;
  updateAdvertisementGroup: UpdateAdvertisementGroupPayload;
  enableAdvertisementGroup: EnableAdvertisementGroupPayload;
  disableAdvertisementGroup: DisableAdvertisementGroupPayload;
  deleteAdvertisementGroup: DeleteAdvertisementGroupPayload;
  createAdvertisement: CreateAdvertisementPayload;
  updateAdvertisementFinalData: UpdateAdvertisementFinalDataPayload;
  updateAdvertisementForm: UpdateAdvertisementFormPayload;
  updateAdvertisementWithCampaign: UpdateAdvertisementWithCampaignPayload;
  enableAdvertisement: EnableAdvertisementPayload;
  disableAdvertisement: DisableAdvertisementPayload;
  cloneAdvertisement: CloneAdvertisementPayload;
  deleteAdvertisement: DeleteAdvertisementPayload;
  fetchLeadReport: FetchLeadReportPayload;
};


export type MutationUpdateVisibleDashboardColumnsArgs = {
  columns: Array<Scalars['String']>;
};


export type MutationCreateCampaignArgs = {
  input: CreateCampaignInput;
};


export type MutationUpdateCampaignArgs = {
  id: Scalars['UUID'];
  input: UpdateCampaignInput;
};


export type MutationUpdateCampaignIntegrationArgs = {
  id: Scalars['UUID'];
  input: UpdateCampaignIntegrationInput;
};


export type MutationEnableCampaignArgs = {
  id: Scalars['UUID'];
};


export type MutationDisableCampaignArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteCampaignArgs = {
  id: Scalars['UUID'];
};


export type MutationCreateAdvertisementGroupArgs = {
  campaignId: Scalars['UUID'];
  input: EditAdvertisementGroupInput;
};


export type MutationUpdateAdvertisementGroupArgs = {
  id: Scalars['UUID'];
  input: EditAdvertisementGroupInput;
};


export type MutationEnableAdvertisementGroupArgs = {
  id: Scalars['UUID'];
};


export type MutationDisableAdvertisementGroupArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteAdvertisementGroupArgs = {
  id: Scalars['UUID'];
};


export type MutationCreateAdvertisementArgs = {
  advertisementGroupId: Scalars['UUID'];
  input: EditAdvertisementInput;
};


export type MutationUpdateAdvertisementFinalDataArgs = {
  id: Scalars['UUID'];
  input: UpdateAdvertisementFinalDataInput;
};


export type MutationUpdateAdvertisementFormArgs = {
  id: Scalars['UUID'];
  input: UpdateAdvertisementFormInput;
};


export type MutationUpdateAdvertisementWithCampaignArgs = {
  id: Scalars['UUID'];
  input: EditAdvertisementInput;
};


export type MutationEnableAdvertisementArgs = {
  id: Scalars['UUID'];
};


export type MutationDisableAdvertisementArgs = {
  id: Scalars['UUID'];
};


export type MutationCloneAdvertisementArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteAdvertisementArgs = {
  id: Scalars['UUID'];
};


export type MutationFetchLeadReportArgs = {
  input: FetchLeadReportInput;
};

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Query = {
  __typename?: 'Query';
  campaigns: CampaignsWithInfo;
  campaign?: Maybe<Campaign>;
  companies: Array<Company>;
  countries: Array<Country>;
  keywords: Array<Keyword>;
  me: User;
  regions: Array<Region>;
  websites: Array<Website>;
};


export type QueryCampaignsArgs = {
  page?: Scalars['Int'];
  perPage?: Maybe<Scalars['Int']>;
  filters?: Maybe<CampaignFilters>;
  orderBy?: Maybe<CampaignOrder>;
};


export type QueryCampaignArgs = {
  id: Scalars['UUID'];
};

export type Region = {
  __typename?: 'Region';
  id: Scalars['Int'];
  label: Scalars['String'];
};


export type UpdateAdvertisementFinalDataInput = {
  finalTitle?: Maybe<Scalars['String']>;
  finalImage?: Maybe<Scalars['Upload']>;
  finalText?: Maybe<Scalars['String']>;
};

export type UpdateAdvertisementFinalDataPayload = {
  __typename?: 'UpdateAdvertisementFinalDataPayload';
  advertisement?: Maybe<Advertisement>;
};

export type UpdateAdvertisementFormInput = {
  formSchema: Scalars['JSON'];
  submitButtonText?: Maybe<Scalars['String']>;
};

export type UpdateAdvertisementFormPayload = {
  __typename?: 'UpdateAdvertisementFormPayload';
  advertisement?: Maybe<Advertisement>;
  campaign?: Maybe<Campaign>;
};

export type UpdateAdvertisementGroupPayload = {
  __typename?: 'UpdateAdvertisementGroupPayload';
  advertisementGroup?: Maybe<AdvertisementGroup>;
};

export type UpdateAdvertisementWithCampaignPayload = {
  __typename?: 'UpdateAdvertisementWithCampaignPayload';
  advertisement?: Maybe<Advertisement>;
  campaign?: Maybe<Campaign>;
};

export type UpdateCampaignInput = {
  name: Scalars['String'];
  websiteIds: Array<Scalars['Int']>;
  companyId: Scalars['ID'];
  sendAdopsEmail: Scalars['Boolean'];
};

export type UpdateCampaignIntegrationInput = {
  integrationId?: Maybe<Scalars['ID']>;
  integrationMeta?: Maybe<Scalars['JSON']>;
  integrationMapping?: Maybe<Scalars['JSON']>;
};

export type UpdateCampaignIntegrationPayload = {
  __typename?: 'UpdateCampaignIntegrationPayload';
  campaign?: Maybe<Campaign>;
};

export type UpdateCampaignPayload = {
  __typename?: 'UpdateCampaignPayload';
  campaign?: Maybe<Campaign>;
};

export type UpdateVisibleDashboardColumnsPayload = {
  __typename?: 'UpdateVisibleDashboardColumnsPayload';
  user: User;
};


export type User = {
  __typename?: 'User';
  fullName: Scalars['String'];
  id: Scalars['UUID'];
  roles: Array<UserRole>;
  email: Scalars['String'];
  mobile: Scalars['String'];
  seat?: Maybe<Scalars['String']>;
  creationDate: Scalars['DateTime'];
  lastEmailUpdate: Scalars['DateTime'];
  visibleDashboardColumns?: Maybe<Array<Scalars['String']>>;
  canEditCountries: Scalars['Boolean'];
  canEditRegions: Scalars['Boolean'];
};

export enum UserRole {
  RoleCampaigns = 'ROLE_CAMPAIGNS',
  RoleAdGroups = 'ROLE_AD_GROUPS',
  RoleAds = 'ROLE_ADS',
  RoleExportLeads = 'ROLE_EXPORT_LEADS',
  RoleConfigureIntegrations = 'ROLE_CONFIGURE_INTEGRATIONS'
}

export type Website = {
  __typename?: 'Website';
  id: Scalars['Int'];
  label: Scalars['String'];
};
