export default function isGraphqlAuthenticationError(
  error:
    | (Error & {
        statusCode?: number;
      })
    | null
): boolean {
  if (error == null) {
    return false;
  }
  const { statusCode } = error;
  return statusCode === 401;
}
