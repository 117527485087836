import 'react-image-crop/dist/ReactCrop.css';

import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isObject, values } from 'lodash';
import { readAsDataURL } from 'promise-file-reader';
import React, { useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import ReactCrop from 'react-image-crop';

import { checkRatio } from '../../utils/imageValidators';
import stopEventPropagation from '../../utils/stopEventPropagation';

const FALLBACK_DEFAULT_CROP = { unit: '%', width: 100, aspect: 16 / 9 };

const useStyles = makeStyles((theme) => ({
  croppingAreaCaption: {
    paddingBottom: '0.4rem',
  },
  cropButtonContainer: {
    marginTop: theme.spacing(1),
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

export default function FileImageDropZone({
  accept = 'image/jpeg, image/png',
  caption,
  className = null,
  defaultCrop = FALLBACK_DEFAULT_CROP,
  disabled = false,
  field,
  form,
  label,
  style = {},
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [crop, setCrop] = useState(defaultCrop);
  const [isCropping, setIsCropping] = useState(false);
  const [image, setImage] = useState(null);
  const previewRef = useRef();

  const fileUrl = field.value && field.value.url;
  const acceptsImage = accept.indexOf('image') >= 0;

  const handleCrop = async () => {
    setIsCropping(true);

    let imageToCrop = image;
    if (!imageToCrop) {
      // There was no image dropped, but the uploaded one had incorrect ratio
      imageToCrop = new Image();
      imageToCrop.crossOrigin = 'anonymous';
      imageToCrop.src = fileUrl;

      await new Promise((resolve) => {
        imageToCrop.onload = () => {
          setImage(imageToCrop);
          resolve();
        };
        imageToCrop.src = fileUrl;
      });
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const scale = imageToCrop.naturalWidth / previewRef.current.width;

    canvas.width = crop.width * scale;
    canvas.height = crop.height * scale;

    ctx.drawImage(
      imageToCrop,
      crop.x * scale,
      crop.y * scale,
      crop.width * scale,
      crop.height * scale,
      0,
      0,
      crop.width * scale,
      crop.height * scale
    );

    const previousFile = field.value.file;
    const previousFilename = previousFile
      ? previousFile.name
      : field.value.originalFilename;
    const previousType = previousFile ? previousFile.type : field.value.mimeType;

    canvas.toBlob(
      async (blob) => {
        form.setFieldValue(field.name, {
          ...field.value,
          width: crop.width * scale,
          height: crop.height * scale,
          url: await readAsDataURL(blob),
          file: new File([blob], previousFilename, {
            lastModified: new Date().getTime(),
            type: previousType,
          }),
        });

        setIsCropping(false);
      },
      previousType,
      previousType === 'image/jpeg' ? 0.95 : 1
    );
  };

  const handleDrop = async (acceptedFiles) => {
    if (!acceptedFiles.length) {
      return;
    }
    const file = acceptedFiles[0];

    const dataUrl = await readAsDataURL(file);

    const imgPromise = new Promise((resolve) => {
      if (acceptsImage) {
        const image = new Image();
        image.onload = () => {
          setImage(image);
          setCrop(defaultCrop);
          resolve({ width: image.width, height: image.height });
        };
        image.src = dataUrl;
      } else {
        resolve({});
      }
    });

    form.setFieldTouched(field.name, true);
    form.setFieldValue(field.name, {
      ...(await imgPromise),
      url: dataUrl,
      name: file.name,
      file: file,
    });
  };

  let hasCorrectRatio = false;
  if (acceptsImage && field.value?.width && field.value?.height) {
    hasCorrectRatio = checkRatio(
      field.value.width,
      field.value.height,
      defaultCrop.aspect
    );
  }

  const showError = form.errors[field.name] && form.touched[field.name];
  let error = form.errors[field.name];
  if (isObject(error)) {
    [error] = values(error);
  }

  let preview = <Typography variant="caption">{caption}</Typography>;

  if (fileUrl && acceptsImage) {
    preview = (
      <img
        alt={t('Preview of image upload')}
        className={classes.img}
        crossOrigin="anonymous"
        ref={previewRef}
        src={fileUrl}
      />
    );
  }

  if (fileUrl && !acceptsImage) {
    preview = (
      <Typography variant="caption">
        {t(
          'File is saved! Click here or drop another file to replace the current one.'
        )}
        <a
          href={fileUrl}
          download
          onClick={stopEventPropagation}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('Download ') + field.value.name}
        </a>
      </Typography>
    );
  }

  let croppingArea = null;

  if (hasCorrectRatio === false) {
    croppingArea = (
      <>
        <div className={classes.croppingAreaCaption}>
          <Typography variant="caption">
            {t('The image has an incorrect ratio. Use the widget below to crop it.')}
          </Typography>
        </div>
        <ReactCrop
          crop={crop}
          imageStyle={{
            maxWidth: '100%',
            maxHeight: '100%',
          }}
          onChange={(newCrop) => {
            setCrop(newCrop);
          }}
          src={fileUrl}
        />
        <div className={classes.cropButtonContainer}>
          <Button
            color="secondary"
            disabled={isCropping}
            onClick={handleCrop}
            type="button"
            variant="contained"
          >
            {isCropping ? 'Cropping' : 'Crop'}
          </Button>
        </div>
      </>
    );
  }

  return (
    <FormControl style={style} className={className} error={showError}>
      <FormLabel style={{ marginBottom: '0.5em' }}>{label}</FormLabel>
      <Dropzone
        accept={accept}
        disabled={form.isSubmitting || disabled}
        multiple={false}
        name={field.name}
        onBlur={field.onBlur}
        onDropAccepted={handleDrop}
        style={{
          minHeight: '7em',
          backgroundColor: '#999',
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {preview}
          </div>
        )}
      </Dropzone>
      {croppingArea}
      {showError && <FormHelperText>{error} </FormHelperText>}
    </FormControl>
  );
}
