import { useSnackbar } from 'notistack';

type AsyncProc<T> = () => Promise<T>;

export default function useRunWithErrorNotifications<T>(): (
  proc: AsyncProc<T>
) => Promise<T> {
  const { enqueueSnackbar } = useSnackbar();

  return async function runWithErrorNotifications<T>(proc: AsyncProc<T>) {
    try {
      return await proc();
    } catch (error) {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
      });
      throw error;
    }
  };
}
