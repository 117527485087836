import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';
import {
  Add,
  ArrowDownward,
  ArrowUpward,
  Clear,
  Delete,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from '@material-ui/icons';
import produce from 'immer';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import RichTextEditor from 'react-rte';

// update.extend('$unset', function(keysToRemove, original) {
//   var copy = Object.assign({}, original);
//   for (const key of keysToRemove) delete copy[key];
//   return copy;
// });

const styles = (theme) => ({
  field: {
    overflow: 'visible',
  },
  fieldWrapper: {
    marginBottom: theme.spacing(2),
  },
  fieldHeader: {
    backgroundColor: theme.palette.grey['A400'],
  },
  fieldContent: {
    padding: theme.spacing(1, 2),
  },
  fieldActions: {
    padding: theme.spacing(2, 1),
    justifyContent: 'space-between',
  },
  fieldItem: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  remove: {
    marginRight: -theme.spacing(2),
  },
  optionsTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  options: {
    boxShadow: theme.shadows[1],
    padding: theme.spacing(1, 2),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  optionsActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const richFieldStyles = (theme) => ({
  control: {
    marginTop: 16,
  },
  container: {
    marginTop: 24,
    marginBottom: 8,
    color: theme.palette.grey['A400'],
  },
  editorComponent: {
    backgroundColor: theme.palette.grey['A400'],
    border: '0',
  },
  editorInner: {
    color: theme.palette.common.white,
  },
});

const DEFAULT_VALUE = {
  type: 'object',
  properties: {},
  displayOrder: [],
  required: [],
};

const FIELDS = {
  default: {
    title: 'Text',
    schema: { type: 'string', title: '' },
  },
  email: {
    title: 'Email',
    schema: {
      type: 'string',
      title: '',
      format: 'email',
    },
  },
  date: {
    title: 'Date',
    schema: {
      type: 'string',
      title: '',
      format: 'date',
    },
  },
  number: {
    title: 'Number',
    schema: { type: 'number', title: '' },
  },
  url: {
    title: 'URL',
    schema: {
      type: 'string',
      title: '',
      format: 'url',
    },
  },
  description: {
    title: 'Description Text',
    schema: {
      displayAs: 'description',
      description: '',
    },
  },
  radio: {
    title: 'Radio',
    schema: {
      displayAs: 'radio',
      type: 'string',
      enum: ['value A', 'value B', 'value C'],
      descriptions: ['Rich text for A', 'Rich text for B', 'Rich text for C'],
    },
  },
  dropdown: {
    title: 'Dropdown',
    schema: {
      displayAs: 'dropdown',
      type: 'string',
      title: '',
      enum: ['value A', 'value B'],
      titles: ['Text for A', 'Text for B'],
    },
  },
  boolean: {
    title: 'Checkbox',
    schema: { type: 'boolean', description: '' },
  },
  imageUpload: {
    title: 'Image upload',
    schema: {
      displayAs: 'imageUpload',
      type: 'string',
      format: 'binary',
    },
  },
};

const DeleteConfirmDialog = withStyles(styles)(
  withTheme(
    class extends React.Component {
      static displayName = 'DeleteConfirmDialog';

      state = {
        open: false,
      };

      handleClickOpen = () => {
        this.setState({ open: true });
      };

      handleClose = () => {
        this.setState({ open: false });
      };

      handleDelete = () => {
        this.setState({ open: false });

        this.props.onChange();
      };

      render() {
        const { classes, children } = this.props;

        return (
          <div className={classes.remove}>
            <span onClick={this.handleClickOpen}>{children}</span>
            <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Are you sure you want to delete <b>{this.props.fieldName}</b>?
              </DialogTitle>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={this.handleDelete} color="primary" autoFocus>
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      }
    }
  )
);

class EnumField extends React.Component {
  render() {
    const {
      options,
      descriptions,
      titles,
      classes,
      onNameChange,
      onDescriptionChange,
      onTitleChange,
      onAddOption,
      onDeleteOption,
      onChangePosition,
    } = this.props;
    return (
      <React.Fragment>
        <div className={classes.fieldItem}>
          <Typography className={classes.optionsTitle} variant="h6">
            Options
          </Typography>
          {options.map((option, index) => {
            const isUp = index === 0;
            const isDown = index >= options.length - 1;

            let description, title;
            if (descriptions != null) {
              description = descriptions[index];
            }
            if (titles != null) {
              title = titles[index];
            }

            return (
              <div className={classes.options} key={index}>
                <TextField
                  label="Name"
                  value={option}
                  fullWidth
                  margin="normal"
                  onChange={(evt) => onNameChange(index, evt.target.value)}
                />
                {description != null && (
                  <RichField
                    label="Description"
                    value={description}
                    onChange={(val) => onDescriptionChange(index, val)}
                  />
                )}
                {title != null && (
                  <TextField
                    label="Title"
                    value={title}
                    fullWidth
                    margin="normal"
                    onChange={(evt) => onTitleChange(index, evt.target.value)}
                  />
                )}
                <div className={classes.optionsActions}>
                  <IconButton
                    disabled={isUp}
                    onClick={() => onChangePosition(index, index - 1)}
                  >
                    <ArrowUpward />
                  </IconButton>
                  <IconButton
                    disabled={isDown}
                    onClick={() => onChangePosition(index, index + 1)}
                  >
                    <ArrowDownward />
                  </IconButton>
                  <DeleteConfirmDialog
                    fieldName={option}
                    onChange={() => onDeleteOption(index)}
                  >
                    <IconButton>
                      <Clear />
                    </IconButton>
                  </DeleteConfirmDialog>
                </div>
              </div>
            );
          })}
          <div style={{ textAlign: 'center' }}>
            <IconButton onClick={onAddOption}>
              <Add />
            </IconButton>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

class AddFieldMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { fields, onClick, children } = this.props;

    return (
      <div>
        <Button
          color="primary"
          variant="contained"
          aria-owns={anchorEl ? 'fields-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          {children}
        </Button>
        <Menu
          id="fields-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {Object.entries(fields).map(([schemaName, field]) => {
            return (
              <MenuItem
                key={schemaName}
                onClick={() => {
                  this.handleClose();
                  onClick(field);
                }}
              >
                {field.title}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  }
}

class RichField extends React.Component {
  // by default create a empty value of the editor state
  state = { editorState: RichTextEditor.createEmptyValue() };

  toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD' },
      { label: 'Italic', style: 'ITALIC' },
      // { label: 'Underline', style: 'UNDERLINE' }
    ],
  };

  componentDidMount() {
    const { value } = this.props;
    if (value != null) {
      this.setState({
        editorState: RichTextEditor.createValueFromString(value, 'markdown'),
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.changeTo) {
      this.setState({
        editorState: RichTextEditor.createValueFromString(nextProps.value, 'markdown'),
      });
      this.changeTo = null;
    }
  }

  changeTo = null;

  onChange = (editorState) => {
    this.setState({ editorState });
    if (this.props.onChange) {
      this.changeTo = editorState.toString('markdown');
      this.props.onChange(this.changeTo);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <FormControl className={classes.control} fullWidth>
        <InputLabel shrink htmlFor="rich-area">
          {this.props.label}
        </InputLabel>
        <div className={classes.container}>
          <RichTextEditor
            className={classes.editorComponent}
            editorClassName={classes.editorInner}
            value={this.state.editorState}
            toolbarConfig={this.toolbarConfig}
            onChange={this.onChange}
          />
        </div>
      </FormControl>
    );
  }
}

RichField = withStyles(richFieldStyles)(RichField);

class FieldEditor extends React.Component {
  state = { expanded: false };

  handleChangeTitle = (evt) => {
    const newFormObject = produce(this.props.formObject, (draft) => {
      draft.properties[this.props.itemName].title = evt.target.value;
    });
    this.props.onChange(newFormObject);
  };

  handleChangeDescription = (value) => {
    const newFormObject = produce(this.props.formObject, (draft) => {
      draft.properties[this.props.itemName].description = value;
    });
    this.props.onChange(newFormObject);
  };

  handleChangeName = (evt) => {
    const newItemName = evt.target.value;
    const { itemName, formObject, onChange } = this.props;

    if (formObject.properties[newItemName] !== undefined) {
      // prevent duplicate object keys
      return;
    }

    const newFormObject = produce(formObject, (draft) => {
      draft.properties[newItemName] = draft.properties[itemName];
      delete draft.properties[itemName];
      draft.displayOrder.splice(
        formObject.displayOrder.indexOf(itemName),
        1,
        newItemName
      );
      if (formObject.required.indexOf(itemName) !== -1) {
        draft.required.splice(formObject.required.indexOf(itemName), 1, newItemName);
      }
      // ad an _id attribute in case it does not have one, so everything can still be edited continuously
      if (draft.properties[newItemName]._id == null) {
        draft.properties[newItemName]._id = itemName;
      }
    });
    onChange(newFormObject);
  };

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  handleEnumNameChange = (index, value) => {
    const { itemName, formObject, onChange } = this.props;

    const newFormObject = produce(formObject, (draft) => {
      draft.properties[itemName].enum[index] = value;
    });
    onChange(newFormObject);
  };

  handleEnumDescriptionChange = (index, value) => {
    const { itemName, formObject, onChange } = this.props;

    const newFormObject = produce(formObject, (draft) => {
      draft.properties[itemName].descriptions[index] = value;
    });
    onChange(newFormObject);
  };

  handleEnumTitleChange = (index, value) => {
    const { itemName, formObject, onChange } = this.props;

    const newFormObject = produce(formObject, (draft) => {
      draft.properties[itemName].titles[index] = value;
    });
    onChange(newFormObject);
  };

  handleEnumAddOption = () => {
    const { itemName, formObject, onChange } = this.props;

    const newFormObject = produce(formObject, (draft) => {
      draft.properties[itemName].enum.push('');
      if (formObject.properties[itemName].displayAs === 'radio') {
        draft.properties[itemName].descriptions.push('');
      } else if (formObject.properties[itemName].displayAs === 'dropdown') {
        draft.properties[itemName].titles.push('');
      }
    });
    onChange(newFormObject);
  };

  handleEnumDeleteOption = (index) => {
    const { itemName, formObject, onChange } = this.props;

    const newFormObject = produce(formObject, (draft) => {
      draft.properties[itemName].enum.splice(index, 1);

      draft.properties[itemName].descriptions != null &&
        draft.properties[itemName].descriptions.splice(index, 1);
      draft.properties[itemName].titles != null &&
        draft.properties[itemName].titles.splice(index, 1);
    });
    onChange(newFormObject);
  };

  handleEnumChangePosition = (oldPos, newPos) => {
    const { itemName, formObject, onChange } = this.props;

    const newFormObject = produce(formObject, (draft) => {
      const enumVal = draft.properties[itemName].enum[oldPos];
      draft.properties[itemName].enum.splice(oldPos, 1);
      draft.properties[itemName].enum.splice(newPos, 0, enumVal);

      if (draft.properties[itemName].descriptions != null) {
        const description = draft.properties[itemName].descriptions[oldPos];
        draft.properties[itemName].descriptions.splice(oldPos, 1);
        draft.properties[itemName].descriptions.splice(newPos, 0, description);
      }
      if (draft.properties[itemName].titles != null) {
        const name = draft.properties[itemName].titles[oldPos];
        draft.properties[itemName].titles.splice(oldPos, 1);
        draft.properties[itemName].titles.splice(newPos, 0, name);
      }
    });
    onChange(newFormObject);
  };

  handleDelete = () => {
    this.props.onDelete(this.props.itemName);
  };

  handleSetRequired = (evt) => {
    this.props.onSetRequired(this.props.itemName, evt);
  };

  render() {
    const { expanded } = this.state;
    const { itemName, classes, item, required } = this.props;
    const fieldType =
      FIELDS[item.displayAs] ||
      FIELDS[item.type] ||
      FIELDS[item.format] ||
      FIELDS.default;

    const { title } = item;
    return (
      <Card>
        <CardHeader
          className={classes.fieldHeader}
          subheader={fieldType.title}
          title={itemName}
          avatar={<Avatar className={classes.avatar}>{itemName[0]}</Avatar>}
          action={
            <IconButton onClick={this.handleExpandClick}>
              {expanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
          }
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent className={classes.fieldContent}>
            <TextField
              label="Name"
              value={itemName}
              onChange={this.handleChangeName}
              fullWidth
              margin="normal"
            />
            {item.title != null && (
              <TextField
                label="Title"
                value={title}
                onChange={this.handleChangeTitle}
                fullWidth
                margin="normal"
              />
            )}
            {item.enum != null && (
              <EnumField
                options={item.enum}
                descriptions={item.descriptions}
                titles={item.titles}
                classes={classes}
                onNameChange={this.handleEnumNameChange}
                onDescriptionChange={this.handleEnumDescriptionChange}
                onTitleChange={this.handleEnumTitleChange}
                onAddOption={this.handleEnumAddOption}
                onDeleteOption={this.handleEnumDeleteOption}
                onChangePosition={this.handleEnumChangePosition}
              />
            )}
            {item.description != null && (
              <RichField
                value={item.description}
                onChange={this.handleChangeDescription}
              />
            )}
          </CardContent>
        </Collapse>
        <CardActions className={classes.fieldActions}>
          {item.displayAs !== 'description' && (
            <FormControlLabel
              control={
                <Checkbox onChange={this.handleSetRequired} checked={required} />
              }
              label={'required'}
            />
          )}
          <DeleteConfirmDialog fieldName={itemName} onChange={this.handleDelete}>
            <IconButton>
              <Delete />
            </IconButton>
          </DeleteConfirmDialog>
        </CardActions>
      </Card>
    );
  }
}

class FormEditor extends React.Component {
  getFormObject() {
    return this.props.value || DEFAULT_VALUE;
  }

  handleDelete = (fieldName) => {
    const formObject = this.getFormObject();
    const newFormObject = produce(formObject, (draft) => {
      draft.displayOrder.splice(formObject.displayOrder.indexOf(fieldName), 1);

      if (formObject.required.indexOf(fieldName) !== -1) {
        draft.required.splice(formObject.required.indexOf(fieldName), 1);
      }
      delete draft.properties[fieldName];
    });
    this.props.onChange(newFormObject);
  };

  handleAddField = (fieldType) => {
    const formObject = this.getFormObject();

    let fieldName = `${fieldType.title} Field`;

    if (formObject.properties != null) {
      for (
        let i = 0;
        formObject.properties[fieldName] !== undefined ||
        // TODO: duplicate ids will happen
        // TODO: Maybe we should refactor this code instead?
        // eslint-disable-next-line no-loop-func
        Object.values(formObject.properties).find((x) => x._id === fieldName) !==
          undefined;
        i++
      ) {
        fieldName = `${fieldType.title} Field ${i + 1}`;
      }
    }

    const newFormObject = produce(formObject, (draft) => {
      if (formObject.displayOrder == null) {
        draft.displayOrder = [];
      }
      draft.displayOrder.push(fieldName);

      if (formObject.required == null) {
        draft.required = [];
      }

      if (formObject.properties == null) {
        draft.properties = {};
      }

      draft.properties[fieldName] = Object.assign({}, fieldType.schema);
    });
    this.props.onChange(newFormObject);
  };

  handleSetRequired = (fieldName, evt) => {
    const formObject = this.getFormObject();
    const newFormObject = produce(formObject, (draft) => {
      if (formObject.required == null) {
        draft.required = [];
      }
      if (evt.target.checked) {
        draft.required.push(fieldName);
      } else if (formObject.required != null) {
        const index = formObject.required.indexOf(fieldName);
        if (index !== -1) {
          draft.required.splice(index, 1);
        }
      }
    });
    this.props.onChange(newFormObject);
  };

  handleDropEnd = (result) => {
    const { destination, source } = result;
    if (destination == null) {
      // not dragged to anywhere
      return;
    }

    const formObject = this.getFormObject();
    const newFormObject = produce(formObject, (draft) => {
      const fieldName = formObject.displayOrder[source.index];
      draft.displayOrder.splice(source.index, 1);
      draft.displayOrder.splice(destination.index, 0, fieldName);
    });
    this.props.onChange(newFormObject);
  };

  render() {
    const { classes, onChange } = this.props;
    const formObject = this.getFormObject();
    const { displayOrder } = formObject;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DragDropContext onDragEnd={this.handleDropEnd}>
            <Droppable droppableId={'fields'}>
              {(provided) => (
                <div {...provided.draggableProps} ref={provided.innerRef}>
                  {(displayOrder || []).map((itemName, index) => {
                    const item = formObject.properties[itemName];
                    const key = item._id || itemName;
                    return (
                      <Draggable key={key} draggableId={key} index={index}>
                        {(provided) => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className={classes.fieldWrapper}
                          >
                            <FieldEditor
                              formObject={formObject}
                              itemName={itemName}
                              onChange={onChange}
                              classes={classes}
                              item={item}
                              required={
                                formObject.required &&
                                formObject.required.indexOf(itemName) !== -1
                              }
                              onDelete={this.handleDelete}
                              onSetRequired={this.handleSetRequired}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>

        <Grid item xs={12}>
          <AddFieldMenu fields={FIELDS} onClick={this.handleAddField}>
            Add field
          </AddFieldMenu>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withTheme(FormEditor));
