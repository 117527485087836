import { Chip } from '@material-ui/core';
import * as colors from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { isAfter, parseISO } from 'date-fns';
import React from 'react';
import { withTranslation } from 'react-i18next';

const style = {
  activeChip: {
    backgroundColor: colors.green[500],
    color: 'white',
  },
  draftChip: {
    backgroundColor: colors.orange[500],
  },
};

function StatusBadge({ active, valid, expireDate, classes, t }) {
  let label = t('Active');
  let className = classes.activeChip;

  if (!active) {
    label = t('Inactive');
    className = '';
  }

  if (expireDate && isAfter(new Date(), parseISO(expireDate))) {
    label = t('Ended');
    className = '';
  }

  if (!valid) {
    label = t('Draft');
    className = classes.draftChip;
  }

  return <Chip label={label} className={className} />;
}

export default withTranslation()(withStyles(style)(StatusBadge));
