import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';

import App from './App';
import {
  BaseDataQuery,
  BaseDataQueryDocument,
} from './components/__generated__/BaseDataQuery';
import i18n from './i18n';
import getApolloClient from './utils/getApolloClient';

const apolloClient = getApolloClient();

const sentryDsn = getMetaValue('app-sentry-dsn');
if (sentryDsn) {
  const sentryEnvironment = getMetaValue('app-sentry-environment');

  Sentry.init({
    dsn: sentryDsn,
    environment: sentryEnvironment,
    beforeSend(event) {
      if (event.exception) {
        // Try to read data of the currently logged in user from Apollo cache
        let userData: BaseDataQuery | null = null;
        try {
          userData = apolloClient.readQuery<BaseDataQuery>({
            query: BaseDataQueryDocument,
          });
        } catch (error) {
          // empty
        }
        Sentry.showReportDialog({
          eventId: event.event_id,
          user: {
            email: userData?.me?.email,
            name: userData?.me?.fullName,
          },
        });
      }
      return event;
    },
  });
}

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={'An error has occurred'}>
    <ApolloProvider client={apolloClient}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </ApolloProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById('content')
);

function getMetaValue(name: string) {
  const tag = document.querySelector(`meta[name="${name}"]`);
  if (!tag) {
    return undefined;
  }

  return (tag as HTMLMetaElement).content;
}
