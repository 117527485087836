import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';

import getComponentDisplayName from '@/utils/getComponentDisplayName';

const ConfirmDialog = ({ title, content, onConfirm, onClose }) => {
  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Abort
        </Button>
        <Button
          onClick={() => {
            onConfirm();
            onClose();
          }}
          color="primary"
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function withConfirmableAction(WrappedComponent) {
  // ...and returns another component...
  return class extends React.Component {
    static displayName = `withConfirmableAction(${getComponentDisplayName(
      WrappedComponent
    )})`;

    constructor(props) {
      super(props);
      this.state = {
        dialogOpen: false,
      };
    }

    handleOpenDialog = () => {
      this.setState({ dialogOpen: true });
    };

    handleClose = () => {
      this.setState({ dialogOpen: false });
    };

    render = () => {
      const { dialogOpen } = this.state;
      const { confirmTitle, confirmContent, confirmOnHandleAction } = this.props;
      return (
        <React.Fragment>
          <WrappedComponent onExecuteAction={this.handleOpenDialog} {...this.props} />
          {dialogOpen && (
            <ConfirmDialog
              onClose={this.handleClose}
              onConfirm={confirmOnHandleAction}
              title={confirmTitle}
              content={confirmContent}
            />
          )}
        </React.Fragment>
      );
    };
  };
}

export { ConfirmDialog, withConfirmableAction };
