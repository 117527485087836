import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import { flowRight as compose } from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Lorem from 'react-lorem-component';

import { AdvertisementPreview, ArticleState } from './AdvertisementPreview';
import FormEditor from './FormEditor';
import FormInput from './forms/FormInput';
import InputContainer from './InputContainer';
import SmartphonePreview from './SmartphonePreview';

const styles = {
  formWidth: { flex: 1 },
};

class FormEditorForm extends React.Component {
  render = () => {
    const { classes, onShowHelp, t } = this.props;
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Field name="formSchema">
              {({ field, form }) => {
                return (
                  <FormEditor
                    value={field.value}
                    onChange={(val) => form.setFieldValue('formSchema', val)}
                  />
                );
              }}
            </Field>
            <InputContainer
              helpTopic="advertisement.button_text"
              onShowHelp={onShowHelp}
            >
              <Field
                className={classes.formWidth}
                component={FormInput}
                label={t('Submit Button Text')}
                name="submitButtonText"
              />
            </InputContainer>
            {/* <Grid container direction="column" spacing={1} /> */}
          </Grid>
          <Grid item xs={4}>
            <Field>
              {({ form }) => {
                return (
                  <div style={{ position: 'sticky', top: 0 }}>
                    <SmartphonePreview>
                      <Lorem count="1" />
                      <AdvertisementPreview
                        advertisementObject={{
                          ...this.props.campaign,
                          ...this.props.advertisement,
                          ...form.values,
                        }}
                        articleState={ArticleState.FORM}
                      />
                      <Lorem />
                    </SmartphonePreview>
                  </div>
                );
              }}
            </Field>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
}

FormEditorForm = compose(withTranslation(), withStyles(styles))(FormEditorForm);

FormEditorForm.propTypes = {};

export { FormEditorForm };
