import { Checkbox, IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Add, CloudDownload, Delete, Edit } from '@material-ui/icons';
import { flowRight as compose, sumBy } from 'lodash';
import { PropTypes } from 'prop-types';
/* eslint-disable react/forbid-prop-types */
import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import useFetchLeadReport from '@/utils/useFetchLeadReport';
import useHistoryPushInModal from '@/utils/useHistoryPushInModal';

import { advertisementGroupValidationSchema } from '../AdvertisementGroupForm';
import { withConfirmableAction } from '../ConfirmDialog';
import { useDisableAdvertisementGroupMutation } from './__generated__/DisableAdvertisementGroupMutation';
import { useEnableAdvertisementGroupMutation } from './__generated__/EnableAdvertisementGroupMutation';
import AnalyticCells from './AnalyticCells';
import RowMenu from './RowMenu';
import StatusBadge from './StatusBadge';
import SwitchWithValidationWarning from './SwitchWithValidationWarning';
import tableStyle from './tableStyle';

const defaultAnalyticsObject = Object.freeze({
  impressions: 0,
  clicks: 0,
  submissions: 0,
  formChanges: 0,
  formShows: 0,
});

/**
 * Represents a advertisement group row in the campaign table.
 *
 * @param {object} props
 */
function AdvertisementGroupRow({
  advertisementGroup,
  campaignId,
  onExecuteAction,
  selected,
  onSelect,
  classes,
  t,
}) {
  const { name, id, active, valid, endTimestamp, advertisements } = advertisementGroup;

  const historyPushInModal = useHistoryPushInModal();
  const [disableAdvertisementGroup] = useDisableAdvertisementGroupMutation();
  const [enableAdvertisementGroup] = useEnableAdvertisementGroupMutation();
  const fetchLeadReport = useFetchLeadReport();

  const analyticsObject = useMemo(() => {
    if (!advertisements) {
      return null;
    }

    const result = { ...defaultAnalyticsObject };

    for (const advertisement of advertisements) {
      for (const key of Object.keys(defaultAnalyticsObject)) {
        result[key] += advertisement?.analytics?.[key] || 0;
      }
    }

    return result;
  }, [advertisements]);

  const leadCount = useMemo(() => {
    if (!advertisements) {
      return null;
    }

    return sumBy(advertisements, 'leadCount');
  }, [advertisements]);

  return (
    <TableRow hover selected={selected} className={classes.expandedTableRow}>
      <TableCell>
        <Checkbox
          checked={selected}
          onChange={(event, checked) => onSelect('advertisementGroup', id, checked)}
        />
      </TableCell>
      <TableCell>
        <SwitchWithValidationWarning
          checked={active && valid}
          disabled={!valid}
          disableMutation={disableAdvertisementGroup}
          enableMutation={enableAdvertisementGroup}
          object={advertisementGroup}
          validationSchema={advertisementGroupValidationSchema}
          fixUrl={`/dashboard/campaigns/${campaignId}/${id}/edit`}
        />
      </TableCell>
      <TableCell>&nbsp;</TableCell>
      <TableCell style={{ paddingLeft: '4em' }}>{name}</TableCell>
      <TableCell>
        <StatusBadge active={active} valid={valid} expireDate={endTimestamp} />
      </TableCell>
      <TableCell align="right">{leadCount}</TableCell>
      <TableCell>
        <Tooltip title="Send leads report">
          <IconButton onClick={() => fetchLeadReport({ advertisementGroupIds: [id] })}>
            <CloudDownload />
          </IconButton>
        </Tooltip>
      </TableCell>
      <AnalyticCells analyticsObject={analyticsObject} />
      <TableCell style={{ textAlign: 'right' }}>
        <RowMenu
          menuItems={[
            {
              label: t('New Advertisement'),
              icon: <Add />,
              onClick: () =>
                historyPushInModal(
                  `/dashboard/campaigns/${campaignId}/${id}/new-advertisement`
                ),
            },
            {
              label: t('Edit Advertisement Group'),
              icon: <Edit />,
              onClick: () =>
                historyPushInModal(`/dashboard/campaigns/${campaignId}/${id}/edit`),
            },
            {
              label: t('Send report'),
              icon: <CloudDownload />,
              onClick: () => fetchLeadReport({ advertisementGroupIds: [id] }),
            },
            {
              label: t('Delete Advertisement Group'),
              icon: <Delete />,
              onClick: () => onExecuteAction(),
            },
          ]}
        />
      </TableCell>
    </TableRow>
  );
}

AdvertisementGroupRow.propTypes = {
  advertisementGroup: PropTypes.object.isRequired,
  campaignId: PropTypes.string.isRequired,
  onExecuteAction: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default compose(
  withTranslation(),
  withRouter,
  withStyles(tableStyle),
  withConfirmableAction
)(AdvertisementGroupRow);
