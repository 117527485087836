import {
  ApolloClient,
  ApolloLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

import Auth from './Auth';
import history from './history';
import isGraphqlAuthenticationError from './isGraphqlAuthenticationError';

const PAGES_WITH_IGNORED_AUTH_ERRORS = new Set(['/login']);

// Based on examples from https://www.apollographql.com/docs/react/advanced/network-layer/
const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  if (Auth.isAuthenticated()) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${Auth.token()}`,
      },
    });
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return forward!(operation);
});

const logoutLink = onError(({ networkError }) => {
  if (networkError) {
    const isAuthError = isGraphqlAuthenticationError(networkError);
    if (isAuthError && !PAGES_WITH_IGNORED_AUTH_ERRORS.has(window.location.pathname)) {
      Auth.logout();
      history.push('/login');
    }
  }
});

export default function getApolloClient(): ApolloClient<NormalizedCacheObject> {
  const link = from([
    logoutLink,
    authMiddleware,
    createUploadLink({
      uri: '/graphql/',
    }),
  ]);
  const cache = new InMemoryCache();
  return new ApolloClient({
    cache,
    link,
  });
}
