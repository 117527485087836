import { useApolloClient } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';

import { AdvertisementGroupFragment } from './__generated__/AdvertisementGroupFragment';
import { useCampaignDialogQuery } from './__generated__/CampaignDialogQuery';
import { useCampaignFullWithRelatedQuery } from './__generated__/CampaignFullWithRelatedQuery';
import {
  AdvertisementGroupForm,
  advertisementGroupValidationSchema,
  useAdvertisementGroupTemplate,
  useGetAdvertisementGroupFields,
  useHandleAdvertisementGroupSubmit,
} from './AdvertisementGroupForm';
import { Wizard, WizardPage } from './Wizard';

export function EditAdvertisementGroupDialog(): JSX.Element {
  const { campaignId, id: adGroupId } = useParams<{ campaignId: string; id: string }>();
  const apolloClient = useApolloClient();
  const handleAdvertisementGroupSubmit = useHandleAdvertisementGroupSubmit(
    campaignId,
    adGroupId
  );
  const getAdvertisementGroupFields = useGetAdvertisementGroupFields();

  const { data, error } = useCampaignFullWithRelatedQuery({
    variables: { id: campaignId },
  });
  if (error) {
    throw error;
  }

  let adGroup: AdvertisementGroupFragment | null = null;

  if (data?.campaign) {
    adGroup = apolloClient.readFragment<AdvertisementGroupFragment>({
      id: `AdvertisementGroup:${adGroupId}`,
      fragment: AdvertisementGroupFragment,
    });
  }

  return (
    <Wizard title={`Edit Advertisement Group "${adGroup && adGroup.name}"`}>
      <WizardPage
        title="Advertisement Group"
        component={AdvertisementGroupForm}
        initialValues={adGroup && getAdvertisementGroupFields(adGroup, null)}
        validationSchema={advertisementGroupValidationSchema}
        onSubmit={handleAdvertisementGroupSubmit}
        primaryHelpTopic="advertisement_group"
        edit
      />
    </Wizard>
  );
}

export function NewAdvertisementGroupDialog(): JSX.Element {
  const { campaignId } = useParams<{ campaignId: string }>();
  const handleAdvertisementGroupSubmit = useHandleAdvertisementGroupSubmit(campaignId);
  const advertisementGroupTemplate = useAdvertisementGroupTemplate();

  const { data, error } = useCampaignDialogQuery({ variables: { id: campaignId } });
  if (error) {
    throw error;
  }
  const campaign = data?.campaign;

  return (
    <Wizard title={`New Advertisement Group in "${campaign?.name}"`}>
      <WizardPage
        title="Advertisement Group"
        component={AdvertisementGroupForm}
        initialValues={
          // wait until campaign details are loaded
          campaign && advertisementGroupTemplate()
        }
        validationSchema={advertisementGroupValidationSchema}
        onSubmit={handleAdvertisementGroupSubmit}
        primaryHelpTopic="advertisement_group"
        edit
      />
    </Wizard>
  );
}
