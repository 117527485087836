import { Chip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  breadCrumbs: {
    marginLeft: '1ch',
    verticalAlign: 'middle',
  },
};

/**
 * Extracts the title from a string with a dot and capitalizes the first word.
 *
 * Eg.:
 * ```js
 * extractTitleFromTopicId("hello.world")
 * "World"
 *
 * extractTitleFromTopicId("hello")
 * "Hello"
 * ```
 *
 * @param {string} topicId
 */
function extractTitleFromTopicId(topicId) {
  const [, sec] = topicId.split('.');
  return sec
    .split('_')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ');
}

/**
 * HelpBrowser should be a context-sensitive guidance for the user to find through the process.
 * It leads markdown file asynchronously via webpack (`html-loader` and `markdown-loader`).
 *
 * The translation information is taken from `i18next.language`.
 *
 * There is a primary and a secondary help topic. The user can always return from the secondary to
 * the primary topic by clicking 'x'.
 *
 */
class HelpBrowser extends React.Component {
  static propTypes = {
    /* primary help topic */
    primaryHelpTopic: PropTypes.string.isRequired,

    /* secondary help topic, can be null */
    secondaryHelpTopic: PropTypes.string,

    /* it is a controlled element so the state must be hold externally. */
    onCloseSecondaryTopic: PropTypes.func.isRequired,
  };

  static defaultProps = {
    secondaryHelpTopic: null,
  };

  state = {
    helpTopicInLoading: null,
    helpHtml: null,
  };

  componentDidMount() {
    this.loadHelpHtml();
  }

  componentDidUpdate() {
    this.loadHelpHtml();
  }

  async loadHelpHtml() {
    const { primaryHelpTopic, secondaryHelpTopic } = this.props;
    const primaryIsActive = secondaryHelpTopic === null;
    const helpTopic = primaryIsActive ? primaryHelpTopic : secondaryHelpTopic;
    const { language } = i18next;

    const languageCode = language.split('-')[0];

    if (helpTopic === this.state.helpTopicInLoading) {
      return;
    }

    this.setState({ helpTopicInLoading: helpTopic });

    let helpHtml;

    try {
      const helpHtmlModule = await import(
        `../../context_doc/${languageCode}/${helpTopic}.md`
      );
      if (helpHtmlModule.default) {
        helpHtml = helpHtmlModule.default;
      }
    } catch (error) {
      helpHtml = `No help found for topic "${helpTopic}".`;
    }

    if (helpTopic !== this.state.helpTopicInLoading) {
      return;
    }

    this.setState({ helpHtml });
  }

  render() {
    const { secondaryHelpTopic, classes, onCloseSecondaryTopic } = this.props;
    const { helpHtml } = this.state;
    const primaryIsActive = secondaryHelpTopic === null;

    return (
      <React.Fragment>
        <Typography variant="h6">
          Help
          {!primaryIsActive && (
            <Chip
              className={classes.breadCrumbs}
              label={extractTitleFromTopicId(secondaryHelpTopic)}
              onDelete={onCloseSecondaryTopic}
            />
          )}
        </Typography>
        {helpHtml && (
          <Typography component="div" dangerouslySetInnerHTML={{ __html: helpHtml }} />
        )}
      </React.Fragment>
    );
  }
}

HelpBrowser = withStyles(styles)(HelpBrowser);

export default HelpBrowser;
