import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default function LoadingIndicator(): JSX.Element {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <CircularProgress size={60} />
    </div>
  );
}
