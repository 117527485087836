import { TextField } from '@material-ui/core';
import { getIn } from 'formik';
import React from 'react';

function FormInput({ classes: _classes, field, form, ...rest }) {
  const error = getIn(form.errors, field.name);
  const showError = error && getIn(form.touched, field.name);
  return (
    <TextField
      error={showError}
      helperText={showError && error}
      disabled={form.isSubmitting}
      {...field}
      {...rest}
    />
  );
}

export default FormInput;
