import PropTypes from 'prop-types';
import React from 'react';
import Lorem from 'react-lorem-component';

import { ArticleState, LeadItNews } from '@bruceleads/ad-renderer/src/LeadItNews';

import SmartphonePreview from './SmartphonePreview';

/**
 * @param {object} data
 */
const convertFormValesToAd = (data) => {
  const result = {
    type: data.campaignTypeId || 'NEWSLETTER',
    title: data.title,
    articlepicture: `${data.imageUrl}`,
    teaser: data.teaser,
    buttontext: data.buttonText,
    submit_buttontext: data.submitButtonText,
    tos_url: data.tosUrl,
    pp_url: data.ppUrl,
    publisher: 'publisher',
    publisher_logo: data.companyLogo || 'https://via.placeholder.com/40x40',
    final_picture: `${data.finalImageUrl}`,
    final_title: data.finalTitle || '',
    final_text: data.finalText || '',
    submit_token: 'No TOKEN',
    form_schema: data.formSchema,
    quiz_answers: data.quizAnswers,
  };

  if (data.image) {
    result.articlepicture = data.image.url;
  }

  if (data.finalImage) {
    result.final_picture = data.finalImage.url;
  }
  return result;
};

class AdvertisementPreview extends React.Component {
  static propTypes = {
    advertisementObject: PropTypes.shape({}).isRequired,
    articleState: PropTypes.number,
  };

  static defaultProps = {
    articleState: ArticleState.BANNER,
  };

  render() {
    const { advertisementObject, articleState } = this.props;
    return (
      <LeadItNews
        articleState={articleState}
        articleData={convertFormValesToAd(advertisementObject)}
        onShowForm={(eventOrAnswer) => {
          // `eventOrAnswer` is an answer object for a quiz, and an event instance
          // otherwise.
          // TODO: try to unify the API
          eventOrAnswer.preventDefault?.();
        }}
        styleName="backend-preview"
      />
    );
  }
}

/**
 * Component Rendering an advertisement with the state.
 */
class WorkingAdvertisementPreview extends React.Component {
  state = { articleState: ArticleState.BANNER };

  handleShowForm = () => {
    this.setState({ articleState: ArticleState.FORM });
  };

  handleSubmitForm = () => {
    this.setState({ articleState: ArticleState.SUBMIT_SCREEN });
  };

  render() {
    const { advertisement, campaign } = this.props;
    const { articleState } = this.state;
    const advertisementObject = { ...campaign, ...advertisement };

    return (
      <div style={{ position: 'relative', top: 0, width: '293px', height: '590px' }}>
        <SmartphonePreview>
          <Lorem count="1" />
          <LeadItNews
            articleState={articleState}
            articleData={convertFormValesToAd(advertisementObject)}
            onShowForm={this.handleShowForm}
            onSubmitClick={this.handleSubmitForm}
            styleName="backend-preview"
          />
          <Lorem />
        </SmartphonePreview>
      </div>
    );
  }
}

export {
  AdvertisementPreview,
  ArticleState,
  convertFormValesToAd,
  WorkingAdvertisementPreview,
};
