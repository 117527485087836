// A modified version of https://github.com/stackworx/formik-material-ui/blob/master/src/Checkbox.tsx

import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import { FieldProps, getIn } from 'formik';
import * as React from 'react';

export type CheckboxProps = FieldProps &
  Omit<
    MuiCheckboxProps,
    'form' | 'checked' | 'defaultChecked' | 'name' | 'onChange' | 'value'
  > & {
    label: React.ReactNode;
  };

export const fieldToCheckbox = ({
  field,
  form: { isSubmitting, setFieldValue },
  disabled = false,
  ...props
}: Omit<CheckboxProps, 'label'>): MuiCheckboxProps => {
  return {
    disabled: isSubmitting || disabled,
    ...props,
    ...field,
    onChange: (event) => {
      setFieldValue(field.name, event.target.checked);
    },
    checked: field.value,
    value: field.value ? 'checked' : '',
  };
};

export default function FormCheckbox({
  field,
  form,
  label,
  ...rest
}: CheckboxProps): JSX.Element {
  const error = getIn(form.errors, field.name);
  const shouldShowError = !!error && getIn(form.touched, field.name);
  return (
    <FormControl>
      <FormControlLabel
        label={label}
        control={<MuiCheckbox {...fieldToCheckbox({ ...rest, form, field })} />}
      />
      {shouldShowError && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
}
