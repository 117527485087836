import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field, useFormikContext } from 'formik';
import { memoize, pick } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Lorem from 'react-lorem-component';
import * as yup from 'yup';

import {
  AdvertisementTeaserDisplayMode,
  EditAdvertisementInput,
  UpdateAdvertisementFinalDataInput,
  UpdateAdvertisementFormInput,
} from '../__generated__/graphql-types';
import { image600x500, imageSixteenToNine } from '../utils/imageValidators';
import campaignValidationSchemaExtended from '../validation_schemas/campaignValidationSchemaExtended';
import { AdvertisementFragment } from './__generated__/AdvertisementFragment';
import { CampaignFragment } from './__generated__/CampaignFragment';
import { useCreateAdvertisementMutation } from './__generated__/CreateAdvertisementMutation';
import { useUpdateAdvertisementFinalDataMutation } from './__generated__/UpdateAdvertisementFinalDataMutation';
import { useUpdateAdvertisementFormSchemaMutation } from './__generated__/UpdateAdvertisementFormSchemaMutation';
import { useUpdateAdvertisementWithCampaignMutation } from './__generated__/UpdateAdvertisementWithCampaignMutation';
import { AdvertisementPreview, ArticleState } from './AdvertisementPreview';
import FormChip from './forms/FormChip';
import FormInput from './forms/FormInput';
import FormSelect from './forms/FormSelect';
import FromFileDropZone from './forms/FromFileDropZone';
import InputContainer from './InputContainer';
import SmartphonePreview from './SmartphonePreview';
import WithLock from './WithLock';

type Props = {
  onShowHelp: (topic: string) => void;
  campaignType: string;
  campaign: CampaignFragment;
};

const useStyles = makeStyles({
  formWidth: { flex: 1 },
  insideLock: {
    width: '100%',
  },
});

/**
 * Template for for advertisements
 */
function advertisementTemplate(campaign: CampaignFragment): EditAdvertisementInput {
  return {
    // campaign_type: campaign.campaign_type,
    // final_title: '',
    // final_image: { url: '' },
    // final_text: '',
    // advertisement_group: advertisementGroupId,
    name: 'Default Advertisement',
    // image: { url: '' },
    teaserDisplayMode: AdvertisementTeaserDisplayMode.ImageWithText,
    teaser: '',
    title: '',
    buttonText: '',
    tosUrl: campaign.tosUrl || '',
    ppUrl: campaign.ppUrl || '',
    // formSchema: campaign.formSchema || {},
    quizAnswers: campaign.quizAnswers || [],
    correctQuizAnswer: campaign.correctQuizAnswer || 0,
    // attachment: { url: '' },
    // company_logo: company && company.logo && company.logo.url,
  };
}

const advertisementValidationSchema = yup.object({
  name: yup.string(),
  teaserDisplayMode: yup.string().oneOf(['IMAGE_WITH_TEXT', 'IMAGE_ONLY', 'NONE']),
  title: yup.string().when('teaserDisplayMode', {
    is: 'IMAGE_WITH_TEXT',
    then: (schema: yup.Schema) => schema.required().min(10),
  }),
  teaser: yup.string().when('teaserDisplayMode', {
    is: 'IMAGE_WITH_TEXT',
    then: (schema: yup.Schema) => schema.required().min(10),
  }),
  buttonText: yup.string().when('teaserDisplayMode', {
    is: 'IMAGE_WITH_TEXT',
    then: (schema: yup.Schema) => schema.required().min(10),
  }),
  image: yup
    .object()
    .nullable()
    .when('teaserDisplayMode', {
      is: AdvertisementTeaserDisplayMode.ImageWithText,
      then: (schema: yup.Schema) => schema.concat(imageSixteenToNine).nonNullable(),
    })
    .when('teaserDisplayMode', {
      is: AdvertisementTeaserDisplayMode.ImageOnly,
      then: (schema: yup.Schema) => schema.concat(image600x500).nonNullable(),
    }),
});

const advertisementValidationSchemaWithCampaignFields = memoize(
  function advertisementValidationSchemaWithCampaignFields_(
    campaignTypeId: string | undefined
  ) {
    return advertisementValidationSchema.concat(
      campaignValidationSchemaExtended(campaignTypeId)
    );
  }
);

const submitTextValidationSchema = yup.object({
  finalTitle: yup.string().required().min(10),
  finalText: yup.string().required().min(10),
  finalImage: imageSixteenToNine.required(),
});

function AdvertisementForm({ onShowHelp, campaignType, campaign }: Props): JSX.Element {
  const formik = useFormikContext<EditAdvertisementInput>();
  const { teaserDisplayMode } = formik.values;
  const classes = useStyles();
  const { t } = useTranslation();
  const [previewState] = useState(ArticleState.BANNER);
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Grid container direction="column" spacing={1}>
            <InputContainer helpTopic="advertisement.name" onShowHelp={onShowHelp}>
              <Field
                autoFocus
                component={FormInput}
                label={t('Advertisement Name')}
                name="name"
                className={classes.formWidth}
              />
            </InputContainer>
            <InputContainer
              helpTopic="advertisement.teaser_display_mode"
              onShowHelp={onShowHelp}
            >
              <Field
                component={FormSelect}
                label={t('Teaser Display Mode')}
                options={[
                  {
                    label: t('Image with text'),
                    value: AdvertisementTeaserDisplayMode.ImageWithText,
                  },
                  { label: t('No Teaser'), value: AdvertisementTeaserDisplayMode.None },
                  {
                    label: t('300x250 Teaser Image'),
                    value: AdvertisementTeaserDisplayMode.ImageOnly,
                  },
                ]}
                name="teaserDisplayMode"
                className={classes.formWidth}
              />
            </InputContainer>
            <InputContainer
              helpTopic="advertisement.title"
              onShowHelp={onShowHelp}
              show={teaserDisplayMode === AdvertisementTeaserDisplayMode.ImageWithText}
            >
              <Field
                component={FormInput}
                label={t('Title')}
                name="title"
                className={classes.formWidth}
              />
            </InputContainer>
            <InputContainer
              helpTopic="advertisement.image"
              onShowHelp={onShowHelp}
              show={
                teaserDisplayMode === AdvertisementTeaserDisplayMode.ImageWithText ||
                teaserDisplayMode === AdvertisementTeaserDisplayMode.ImageOnly
              }
            >
              <Field
                component={FromFileDropZone}
                className={classes.formWidth}
                name="image"
                label={t('Image')}
                caption={t('Drop your image banner here, or click to upload')}
                defaultCrop={getDefaultCrop(teaserDisplayMode)}
              />
            </InputContainer>
            <InputContainer
              helpTopic="advertisement.teaser"
              onShowHelp={onShowHelp}
              show={teaserDisplayMode === AdvertisementTeaserDisplayMode.ImageWithText}
            >
              <Field
                component={FormInput}
                label={t('Teaser')}
                name="teaser"
                className={classes.formWidth}
                multiline
                rows="4"
              />
            </InputContainer>
            <InputContainer
              helpTopic="advertisement.button_text"
              onShowHelp={onShowHelp}
              show={teaserDisplayMode === AdvertisementTeaserDisplayMode.ImageWithText}
            >
              <Field
                component={FormInput}
                label={t('Button Text')}
                name="buttonText"
                className={classes.formWidth}
              />
            </InputContainer>
            <InputContainer helpTopic="advertisement.tos_url" onShowHelp={onShowHelp}>
              <WithLock>
                <Field
                  component={FormInput}
                  label={t('Terms of Service URL')}
                  name="tosUrl"
                  className={classes.insideLock}
                />
              </WithLock>
            </InputContainer>
            <InputContainer helpTopic="advertisement.pp_url" onShowHelp={onShowHelp}>
              <WithLock>
                <Field
                  component={FormInput}
                  label={t('Privacy Policy URL')}
                  name="ppUrl"
                  className={classes.insideLock}
                />
              </WithLock>
            </InputContainer>
            {campaignType === 'QUIZ' && (
              <React.Fragment>
                <InputContainer
                  helpTopic="advertisement.quiz_answers"
                  onShowHelp={onShowHelp}
                >
                  <WithLock>
                    <Field
                      component={FormChip}
                      label={t('Quiz Answers')}
                      name="quizAnswers"
                      className={classes.insideLock}
                    />
                  </WithLock>
                </InputContainer>

                <InputContainer
                  helpTopic="advertisement.quiz_answers"
                  onShowHelp={onShowHelp}
                >
                  <Field>
                    {({ form }) => {
                      return (
                        <WithLock>
                          <Field
                            component={FormSelect}
                            label={t('Correct Quiz Answer')}
                            name="correctQuizAnswer"
                            controlProps={{
                              className: classes.insideLock,
                            }}
                            options={
                              form.values.quizAnswers.map((val, idx) => ({
                                label: val,
                                value: idx,
                              })) || []
                            }
                          />
                        </WithLock>
                      );
                    }}
                  </Field>
                </InputContainer>
              </React.Fragment>
            )}
            {campaignType === 'WHITEPAPER_DOWNLOAD' && (
              <React.Fragment>
                <InputContainer
                  helpTopic="advertisement.whitepaper"
                  onShowHelp={onShowHelp}
                >
                  <WithLock>
                    <Field
                      component={FromFileDropZone}
                      label={t('Whitepaper')}
                      name="attachment"
                      className={classes.formWidth}
                      accept="application/pdf"
                      caption={t('Drop your PDF file here or click!')}
                    />
                  </WithLock>
                </InputContainer>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Field>
            {({ form }) => {
              return (
                <div style={{ position: 'sticky', top: 0 }}>
                  <SmartphonePreview>
                    <Lorem count="1" />
                    <AdvertisementPreview
                      advertisementObject={{ ...campaign, ...form.values }}
                      articleState={previewState}
                    />
                    <Lorem />
                  </SmartphonePreview>
                </div>
              );
            }}
          </Field>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export function getAdvertisementFields(
  advertisement: AdvertisementFragment | null | undefined,
  advertisementGroupId: string | null,
  campaign: CampaignFragment | null | undefined
): EditAdvertisementInput | null {
  if (!campaign) {
    return null;
  }
  if (!advertisement) {
    if (!advertisementGroupId) {
      return null;
    }
    return advertisementTemplate(campaign);
  }

  return {
    ...pick(advertisement, [
      'name',
      'teaserDisplayMode',
      'title',
      'image',
      'teaser',
      'buttonText',
    ]),
    ...pick(campaign, [
      'tosUrl',
      'ppUrl',
      'quizAnswers',
      'correctQuizAnswer',
      'attachment',
    ]),
  };
}

export function getAdvertisementFinalDataFields(
  advertisement: AdvertisementFragment | null
): UpdateAdvertisementFinalDataInput | null {
  if (!advertisement) {
    return null;
  }

  return pick(advertisement, ['finalImage', 'finalTitle', 'finalText']);
}

export function getFormEditorFields(
  campaign: CampaignFragment | null | undefined,
  advertisement: AdvertisementFragment | null | undefined
): UpdateAdvertisementFormInput | null {
  if (!(campaign && advertisement)) {
    return null;
  }

  return {
    formSchema: campaign.formSchema || {},
    submitButtonText: advertisement.submitButtonText,
  };
}

export function useAdvertisementHandlers({
  advertisementGroupId,
  advertisementId,
  onAdvertisementCreated,
}: {
  advertisementGroupId: string | null;
  advertisementId: string | null | undefined;
  campaignId: string | null;
  onAdvertisementCreated: (advertisement: AdvertisementFragment) => void;
}): {
  handleAdvertisementFinalDataSubmit;
  handleAdvertisementSubmit;
  handleFormSchemaSubmit;
} {
  const [createAdvertisement] = useCreateAdvertisementMutation();
  const [updateAdvertisementForm] = useUpdateAdvertisementFormSchemaMutation();
  const [updateAdvertisementFinalData] = useUpdateAdvertisementFinalDataMutation();
  const [updateAdvertisementWithCampaign] =
    useUpdateAdvertisementWithCampaignMutation();

  const handleAdvertisementSubmit = async (data, actions) => {
    const input = {
      ...data,
      attachment: data.attachment?.file,
      image: data.image?.file,
    };
    let newAdvertisement: AdvertisementFragment | null | undefined;
    let updatedCampaign: CampaignFragment | null | undefined;

    if (advertisementId) {
      const result = await updateAdvertisementWithCampaign({
        variables: {
          id: advertisementId,
          input,
        },
      });
      // TODO: validate result
      newAdvertisement = result.data?.updateAdvertisementWithCampaign.advertisement;
      updatedCampaign = result.data?.updateAdvertisementWithCampaign.campaign;
    } else {
      const result = await createAdvertisement({
        variables: { advertisementGroupId, input },
        update(cache, { data }) {
          if (!data?.createAdvertisement?.advertisement) {
            return;
          }

          cache.modify({
            id: `AdvertisementGroup:${advertisementGroupId}`,
            fields: {
              advertisements(existingAdRefs = []) {
                const newAdRef = cache.writeFragment({
                  fragment: AdvertisementFragment,
                  data: data.createAdvertisement.advertisement,
                });
                return [...existingAdRefs, newAdRef];
              },
            },
          });
        },
      });
      // TODO: validate result
      newAdvertisement = result.data?.createAdvertisement.advertisement;
      updatedCampaign = result.data?.createAdvertisement.campaign;
    }

    if (newAdvertisement && updatedCampaign) {
      onAdvertisementCreated(newAdvertisement);
      actions.setValues(
        getAdvertisementFields(newAdvertisement, advertisementGroupId, updatedCampaign)
      );
    }
  };

  const handleFormSchemaSubmit = async (data, actions) => {
    const result = await updateAdvertisementForm({
      variables: { id: advertisementId, input: data },
    });
    // TODO: validate result
    const updatedCampaign = result.data?.updateAdvertisementForm.campaign;
    const updatedAdvertisement = result.data?.updateAdvertisementForm.advertisement;
    if (updatedCampaign) {
      actions.setValues(getFormEditorFields(updatedCampaign, updatedAdvertisement));
    }
  };

  const handleAdvertisementFinalDataSubmit = async (data, actions) => {
    const result = await updateAdvertisementFinalData({
      variables: {
        id: advertisementId,
        input: { ...data, finalImage: data.finalImage?.file },
      },
    });
    // TODO: validate result
    const updatedAdvertisement =
      result.data?.updateAdvertisementFinalData.advertisement;
    if (updatedAdvertisement) {
      actions.setValues(getAdvertisementFinalDataFields(updatedAdvertisement));
    }
  };

  return {
    handleAdvertisementFinalDataSubmit,
    handleAdvertisementSubmit,
    handleFormSchemaSubmit,
  };
}

function getDefaultCrop(teaserDisplayMode: AdvertisementTeaserDisplayMode | undefined) {
  const aspect =
    teaserDisplayMode === AdvertisementTeaserDisplayMode.ImageWithText
      ? 16 / 9
      : 30 / 25;
  return { unit: '%', width: 100, aspect };
}

export {
  AdvertisementForm,
  advertisementTemplate,
  advertisementValidationSchema,
  advertisementValidationSchemaWithCampaignFields,
  submitTextValidationSchema,
};
