import { round } from 'lodash';
import * as yup from 'yup';

export function checkRatio(width, height, aspect) {
  // round(val*100) will check for 2 commas and will mitigate float issues
  return round((width / height) * 100) === round(aspect * 100);
}

export const imageSixteenToNine = yup
  .object({
    width: yup.number(),
    height: yup.number(),
    url: yup.string().nullable(),
    file: yup.mixed(),
  })
  .test('image', 'A image is required.', (value) => (value.url || value.file) != null)
  .test('image', 'The image should have a ratio of 16:9.', (value) =>
    checkRatio(value.width, value.height, 16 / 9)
  );

export const image600x500 = yup
  .object({
    width: yup.number(),
    height: yup.number(),
    url: yup.string().nullable(),
    file: yup.mixed(),
  })
  .test('image', 'A image is required.', (value) => (value.url || value.file) != null)
  .test(
    'image',
    'The image must be width>600 height>500 and ratio 30/25.',
    (value) =>
      value.width >= 600 &&
      value.height >= 500 &&
      checkRatio(value.width, value.height, 30 / 25)
  );
