import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import axios from 'axios';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import BaseDataLoader from './components/BaseDataLoader';
import PrivateRoute from './components/PrivateRoute';
import Dashboard from './pages/Dashboard';
import LoginPage from './pages/LoginPage';
import Auth from './utils/Auth';
import history from './utils/history';
import MyDateFnsUtils from './utils/MyDateFnsUtils';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#f4c839',
    },
    secondary: {
      main: '#f4c839',
    },
    background: {
      default: '#f4c839',
    },
  },
});

// Add a request interceptor for authentication
axios.interceptors.request.use((config) => {
  // Add authentication header before sending
  if (Auth.isAuthenticated()) {
    config.headers = { ...config.headers, Authorization: `Bearer ${Auth.token()}` };
  }
  return config;
});

axios.interceptors.response.use(null, (err) => {
  if (err.response && err.response.status === 401) {
    Auth.logout();
    history.push('/login');
  }

  return Promise.reject(err);
});

class App extends React.Component {
  render() {
    const { i18n } = this.props;
    const dateFnsLocale = window.__datefns_locale_map__[i18n.language];

    return (
      <BaseDataLoader>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MyDateFnsUtils} locale={dateFnsLocale}>
            <SnackbarProvider>
              <CssBaseline />
              <Router history={history}>
                <Switch>
                  <Route exact path="/login" component={LoginPage} />
                  <PrivateRoute path="/dashboard" component={Dashboard} />
                  <Redirect from="/" to="/dashboard" />
                </Switch>
              </Router>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </BaseDataLoader>
    );
  }
}

export default withTranslation()(App);
