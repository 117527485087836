import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Step,
  StepButton,
  Stepper,
  Toolbar,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Close, Save } from '@material-ui/icons';
import { Formik } from 'formik';
import update from 'immutability-helper';
import { flowRight as compose, isEmpty } from 'lodash';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import * as yup from 'yup';

import { FormFillersContext } from '../utils/FormFillersContext';
import HelpBrowser from './HelpBrowser';
import SpinningButton from './SpinningButton';
import ValidationErrorsDialog from './ValidationErrorsDialog';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down('md')]: {
    dialogPaper: {
      minWidth: '100%',
      minHeight: '100%',
    },
  },
  [theme.breakpoints.up('md')]: {
    dialogPaper: {
      minHeight: '90vh',
      maxHeight: '80vh',
      minWidth: 1400,
    },
  },

  dialogContent: {
    overflowY: 'scroll',
  },
  stepper: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

const useWizardNavigationStyles = makeStyles((theme) => ({
  navigation: {
    position: 'relative',
    marginTop: 20,
    backgroundColor: theme.palette.background.paper,
  },
}));

/**
 * Container Component to store the wizard pages
 */
const WizardPage = ({ children }) => children;

WizardPage.propTypes = {
  component: PropTypes.func.isRequired,
  initialValues: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  // if it needs a valid page to navigate to the next step
  needValidPage: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  primaryHelpTopic: PropTypes.string,
  title: PropTypes.string.isRequired,
  validationSchema: PropTypes.objectOf(yup.object).isRequired,
};

WizardPage.defaultProps = {
  needValidPage: false,
  initialValues: null,
};

/**
 * Function component for the Header in the wizard, to enable closing and saving of the current page
 */
function WizardHeader({ showSpinner, onClose, onSaveClick, title, saveDisabled }) {
  return (
    <Grid
      container
      style={{
        display: 'flex',
        flexWrap: 'nowrap',
        justify: 'space-between',
        minHeight: '1.5rem',
        marginBottom: '2%',
        justifyContent: 'space-between',
      }}
    >
      <Grid item>
        <DialogTitle style={{ position: 'relative' }}>{title}</DialogTitle>
      </Grid>
      <Grid item>
        <Toolbar>
          <span style={{ position: 'relative' }}>
            <IconButton disabled={saveDisabled} onClick={onSaveClick}>
              <Save />
            </IconButton>
            {showSpinner && (
              <CircularProgress
                size={40}
                style={{
                  position: 'absolute',
                  top: '4px',
                  left: '3px',
                  zIndex: 1,
                }}
              />
            )}
          </span>
          <IconButton disabled={showSpinner} onClick={onClose}>
            <Close />
          </IconButton>
        </Toolbar>
      </Grid>
    </Grid>
  );
}

WizardHeader.propTypes = {
  /** A spinner is displayed if the form is submitting */
  showSpinner: PropTypes.bool.isRequired,
  /** function to execute closing of the wizard */
  onClose: PropTypes.func.isRequired,
  /** function to save current page closing of the wizard */
  onSaveClick: PropTypes.func.isRequired,
  /** Title of the current page */
  title: PropTypes.string.isRequired,
  /** disable the save button */
  saveDisabled: PropTypes.bool.isRequired,
};

/**
 * Navigation items for the wizard (next back)
 */
function WizardNavigation({
  activePageIndex,
  allPagesCount,
  isSubmitting,
  onBack,
  onNext,
  disabled,
}) {
  const classes = useWizardNavigationStyles();

  return (
    <Grid
      className={classes.navigation}
      container
      spacing={1}
      direction="row"
      justify="flex-end"
      alignItems="flex-end"
    >
      <Grid item>
        <Button disabled={activePageIndex === 0 || disabled} onClick={onBack}>
          Back
        </Button>
        <SpinningButton
          disabled={disabled}
          onClick={onNext}
          text={activePageIndex === allPagesCount - 1 ? 'Finish' : 'Next'}
          spinning={isSubmitting}
        />
      </Grid>
    </Grid>
  );
}

WizardNavigation.propTypes = {
  /** which page is active? */
  activePageIndex: PropTypes.number.isRequired,
  /** How many pages are in total */
  allPagesCount: PropTypes.number.isRequired,
  /** if the form is currently submitting, displays spinner */
  isSubmitting: PropTypes.bool.isRequired,
  /** function to return to the last page */
  onBack: PropTypes.func.isRequired,
  /** function to go to next page */
  onNext: PropTypes.func.isRequired,
  /** if the navigation is disabled */
  disabled: PropTypes.bool.isRequired,
};

/**
 * The core component to render the
 */
class Wizard extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
  };

  static defaultProps = {
    title: 'Loading...',
  };

  state = {
    activePageIndex: 0,
    highestVisitedPageIndex: 0,
    secondaryHelpTopic: null,
    showValidationDialog: false,
    /** used to pass the next page to the validationError dialog */
    nextPage: -1,
  };

  getActivePage() {
    const { activePageIndex } = this.state;
    const pages = this.getPages();
    return pages[activePageIndex];
  }

  getPages() {
    const { children } = this.props;
    return React.Children.toArray(children);
  }

  handleClose = () => {
    const { history } = this.props;
    history.length ? history.goBack() : history.push('/');
  };

  handleConfirmValidationErrors = (formikBag) => () => {
    this.setState({ showValidationDialog: false });
    this.handleSubmit(formikBag, this.state.nextPage, true)();
  };

  handleRejectValidationErrors = () => {
    this.setState({ showValidationDialog: false });
  };

  /**
   * Handle submission to the WizardPage object.
   *
   * @param formikBag: The formik bag
   * @param nextPage: optional argument to define a page to jump to
   * @param resume: saves the form with validation errors without asking the user
   */
  handleSubmit = (formikBag, nextPage, resume) => async () => {
    formikBag.setSubmitting(true);

    // if the form is not valid ask the user if he/she want to resume in draft form
    const validationResult = await formikBag.validateForm();

    if (!resume && !isEmpty(validationResult)) {
      this.setState({ showValidationDialog: true, nextPage });
      formikBag.setSubmitting(false);
      return;
    }

    // validate all fields and display error messages by "touching" them
    for (const key of Object.keys(formikBag.values)) {
      formikBag.setFieldTouched(key);
    }

    // now actually submit it
    try {
      await this.getActivePage().props.onSubmit(formikBag.values, formikBag);
    } catch (error) {
      formikBag.setSubmitting(false);
      this.props.enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
      });
      throw error;
    }

    formikBag.setSubmitting(false);

    // go to another page if one is given to the function
    if (nextPage != null) {
      if (this.getPages().length === nextPage) {
        this.handleClose();
      } else {
        this.setState((prevState) =>
          update(prevState, {
            activePageIndex: { $set: nextPage },
            highestVisitedPageIndex: {
              $set: Math.max(prevState.highestVisitedPageIndex, nextPage),
            },
            secondaryHelpTopic: { $set: null },
            // visitedPages: { $add: [nextPage] },
          })
        );
      }
    }
  };

  handleShowHelp = (topic) => {
    this.setState({ secondaryHelpTopic: topic });
  };

  renderFormikContent = (formikArgs) => {
    const {
      activePageIndex,
      secondaryHelpTopic,
      highestVisitedPageIndex,
      showValidationDialog,
    } = this.state;
    const { children, classes, title } = this.props;
    const pages = React.Children.toArray(children);
    const activePage = this.getActivePage();
    const { primaryHelpTopic } = activePage.props;

    const { isSubmitting, isValid } = formikArgs;
    const PageComponent = activePage.props.component;

    const submissionDisabled =
      (activePage.props.needValidPage && !isValid) || isSubmitting;

    return (
      <React.Fragment>
        {showValidationDialog && (
          <ValidationErrorsDialog
            open={showValidationDialog}
            validationErrors={formikArgs.errors}
            handleAccept={this.handleConfirmValidationErrors(formikArgs)}
            handleReject={this.handleRejectValidationErrors}
          />
        )}
        <WizardHeader
          showSpinner={isSubmitting}
          onClose={this.handleClose}
          onSaveClick={this.handleSubmit(formikArgs)}
          saveDisabled={submissionDisabled}
          title={title}
        />
        <DialogContent className={classes.dialogContent}>
          <Stepper className={classes.stepper} nonLinear>
            {pages.map((page, index) => {
              const disabled =
                submissionDisabled ||
                (index > activePageIndex + 1 && highestVisitedPageIndex < index); // if the page is the next one its fine
              return (
                <Step
                  active={index === activePageIndex}
                  disabled={disabled}
                  key={index} // eslint-disable-line react/no-array-index-key
                >
                  <StepButton onClick={this.handleSubmit(formikArgs, index)}>
                    {page.props.title}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>

          <Grid container spacing={5}>
            <Grid item xs={8}>
              <Paper className={classes.paper}>
                <form onSubmit={this.handleSubmit(formikArgs)} autoComplete="off">
                  <PageComponent
                    {...activePage.props}
                    {...formikArgs}
                    onShowHelp={this.handleShowHelp}
                  />
                  <WizardNavigation
                    activePageIndex={activePageIndex}
                    allPagesCount={pages.length}
                    isSubmitting={isSubmitting}
                    disabled={submissionDisabled}
                    onBack={this.handleSubmit(formikArgs, activePageIndex - 1)}
                    onNext={this.handleSubmit(formikArgs, activePageIndex + 1)}
                  />
                </form>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper
                className={classes.paper}
                style={{ position: 'sticky', top: '2em' }}
              >
                <HelpBrowser
                  secondaryHelpTopic={secondaryHelpTopic}
                  primaryHelpTopic={primaryHelpTopic}
                  onCloseSecondaryTopic={() =>
                    this.setState({ secondaryHelpTopic: null })
                  }
                />
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
      </React.Fragment>
    );
  };

  render() {
    const { activePageIndex } = this.state;
    const { classes } = this.props;
    const activePage = this.getActivePage();

    // its loading if initialValues is either null or undefined
    const isLoading = activePage.props.initialValues == null;

    return (
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        className={classes.dialog}
        onClose={this.handleClose}
        open
      >
        <FormFillersContext.Consumer>
          {(formFillers) => {
            const formFillersLoading = formFillers == null;
            if (isLoading || formFillersLoading) {
              return (
                <React.Fragment>
                  <CircularProgress /> {activePage.props.title} is Loading...
                </React.Fragment>
              );
            }
            return (
              <Formik
                initialValues={activePage.props.initialValues}
                key={activePageIndex} // important to re-render form on pageChange
                validateOnMount
                validationSchema={activePage.props.validationSchema}
              >
                {this.renderFormikContent}
              </Formik>
            );
          }}
        </FormFillersContext.Consumer>
      </Dialog>
    );
  }
}

Wizard = compose(withSnackbar, withStyles(styles), withRouter)(Wizard);

export { Wizard, WizardPage };
