import { Theme } from '@material-ui/core/styles';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const analyticsStyles = (theme: Theme) => ({
  [theme.breakpoints.down('md')]: {
    analyticsCell: {
      display: 'none',
    },
  } as const,
});

export default analyticsStyles;
