import {
  Button,
  IconButton,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { lighten, Theme, withStyles } from '@material-ui/core/styles';
import { Add, CloudDownload, Pause, PlayArrow } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import classNames from 'classnames';
import { flowRight as compose } from 'lodash';
import React, { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';

import { ClassesFromStyleFunction } from '@/utils/ClassesFromStyleFunction';
import useHistoryPushInModal from '@/utils/useHistoryPushInModal';

const styles = (theme: Theme) =>
  ({
    root: {
      paddingRight: theme.spacing(),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    spacer: {
      flex: '1 1 100%',
    },
    actions: {
      color: theme.palette.text.secondary,
      display: 'inherit',
    },
    title: {
      flex: '0 0 auto',
    },
  } as const);

type Props = WithTranslation & {
  classes: ClassesFromStyleFunction<typeof styles>;
  numSelected: number;
  onActiveSelection: () => void;
  onDeactivateSelection: () => void;
  onExportSelection: () => void;
  onSelectStartDate: (data: Date | null) => void;
  onSelectEndDate: (data: Date | null) => void;
  onChangeFilterString: (filterString: string | null) => void;
  startDate: Date;
  endDate: Date;
  filterString: string;
};

function EnhancedTableToolbar({
  numSelected,
  classes,
  onActiveSelection,
  onDeactivateSelection,
  onExportSelection,
  onSelectStartDate,
  onSelectEndDate,
  onChangeFilterString,
  startDate,
  endDate,
  filterString,
  t,
}: Props) {
  const historyPushInModal = useHistoryPushInModal();

  const [bouncyFilterString, setBouncyFilterString] = useState(filterString);
  useDebounce(
    () => {
      onChangeFilterString(bouncyFilterString);
    },
    300,
    [bouncyFilterString]
  );

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <>
            <Button
              color="secondary"
              aria-label="Menu"
              variant="contained"
              onClick={() => {
                historyPushInModal('/dashboard/campaigns/new-campaign');
              }}
            >
              <Add /> {t('Add Campaign')}
            </Button>
            <TextField
              style={{ marginLeft: '1em' }}
              value={bouncyFilterString}
              label="Search Campaign..."
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setBouncyFilterString(e.target.value)
              }
            ></TextField>
          </>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <React.Fragment>
            <Tooltip
              title={
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                t('Activate all selected items')!
              }
            >
              <IconButton onClick={onActiveSelection}>
                <PlayArrow />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                t('Deactivate all selected items')!
              }
            >
              <IconButton onClick={onDeactivateSelection}>
                <Pause />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                t('Export Lead Data to CSV')!
              }
            >
              <IconButton onClick={onExportSelection}>
                <CloudDownload />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <DatePicker onChange={onSelectStartDate} value={startDate} />
            <DatePicker onChange={onSelectEndDate} value={endDate} />
          </React.Fragment>
        )}
      </div>
    </Toolbar>
  );
}

export default compose(withTranslation(), withStyles(styles))(EnhancedTableToolbar);
