import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { flowRight as compose } from 'lodash';
import React from 'react';

import { ClassesFromStyleFunction } from '@/utils/ClassesFromStyleFunction';
import useVisibleDashboardColumns from '@/utils/useVisibleDashboardColumns';

import AnalyticsColumnsSelect from './AnalyticsColumnsSelect';
import analyticsStyles from './analyticsStyles';

const styles = (theme: Theme) => ({
  head: {
    // An alternative to the following is the `stickyHeader` prop
    // on <Table />, but it uses different background
    backgroundColor: theme.palette.background.paper,
    position: 'sticky',
    top: 0,
    zIndex: 10,
  } as const,
  ...analyticsStyles(theme),
});

type Props = {
  classes: ClassesFromStyleFunction<typeof styles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    property: string | undefined
  ) => void;
  onSelectAllClick: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  order: 'asc' | 'desc';
  orderBy: string;
  rowCount: number;
};

const COLUMNS = [
  {
    style: { width: '2em' },
    name: 'active',
    label: 'Active',
    position: 'fixed',
    top: 0,
  },
  { style: { width: '0.5em' } }, // expand button
  { style: { width: '20%' }, name: 'name', label: 'Name' },
  { style: { width: 'auto' }, label: 'Status' },
  { style: { width: 'auto' }, label: 'Leads', numeric: true },
  { style: { width: '0.5em' }, label: 'Lead Download' }, // download button
  {
    style: { width: 'auto' },
    name: 'ctr',
    label: 'CTR',
    numeric: true,
    responsive: true,
    hideable: true,
  },
  {
    style: { width: 'auto' },
    name: 'impressions',
    label: 'Impressions',
    numeric: true,
    responsive: true,
    hideable: true,
  },
  {
    style: { width: 'auto' },
    name: 'conversion_rate_impressions',
    label: 'Conversion Rate (Impressions)',
    numeric: true,
    responsive: true,
    hideable: true,
  },
  {
    style: { width: 'auto' },
    name: 'conversion_rate_clicks',
    label: 'Conversion Rate (Clicks)',
    numeric: true,
    responsive: true,
    hideable: true,
  },
  {
    style: { width: 'auto' },
    name: 'clicks',
    label: 'Clicks',
    numeric: true,
    responsive: true,
    hideable: true,
  },

  {
    style: { width: 'auto' },
    name: 'form_shows',
    label: 'Form Shows',
    numeric: true,
    responsive: true,
    hideable: true,
  },
  {
    style: { width: 'auto' },
    name: 'form_changes',
    label: 'Form Changes',
    numeric: true,
    responsive: true,
    hideable: true,
  },
  {
    style: { width: 'auto' },
    name: 'submissions',
    label: 'Submissions',
    numeric: true,
    responsive: true,
    hideable: true,
  },
];

export const ORDERABLE_ANALYTICS_COLUMNS = COLUMNS.map((column) => column.name).filter(
  (name) => name != null
);

function EnhancedTableHead({
  classes,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  order,
  orderBy,
  rowCount,
}: Props) {
  const columnsManager = useVisibleDashboardColumns();

  const createSortHandler =
    (property: string | undefined) =>
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (property) {
        onRequestSort(event, property);
      }
    };
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className={classes.head} style={{ width: '4em' }}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount && rowCount > 0}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {COLUMNS.map((column, index) => {
          if (column.hideable && !columnsManager.isVisible(column.label)) {
            return null;
          }

          const cellClasses = {
            [classes.head]: true,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            [(classes as any).analyticsCell]: column.responsive,
          };
          return (
            <TableCell
              style={column.style}
              key={index} // eslint-disable-line react/no-array-index-key
              align={column.numeric ? 'right' : undefined}
              className={classNames(cellClasses)}
              // padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={column.name && orderBy === column.name ? order : false}
            >
              {/* <Tooltip title="Sort" enterDelay={300}> */}
              <TableSortLabel
                active={column.name === orderBy}
                direction={order != null ? order : undefined}
                hideSortIcon={column.name == null}
                onClick={createSortHandler(column.name)}
              >
                {column.label}
              </TableSortLabel>
              {/* </Tooltip> */}
            </TableCell>
          );
        })}
        <TableCell align="right">
          <AnalyticsColumnsSelect />
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default compose(withStyles(styles))(EnhancedTableHead);
