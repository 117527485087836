export default function hasParentWithClass(
  element: Element,
  className: string,
  stopOn?: string
): boolean {
  let checkedElement = element;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (checkedElement.classList.contains(className)) {
      return true;
    }

    if (stopOn) {
      if (checkedElement.classList.contains(stopOn)) {
        return false;
      }
    }

    if (!checkedElement.parentElement) {
      return false;
    }

    checkedElement = checkedElement.parentElement;
  }
}
