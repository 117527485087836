import ChipInput from 'material-ui-chip-input';
import PropTypes from 'prop-types';
import React from 'react';

const handleAdd = (form, field, value) => {
  // copy, push, set
  const values = field.value.slice();
  values.push(value);

  form.setFieldValue(field.name, values);
};

const handleDelete = (form, field, value) => {
  // copy, splice, set
  const values = field.value.slice();
  const indexToRemove = field.value.findIndex((item) => item === value);
  values.splice(indexToRemove, 1);
  form.setFieldValue(field.name, values);
};

const handleBlur = (form, field) => {
  form.setFieldTouched(field.name, true);
};

const FormChip = ({ classes, field, form, onBlur, ...rest }) => {
  const showError = form.errors[field.name] && form.touched[field.name];
  return (
    <ChipInput
      error={showError}
      helperText={showError && form.errors[field.name]}
      disabled={form.isSubmitting}
      {...field}
      {...rest}
      onChange={undefined}
      onAdd={(chip) => handleAdd(form, field, chip)}
      onDelete={(chip) => handleDelete(form, field, chip)}
      onBlur={(event) => {
        handleBlur(form, field);
        onBlur(event, form, field);
      }}
    />
  );
};

FormChip.propTypes = {
  field: PropTypes.shape({ value: PropTypes.arrayOf(PropTypes.string).isRequired })
    .isRequired,
  onBlur: PropTypes.func,
};

FormChip.defaultProps = {
  onBlur: () => {
    // empty
  },
};

export default FormChip;
