import { Button, Paper } from '@material-ui/core';
import { Field, FieldArray, FieldProps } from 'formik';
import React, { useMemo } from 'react';

import { IntegrationMapping } from '@/utils/types';

import { CampaignFragment } from '../__generated__/CampaignFragment';
import IntegrationMappingEditorField from './IntegrationMappingEditorField';

type Props = FieldProps<IntegrationMapping[]> & {
  campaign: CampaignFragment;
};

const NEW_INTEGRATION_MAPPING: IntegrationMapping = {
  target: '',
  expression: '',
  is_advanced: false,
};

export default function IntegrationMappingEditor({
  campaign,
  field,
  form,
}: Props): React.ReactNode {
  const { value } = field;
  const originExpressionOptions = useMemo(() => {
    const { properties } = campaign.formSchema;
    return properties ? getOriginExpressionOptions(properties) : [];
  }, [campaign.formSchema]);

  return (
    <Paper>
      <h3>Mappings</h3>
      <FieldArray name="integrationMapping">
        {({ push }) => {
          return (
            <div>
              {value &&
                value.map((_mapping, index) => {
                  return (
                    <Field
                      component={IntegrationMappingEditorField}
                      key={index}
                      name={`${field.name}.${index}`}
                      originExpressionOptions={originExpressionOptions}
                    />
                  );
                })}
              <div>
                <Button
                  onClick={() => {
                    if (!value) {
                      form.setFieldValue(field.name, [NEW_INTEGRATION_MAPPING]);
                    } else {
                      push(NEW_INTEGRATION_MAPPING);
                    }
                  }}
                  type="button"
                >
                  Add mapping
                </Button>
              </div>
            </div>
          );
        }}
      </FieldArray>
    </Paper>
  );
}

function getOriginExpressionOptions(
  properties: Record<string, Record<string, unknown>>
) {
  return Object.keys(properties).map((key) => ({
    label: key,
    value: `form[${JSON.stringify(key)}]`,
  }));
}
