import { Checkbox, ListItemText, MenuItem, Select } from '@material-ui/core';
import { ViewColumn } from '@material-ui/icons';
import React from 'react';

import { AnalyticsColumnsNames } from '@/utils/AnalyticsColumns';
import useVisibleDashboardColumns from '@/utils/useVisibleDashboardColumns';

export default function AnalyticsColumnsSelect(): JSX.Element {
  const columns = useVisibleDashboardColumns();
  return (
    <Select
      displayEmpty
      id="analytics-columns-select"
      multiple
      onChange={(event) => {
        // TypeScript definitions are incorrect here - the value is actually
        // an array of strings
        const values = event.target.value as unknown as string[];
        columns.setVisibleList(values);
      }}
      renderValue={() => <ViewColumn />}
      value={columns.visibleList}
    >
      {AnalyticsColumnsNames.map((name) => (
        <MenuItem key={name} value={name}>
          <Checkbox checked={columns.isVisible(name)} />
          <ListItemText primary={name} />
        </MenuItem>
      ))}
    </Select>
  );
}
